import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { ItemData, selectedItemAtom } from "../../../../Atoms/DashboardAtom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CommonChartProps } from "../..";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";

const ItemDetailInfo = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedItems = useAtomValue(selectedItemAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedItem = selectedItems.find((item) => item.pageId === studioHeader.currentPageId)?.item as ItemData ?? null;

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "40px" }}>
          {selectedItem ?
            (
              <Typography variant="subtitle2" component="div" sx={{ fontWeight: 'bold' }}>
                {selectedItem.name}
              </Typography>
            ) : (
              <Typography variant="h6" component="div" >
                아이템 상세정보
              </Typography>
            )}
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        {selectedItem ? (
          <Box sx={{ width: "100%", minHeight: `${customLayout.h * rowHeight - 90}px`, height: `${customLayout.h * rowHeight - 90}px`, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <img src={selectedItem?.productImage ?? ""} alt="대체 이미지" style={{ width: "100px", height: "100px", borderRadius: "50%", marginLeft: "10px" }} />
            <Box sx={{ display: "flex", flexDirection: "column", marginRight: "10px", width: "70%", gap: "5px" }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "5px", fontSize: "12px", alignItems: "center", fontWeight: "600" }}>
                {selectedItem.industrialCategories.length >= 3 && (
                  <>
                    <div>{selectedItem.industrialCategories[2]}</div>
                    <KeyboardArrowRightIcon />
                    <div>{selectedItem.industrialCategories[3]}</div>
                  </>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "5px", fontSize: "12px", alignItems: "center", fontWeight: "600" }}>
                <div>{selectedItem.itemCategories[0]}</div>
                {selectedItem.itemCategories.length > 1 && selectedItem.itemCategories.slice(1).map((category, index) => (
                  <Box key={index} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                    <KeyboardArrowRightIcon />
                    <div>{category}</div>
                  </Box>
                ))}
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5px", fontSize: "12px", fontWeight: "500" }}>
                <div>가격: {selectedItem.price}원</div>
                <div>브랜드: {selectedItem.brand}</div>
                <div>제조국: {selectedItem.manufacturingCountry}</div>
                <div>제조사: {selectedItem.manufacturingCompany}</div>
                <div>
                  URL:
                  <a href={selectedItem.productUrl ?? ""} target="_blank" rel="noreferrer" onMouseDown={(e) => e.stopPropagation()}>
                    바로가기
                  </a>
                </div>
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary">
            유망아이템 리스트에서 아이템을 선택해주세요.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default ItemDetailInfo;
