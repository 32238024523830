import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Slider, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import * as C from './containers';
import { useSetAtom } from 'jotai';
import { mapperOption, userAtom } from '../../Atoms/UserAtom';

interface MapperProps {
  openDialog: mapperOption[] | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<mapperOption[] | null>>;
}

const minDistance = 10

const Mapper = ({ openDialog, setOpenDialog }: MapperProps) => {
  const setUser = useSetAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<C.SearchResult[]>([]);
  const [value1, setValue1] = useState<number[]>([20, 37]);

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  const valuetext = (value: number) => {
    return `${value}°C`;
  }

  const handleClose = () => {
    setOpenDialog(null);
    setSearchResults([]);
  }

  const handleConfirm = () => {
    if (!openDialog) {
      console.error("Open dialog is null or undefined.");
      return;
    }
    setUser((prev) => {
      // 현재 currentAsset의 id를 가져옵니다.
      const currentAssetId = prev.currentAsset?.id;

      if (!currentAssetId) {
        // currentAsset이 없는 경우 예외 처리 (선택사항)
        console.error("Current asset is null or undefined.");
        return prev;
      }

      if (openDialog.length === 0) {
        // 선택된 mapper가 없는 경우, 해당 currentAsset의 mapper를 제거합니다.
        return {
          ...prev,
          currentMapper: prev.currentMapper.filter(
            (mapper) => mapper.id !== currentAssetId
          ),
        };
      }

      // currentMapper 배열에서 currentAsset의 id와 일치하는 항목을 찾습니다.
      const existingMapperIndex = prev.currentMapper.findIndex(
        (mapper) => mapper.id === currentAssetId
      );

      if (existingMapperIndex > -1) {
        // 해당 id가 이미 존재하는 경우, mapper를 업데이트합니다.
        const updatedMapper = [...prev.currentMapper];
        updatedMapper[existingMapperIndex].mapper = openDialog;

        return {
          ...prev,
          currentMapper: updatedMapper,
        };
      } else {
        // 해당 id가 존재하지 않는 경우, 새로운 항목을 추가합니다.
        return {
          ...prev,
          currentMapper: [
            ...prev.currentMapper,
            { id: currentAssetId, mapper: openDialog },
          ],
        };
      }
    });
    setOpenDialog(null);
    setSearchResults([]);
  };

  return (
    <Dialog
      open={openDialog !== null}
      onClose={handleClose}
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle>MAPPER</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <C.MapperTextField setSearchResults={setSearchResults} setLoading={setLoading} />
      </DialogContent>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          fontSize: '12px',
          gap: '10px',
          alignItems: 'center',
          height: 'auto',
          maxHeight: '100px',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        <C.SelectedOptions openDialog={openDialog} setOpenDialog={setOpenDialog} />
      </DialogContent>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '200px', flexDirection: 'column', gap: '50px' }}>
            <Typography>검색 중 입니다.</Typography>
            <CircularProgress />
          </Box>
        ) :
          searchResults.length > 0 ?
            <C.SearchResults searchResults={searchResults} openDialog={openDialog} setOpenDialog={setOpenDialog} />
            :
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <C.ListSelector openDialog={openDialog} setOpenDialog={setOpenDialog} />
              {/* 슬라이더 */}
              {/* <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <Box sx={{ width: "30%" }}>
                  <Typography id="input-slider" gutterBottom>
                    사원 수
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      최소 값
                    </Grid>
                    <Grid item xs>
                      <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={value1}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        disableSwap
                      />
                    </Grid>
                    <Grid item>
                      최대 값
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: "30%" }}>
                  <Typography id="input-slider" gutterBottom>
                    기업 규모
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      최소 값
                    </Grid>
                    <Grid item xs>
                      <Slider
                        getAriaLabel={() => 'Minimum distance'}
                        value={value1}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        disableSwap
                      />
                    </Grid>
                    <Grid item>
                      최대 값
                    </Grid>
                  </Grid>
                </Box>
              </Box> */}
              {/* 여러가지 스위치 */}
              {/* <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}
              >
                <FormControlLabel
                  value="top"
                  control={<Switch color="primary" />}
                  label="스위치1"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Switch color="primary" />}
                  label="스위치2"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Switch color="primary" />}
                  label="스위치3"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Switch color="primary" />}
                  label="스위치4"
                  labelPlacement="top"
                />
              </Box> */}
            </Box>
        }
      </DialogContent>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleClose}>닫기</Button>
        <Button onClick={handleConfirm}>적용</Button>
      </DialogContent>
    </Dialog>
  )
}

export default Mapper;
