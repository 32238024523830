import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { userAtom } from "../../../Atoms/UserAtom";
import { ExcelFileProps } from "../../../Atoms/AssetAtom";
import { TrendGridItem } from "../../../Atoms/DashboardAtom";

interface UseProcessTrendSourceDataProps {
  customLayout: any;
  setFilteredItems: (items: TrendGridItem[]) => void;
  selectedItem: TrendGridItem | null;
  filterKeyword?: string; // 필터링에 사용할 키워드 (옵션)
}

const useProcessTrendSourceData = ({
  customLayout,
  setFilteredItems,
  selectedItem,
  filterKeyword = "" // 기본값을 빈 문자열로 설정
}: UseProcessTrendSourceDataProps) => {
  const user = useAtomValue(userAtom);

  const findColumnData = (columnId: string, fileData: ExcelFileProps | null) => {
    return fileData?.categoricalData.find((data) => data.id === columnId)?.data ||
      fileData?.numericalData.find((data) => data.id === columnId)?.data || [];
  };

  useEffect(() => {
    const currentSource = user.currentAsset?.sources.find((assetSource) => assetSource.assetType === "trend");
    const fileData = currentSource?.file?.fileData as ExcelFileProps ?? null;
    if (!fileData) return;

    const trendCategoryData = findColumnData('트렌드 유형', fileData);
    const trendDivisionData = findColumnData('트렌드 구분', fileData);
    const trendKeywordData = findColumnData('트렌드 키워드', fileData);
    const trendConsumptionEffectData = findColumnData('소비에영향을미치는정도', fileData);
    const trendSocialWaveData = findColumnData('사회적파장정도', fileData);

    if (!trendCategoryData.length || !trendDivisionData.length || !trendKeywordData.length || !trendConsumptionEffectData.length || !trendSocialWaveData.length) {
      console.log("No data");
      return;
    }

    const items: TrendGridItem[] = trendKeywordData.map((keyword, i) => ({
      index: i,
      category: trendCategoryData[i],
      division: trendDivisionData[i],
      keyword,
      consumptionEffect: trendConsumptionEffectData[i],
      socialWave: trendSocialWaveData[i],
    }));

    // 특정 키워드를 포함하는 항목 필터링
    const filteredItems = filterKeyword
      ? items.filter((item) => item.category.includes(filterKeyword))
      : items;

    // 상태 변경은 조건부로 수행하여 무한 렌더링을 방지합니다.
    setFilteredItems(filteredItems);

  }, [customLayout, user.currentAsset, selectedItem, filterKeyword, setFilteredItems]);
};

export default useProcessTrendSourceData;
