import { Card, CardContent, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { CommonChartProps } from "../..";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TrendMarketSize = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {

  const data = {
    labels: ['2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'],
    datasets: [
      {
        type: 'line' as const,
        label: '성장률 (%)',
        data: [5, 10, 15, 10, 8, 12, 15, 5, 5, 10],
        borderColor: '#6a00f4',
        backgroundColor: '#6a00f4',
        fill: false,
        yAxisID: 'y1',
      },
      {
        type: 'bar' as const,
        label: '시장 규모 (억 원)',
        data: [100, 120, 130, 150, 140, 160, 180, 170, 190, 200],
        backgroundColor: 'rgba(0, 210, 255, 0.8)',
        yAxisID: 'y',
      },
    ]
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
      y1: {
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      datalabels: {
        display: false
      }
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div">
            시장규모
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 90}px`, maxHeight: `${customLayout.h * rowHeight - 90}px`, gap: "5px" }} onMouseDown={(e) => e.stopPropagation()}>
          <Chart type="line" data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrendMarketSize;
