import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  // position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  // background-color: #f2f2f2;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  min-width: 900px;
  height: 610px;
  // min-height: 630px;
  background-color: #fff;
  border: 2px dashed #000;
  border-radius: 10px;
`;

export const DashboardBlankContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  gap: 20px;
  color: #d9d9d9;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: #f2f2f2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CloseIconStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '20px',
  height: '20px',
  cursor: 'pointer',
  zIndex: 100,
  pointerEvent: 'all',
}

export const LayoutComponentHeader = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
`;

export const LayoutComponentHeaderTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const LayoutComponentHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
