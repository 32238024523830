import { atom } from 'jotai';
import { AssetProps } from './AssetAtom';

export const testMember = {
  id: 'test',
  verified: true,
  profileImage: 'https://avatars.githubusercontent.com/u/67744643?v=4',
  auth: {
    email: 'test@test.com',
    hasPassword: true,
    providers: [
      {
        provider: 'email',
      },
    ],
  },
  customFields: {
    mbrship: 'admin',
  },
}

export const memberAtom = atom<{
  id: string;
  verified: boolean;
  profileImage: string;
  auth: {
    email: string;
    hasPassword: boolean;
    providers: {
      provider: string;
    }[];
  };
  customFields: any;
} | null>(null);

export interface mapperOption {
  type: string;
  value: string;
}

export interface currentMapper {
  id: string;
  mapper: mapperOption[];
}

export const userAtom = atom<{
  userId: string;
  assets: AssetProps[];
  currentAsset: AssetProps | null;
  currentMapper: currentMapper[];
}>(
  {
    userId: '',
    assets: [],
    currentAsset: null,
    currentMapper: [],
  },
);

export const heightAtom = atom<number>(window.innerHeight);
