import { useState } from "react";
import * as S from "./styled";
import { apiAddress } from "../../../Atoms/GlobalVariable";
import { useAtomValue } from "jotai";
import { assetAtom } from "../../../Atoms/AssetAtom";
import { userAtom } from "../../../Atoms/UserAtom";
import { useUser } from "../../../Providers/User";
import { dashboardBaseThumbnail, documentBaseThumbnail } from "../../../Data/thumbnailMetadata";

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 버튼 컴포넌트들
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
export const AddPageButton = () => {
  const assets = useAtomValue(assetAtom);
  const [isOpen, setIsOpen] = useState(false);
  const user = useAtomValue(userAtom);
  const { refetchUserData } = useUser();

  const handleClick = () => {
    console.log('Add Page Button Clicked', assets);
    setIsOpen(!isOpen);
  };

  const handleItemClick = async (item: string) => {
    await requestAddPage(item);
    setIsOpen(false); // 선택 후 드롭다운 닫기
  };

  const requestAddPage = async (item: string) => {
    try {
      if (!user.currentAsset) {
        return;
      }
      const formData = new FormData();
      formData.append('assetId', user.currentAsset.id);
      formData.append('pageType', item);
      formData.append('thumbnail', item === "dashboard" ? dashboardBaseThumbnail : documentBaseThumbnail);
      const response = await fetch(`${apiAddress}/asset/page/add`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to add page');
      }
      const data = await response.json();
      console.log(data);
      // 페이지 추가 후 페이지 목록 다시 불러오기
      refetchUserData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <S.AddPageButtonContainer>
      <S.AddPageButton onClick={handleClick}>
        <div>+ 페이지 추가</div>
      </S.AddPageButton>
      <S.DropdownMenu $isOpen={isOpen}>
        <S.DropdownItem onClick={() => handleItemClick('dashboard')}>대시보드</S.DropdownItem>
        <S.DropdownItem onClick={() => handleItemClick('document')}>문서</S.DropdownItem>
      </S.DropdownMenu>
    </S.AddPageButtonContainer>
  );
};
// ----------------------------------------------------------------------------------------
