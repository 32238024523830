import styled from 'styled-components';
import { Box, Paper } from '@mui/material';

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Sidebar 스타일 컴포넌트
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
export const Container = styled.div<{ $open: boolean, $anchor: "left" | "right" }>`
  display: ${({ $open }) => $open ? 'block' : 'none'};
  position: fixed;
  top: 56px;
  left: ${({ $anchor }) => $anchor === 'left' ? 0 : 'auto'};
  right: ${({ $anchor }) => $anchor === 'right' ? 0 : 'auto'};
  z-index: 1300;
  height: 100%;
`;

export const StyledPaper = styled(Paper)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 300px;
  pointer-events: auto;
  top: 0;
  border: 1px solid #d9d9d9;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100% - 56px);
  width: 300px;
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  padding: 8px 15px;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
`;

export const ContentsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  gap: 15px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const PageContentContainer = styled.div<{ $selected: boolean }>`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  min-height: 150px;
  border: ${(props) => props.$selected ? 2 : 1}px solid ${(props) => props.$selected ? '#5340FF' : '#d9d9d9'};
  border-radius: 5px;
  padding: 15px;
  gap: 5px;
`;

export const PageContentTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
`;

export const PageContentText = styled.span<{ $fontSize: number, $fontWeight: string }>`
  font-size: ${(props) => props.$fontSize}px;
  font-weight: ${(props) => props.$fontWeight};
  font-family: Inter;
`;

export const PageThumbnail = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  max-height: 100px;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
`;

export const ThumbnailImage = styled.img`
  width: auto;
  height: 100%;
`;

export const ButtonStyle = {
  border: "none",
  padding: 0,
  width: "20px",
  height: "20px",
  backgroundColor: "transparent",

  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
};

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 65%;
`;

export const AddLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1px;
  border: 1px solid #222;
  border-radius: 10px;
  overflow-y: auto;
`;

export const AddLayoutHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
`;

export const AddLayoutHeaderTab = styled.div<{ $selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  cursor: pointer;
  font-size: 12px;
  font-weight: ${(props) => props.$selected ? 'bold' : '500'};
  border-bottom: 1px solid ${(props) => !props.$selected ? '#222' : 'transparent'};
`;

export const AddLayoutContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
`;

export const AddLayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  min-height: 150px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
`;

export const AddLayoutContentImage = styled.img`
  width: auto;
  height: 105px;
  object-fit: contain;
`;
// ----------------------------------------------------------------------------------------
