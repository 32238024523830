import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { CommonChartProps } from "../..";
import { selectedTechInfoAtom, selectedThesisInfoAtom } from "../../../../Atoms/DashboardAtom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colorMap } from "../../../../Data/colorMap";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";
import { useData } from "../../../../Providers/Data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ThesisCheckChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const { thesis } = useData();
  const selectedThesisInfo = useAtomValue(selectedThesisInfoAtom);
  const [chartData, setChartData] = useState<any>(null);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedTechInfo = useAtomValue(selectedTechInfoAtom);

  useEffect(() => {
    let uniqueItems = thesis.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.title === item.title
      ))
    );

    const techInfo = selectedTechInfo.find((info) => info.pageId === studioHeader.currentPageId);
    if (techInfo) {
      uniqueItems = uniqueItems.filter((item) =>
        item.trendKeywords?.some((keyword) => keyword.id.includes(techInfo.selectedKeyword) && keyword.value === 1)
      );
    }

    const countsOfDetailCheckData = uniqueItems.flatMap((item) =>
      item.countsOfDetailCheck?.map((detail) => ({
        year: parseInt(detail.id.split('_')[1], 10), // Assuming '상세조회횟수_' prefix
        count: detail.value
      })) || []
    );

    if (!countsOfDetailCheckData.length) {
      console.error('countsOfDetailCheckData is null');
      return;
    }
    countsOfDetailCheckData.sort((a, b) => a.year - b.year);
    const labels = Array.from(new Set(countsOfDetailCheckData.map(item => item.year.toString())));
    const selectedItem = selectedThesisInfo.find((item) => item.pageId === studioHeader.currentPageId);
    const selectedCheckData = labels.map(label => {
      const matchingDetail = selectedItem?.item.countsOfDetailCheck?.find((detail: any) => detail.id.includes(label));
      return matchingDetail ? matchingDetail.value : 0;
    });
    const averageCounts = labels.map(label => {
      const yearlyData = countsOfDetailCheckData.filter(item => item.year.toString() === label);
      const sum = yearlyData.reduce((acc, item) => acc + item.count, 0);
      return yearlyData.length > 0 ? sum / yearlyData.length : 0;
    });

    const lineData = {
      labels,
      datasets: [
        {
          type: 'line' as const,
          label: '현재 선택된 연도의 상세 조회 횟수',
          data: selectedCheckData,
          borderColor: colorMap[0],
          backgroundColor: colorMap[0],
          fill: false,
          yAxisID: 'y',
        },
        {
          type: 'bar' as const,
          label: '모든 연도의 상세 조회 횟수 평균값',
          data: averageCounts,
          backgroundColor: colorMap[2],
          yAxisID: 'y',
        },
      ],
    };

    setChartData(lineData);
  }, [thesis, selectedTechInfo, studioHeader.currentPageId, selectedThesisInfo]);

  const options = {
    responsive: true,
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      datalabels: {
        display: false,
      }
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            연도별 논문 상세 조회 횟수
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box sx={{ display: "flex", maxHeight: `${customLayout.h * rowHeight - 90}px`, minHeight: `${customLayout.h * rowHeight - 90}px`, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          {chartData ? <Line options={options} data={chartData} /> : <Typography>Loading chart...</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ThesisCheckChart;
