import { ItemData } from "../../../../Atoms/DashboardAtom";
import { mapperOption } from "../../../../Atoms/UserAtom";

interface ProcessItemSourceDataProps {
  assetId: string;
  setItems: (items: ItemData[]) => void;
  fetchAssetData: (assetId: string, type: string) => Promise<any>;
  fetchAssetDataByMapper: (assetId: string, mapper: mapperOption[]) => Promise<any>;
  mapper: mapperOption[] | null;
}

const processItemData = async ({ assetId, setItems, fetchAssetData, fetchAssetDataByMapper, mapper }: ProcessItemSourceDataProps) => {
  // Fetch item data
  let itemResponse;
  if (mapper) {
    itemResponse = await fetchAssetDataByMapper(assetId, mapper);
  } else {
    itemResponse = await fetchAssetData(assetId, "item");
  }
  if (itemResponse && itemResponse.data) {
    const allKeywords = new Set<string>();
    itemResponse.data.forEach((data: any) => {
      const keywordsArray = data.keywords.split(' ; ');
      keywordsArray.forEach((keyword: string) => allKeywords.add(keyword));
    });

    const allKeywordsArray = Array.from(allKeywords).filter((keyword) => keyword !== '');

    const items: ItemData[] = itemResponse.data.map((data: any, index: number) => {
      let parsedProducts = [];
      try {
        parsedProducts = JSON.parse(data.dataDetails.products || '[]');
      } catch (error) {
        console.error("Error parsing products JSON:", error);
      }

      const itemKeywords = new Set(data.keywords.split(' ; '));
      const trendData = allKeywordsArray.map((keyword) => ({
        id: keyword,
        value: itemKeywords.has(keyword) ? 1 : 0,
      }));

      return {
        index: index,
        id: data.id,
        name: data.name || null,
        productUrl: data.dataDetails.productUrl || null,
        productImage: data.dataDetails.productImage || null,
        price: parseFloat(data.dataDetails.price) || null,
        manufacturingCountry: data.dataDetails.manufacturingCountry || null,
        manufacturingCompany: data.dataDetails.manufacturingCompany || null,
        brand: data.dataDetails.brand || null,
        industrialCategories: data.dataDetails.industrialCategories?.split(';').map((item: string) => item.trim()) || [],
        itemCategories: data.dataDetails.itemCategories?.split(';').map((item: string) => item.trim()) || [],
        rating: parseFloat(data.dataDetails.rating) || null,
        productScoring: parseFloat(data.dataDetails.productScoring) || null,
        keywords: data.keywords?.split(';').map((keyword: string) => keyword.trim()) || [],
        products: parsedProducts.map((product: any) => ({
          id: product.id,
          value: product.value,
        })),
        trendData,
      };
    });

    // 모든 아이템의 trendData를 합쳐 평균을 계산
    const trendAverages: { [key: string]: number } = {};
    const allTrendData: { [key: string]: number[] } = {};

    items.forEach((item) => {
      item.trendData.forEach((trend) => {
        if (!allTrendData[trend.id]) {
          allTrendData[trend.id] = [];
        }
        allTrendData[trend.id].push(trend.value);
      });
    });

    // 각 trend 키워드의 평균 계산
    for (const trendId in allTrendData) {
      const total = allTrendData[trendId].reduce((acc, value) => acc + value, 0);
      trendAverages[trendId] = total / allTrendData[trendId].length;
    }

    // 평균을 각 아이템의 trendData에 추가
    const updatedItems = items.map((item) => ({
      ...item,
      trendData: item.trendData.map((trend) => ({
        ...trend,
        average: trendAverages[trend.id],
      })),
    }));

    setItems(updatedItems);
  }
};

export default processItemData;
