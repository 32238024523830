import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import 'leaflet/dist/leaflet.css';
import { Container as MapDiv, Marker, NaverMap, useNavermaps } from 'react-naver-maps';
import { CommonChartProps } from '../..';
import { selectedCompanyInfoAtom } from '../../../../Atoms/DashboardAtom';

const CompanyMap = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedCompanyInfo = useAtomValue(selectedCompanyInfoAtom);
  const navermaps = useNavermaps();
  const [firstCompany, setFirstCompany] = useState(selectedCompanyInfo.length > 0 ? selectedCompanyInfo[0].item : null);
  const [location, setLocation] = useState({ lat: 37.3595704, lng: 127.105399 });
  const [map, setMap] = useState<naver.maps.Map | null>(null);
  const [marker, setMarker] = useState<naver.maps.Marker | null>(null);
  const [infoWindow, setInfoWindow] = useState<naver.maps.InfoWindow | null>(null);

  useEffect(() => {
    setFirstCompany(selectedCompanyInfo.length > 0 ? selectedCompanyInfo[0].item : null);
  }, [selectedCompanyInfo]);

  useEffect(() => {
    if (firstCompany) {
      setLocation({ lat: firstCompany.latitude, lng: firstCompany.longitude });
      const position = new navermaps.LatLng(firstCompany.latitude, firstCompany.longitude);

      if (map) {
        map.setCenter(position);
        map.setZoom(15);
      }

      if (marker) {
        marker.setPosition(position);
      } else {
        if (!map) return;
        const newMarker = new navermaps.Marker({
          position,
          map,
        });
        setMarker(newMarker);

        const contentString = `
         <div class="iw_inner" style="width: 200px; height: 100px; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 5px; padding: 10px;">
            <div style="font-size: 14px; font-weight: bold;">${firstCompany.companyName}</div>
            <div style="font-size: 10px; font-weight: 600;">
              주소: ${firstCompany.address} <br />
              설립연도: ${firstCompany.establishmentYear}
            </div>
          </div>
        `;
        const newInfoWindow = new navermaps.InfoWindow({
          content: contentString
        });
        setInfoWindow(newInfoWindow);

        naver.maps.Event.addListener(newMarker, "click", function (e) {
          if (newInfoWindow.getMap()) {
            newInfoWindow.close();
          } else {
            if (map) newInfoWindow.open(map, newMarker);
          }
        });
      }

      if (infoWindow) {
        const contentString = `
         <div class="iw_inner" style="width: 200px; height: 100px; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 5px; padding: 10px;">
            <div style="font-size: 14px; font-weight: bold;">${firstCompany.companyName}</div>
            <div style="font-size: 10px; font-weight: 600;">
              주소: ${firstCompany.address} <br />
              설립연도: ${firstCompany.establishmentYear}
            </div>
          </div>
        `;
        infoWindow.setContent(contentString);
        if (map && marker) infoWindow.open(map, marker);
      }
    } else {
      setLocation({ lat: 37.3595704, lng: 127.105399 });
    }
  }, [firstCompany, map, navermaps, marker, infoWindow]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ minHeight: `calc(100vh - 40px)`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div">
            기업분포지도
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 90}px`, height: `${customLayout.h * rowHeight - 90}px`, gap: "5px" }}
          onMouseDown={(e: any) => e.stopPropagation()}
        >
          {!location.lat && !location.lng ?
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <h3>
                {firstCompany ? firstCompany.companyName : '기업정보'}
              </h3>
              <h4>
                {firstCompany ? firstCompany.address : '주소정보'}
              </h4>
              <h4>
                {firstCompany ? firstCompany.contact : '연락처'}
              </h4>
              <h5>
                해외 기업의 경우 위치 정보가 제공되지 않습니다.
              </h5>
            </div> :
            <MapDiv
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <NaverMap
                defaultCenter={new navermaps.LatLng(location.lat, location.lng)}
                defaultZoom={15}
                ref={(ref) => setMap(ref)}
              >
                {marker && <Marker position={new navermaps.LatLng(location.lat, location.lng)} />}
              </NaverMap>
            </MapDiv>
          }
        </Box>
      </CardContent>
    </Card>
  );
}

export default CompanyMap;
