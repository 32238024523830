import { createContext, useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { mapperOption, memberAtom, userAtom } from '../../Atoms/UserAtom';
import { fetchUserData } from '../../Queries/UserQuery';
import { AssetProps } from '../../Atoms/AssetAtom';
import { fetchDataByAssetType, fetchDataByMapper } from '../../Queries/DataQuery';

const UserContext = createContext({
  refetchUserData: () => { },
  fetchAssetData: async (assetId: string, type: string) => null as any,
  fetchAssetDataByMapper: async (assetId: string, mapper: mapperOption[]) => null as any,
});

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const member = useAtomValue(memberAtom);
  const [user, setUser] = useAtom(userAtom);
  const { data: userData, refetch } = useQuery({
    queryKey: ['userData', member?.id],
    queryFn: () => fetchUserData(member?.id ?? ''),
    enabled: !!member && member.id.trim() !== "", // member가 있을 때만 fetch 실행
    staleTime: 1000 * 60 * 5, // 5분 동안은 캐시된 데이터를 사용
  });

  useEffect(() => {
    if (userData) {
      const findCurrentAsset = userData.user.assets.find((asset: AssetProps) => asset.id === user.currentAsset?.id) ?? user.currentAsset ?? userData.user.assets[0];
      setUser({
        userId: userData.user.userId,
        assets: userData.user.assets,
        currentAsset: findCurrentAsset,
        currentMapper: user.currentMapper,
      });
    }
  }, [userData, setUser, user.currentAsset, user.currentMapper]);

  const fetchAssetData = async (assetId: string, type: string) => {
    const data = await fetchDataByAssetType(assetId, type);
    return data;
  };

  const fetchAssetDataByMapper = async (assetId: string, mapper: mapperOption[]) => {
    const data = await fetchDataByMapper(assetId, mapper);
    return data;
  }

  return (
    <UserContext.Provider value={{ refetchUserData: refetch, fetchAssetData, fetchAssetDataByMapper }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
