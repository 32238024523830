import {
  Box,
  Button,
  IconButton,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  SourceProps,
  makeEditAssetAtom,
  sourceOptionAtom,
  alertMessageAtom,
  alertType,
  initialSource,
  initialMakeEditAsset,
  AssetProps,
  publicSourceAtom,
} from "../../../Atoms/AssetAtom";
import {
  Alert,
} from "@mui/joy";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import { memberAtom, userAtom } from "../../../Atoms/UserAtom";
import { apiAddress } from "../../../Atoms/GlobalVariable";
import { dashboardBaseThumbnail } from "../../../Data/thumbnailMetadata";
import { studioHeaderAtom } from "../../../Atoms/StudioAtom";
import * as S from './styled';
import * as C from './containers';
import { useUser } from "../../../Providers/User";

type FileType = 'excel' | 'api' | 'crawling' | 'video' | 'img';
interface FileExtensions {
  [key: string]: string[];
}

interface MakeUserAssetContentProps {
  setIsCancel: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MakeUserAssetContent = ({ setIsCancel, isLoading, setIsLoading }: MakeUserAssetContentProps) => {
  const [makeEditAsset, setMakeEditAsset] = useAtom(makeEditAssetAtom);
  const [sourceOption] = useAtom(sourceOptionAtom);
  const [alertMessage, setAlertMessage] = useAtom(alertMessageAtom);
  const [publicSources, setPublicSources] = useAtom(publicSourceAtom);
  const member = useAtomValue(memberAtom);
  const setUser = useSetAtom(userAtom);
  const setStudioHeader = useSetAtom(studioHeaderAtom);
  const navigate = useNavigate();
  const [source, setSource] = useState<SourceProps>(initialSource);
  const [sourceMode, setSourceMode] = useState<{
    mode: string;
    index: number;
  }>({
    mode: "create",
    index: -1,
  });

  const refetchUserData = useUser().refetchUserData;
  const [publicAssets, setPublicAssets] = useState<AssetProps[]>([]);

  // Alert 메시지를 띄워주는 함수, 2초 후에 사라집니다.
  const handleAlertMessage = (message: string, type: alertType) => {
    setAlertMessage({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlertMessage({
        message: "",
        type: "danger",
      });
    }, 2000);
  };

  const handleMakeEditAsset = (source: SourceProps) => {
    if (isInMakeEditAsset(source.file?.fileId)) {
      const newAsset = { ...makeEditAsset, sources: makeEditAsset.sources.filter(sou => source.file?.fileId !== sou.file?.fileId) };
      setMakeEditAsset(newAsset);
    } else {
      if (isInMakeEditAsset(source.file?.fileId) === undefined) {
        const newAsset = { ...makeEditAsset, sources: makeEditAsset.sources.length !== 0 ? [...makeEditAsset.sources, source] : [source] };
        setMakeEditAsset(newAsset);
      }
    }
  }

  const isInMakeEditAsset = (fileId: string | undefined) => {
    return makeEditAsset.sources.find(source => source.file?.fileId === fileId);
  }

  const handleSaveAsset = async () => {
    if (makeEditAsset.name === "" || makeEditAsset.description === "") {
      handleAlertMessage("Asset명과 설명을 입력해주세요.", "danger");
      return;
    } else if (makeEditAsset.sources.length === 0) { // Asset Sources가 비어있을 경우
      handleAlertMessage("Asset을 추가해주세요.", "danger");
      return;
    } else if (sourceMode.mode === "edit") { // 수정 중인 source가 있을 경우
      handleAlertMessage("Asset Source 수정 중입니다. 수정을 완료해주세요.", "danger");
      return;
    }
    setIsLoading(true);
    // 저장 버튼을 눌렀을 때의 동작
    const formData = new FormData();
    formData.append('assetName', makeEditAsset.name);
    formData.append('description', makeEditAsset.description);
    formData.append('isPrivate', 'true');
    formData.append('userId', member?.id ?? '');
    formData.append('sources', JSON.stringify(makeEditAsset.sources));
    formData.append('thumbnail', dashboardBaseThumbnail);
    let response = null;
    try {
      if (makeEditAsset.id !== "") {
        // asset을 수정하는 경우
        console.log("수정 요청 보내기");
        console.log("formData", formData);

        formData.append('assetId', makeEditAsset.id);
        response = await fetch(`${apiAddress}/asset/edit`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) throw new Error('수정에 실패했습니다.');
      } else {
        // asset을 새로 생성하는 경우
        response = await fetch(`${apiAddress}/asset/create`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) throw new Error('파일 업로드에 실패했습니다.');
      }
      setMakeEditAsset(initialMakeEditAsset);
    } catch (error: any) {
      handleAlertMessage(`${error.message}`, "danger");
    } finally {
      if (!response) return;
      const data = await response.json();

      console.log("data", data);

      refetchUserData();
      setUser((prev) => ({ ...prev, currentAsset: data.asset }));
      setStudioHeader((prev) => ({ ...prev, currentPageId: data.asset.pages[0].id }));
      setTimeout(() => {
        setIsLoading(false);
        navigate("/studio");
      }, 2000);
    }
  };


  const deleteSource = async (index: number) => {
    console.log("deleteSource index: ", index);
    console.log("deleteSource makeEditAsset: ", makeEditAsset.sources[index]);
    try {
      const formData = new FormData();
      formData.append('sourceId', makeEditAsset.sources[index].id);
      const response = await fetch(`${apiAddress}/asset/source/user/delete`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error('Failed to delete source');
      }
      setMakeEditAsset(current => ({ ...current, sources: current.sources.filter((_, i) => i !== index) }))
    } catch (error) {
      console.error(error);
    }
  }

  const getPublicAssets = async () => {
    try {
      const response = await fetch(`${apiAddress}/asset/public`);
      if (!response.ok) {
        throw new Error('Failed to fetch public assets');
      }
      const data = await response.json();
      setPublicAssets(data.assets);
    } catch (error) {
      console.error('Error fetching public assets:', error);
    }
  }



  useEffect(() => {
    getPublicAssets();
  }, []);

  useEffect(() => {
    console.log('publicAssets:', publicAssets);
  }, [publicAssets]);

  useEffect(() => {
    console.log("publicSource", publicSources);
  }, [publicSources]);

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 56px)" }} >
      {/* 왼쪽 설정 */}
      <Box sx={S.LeftBoxContainer}>
        <S.PrevAndSaveButtonContainer>
          <Button
            sx={{
              height: "35px",
              border: "1px solid #134cf8",
              color: "#134cf8",
            }}
            variant="outlined"
            startIcon={(<ArrowBackIcon />)}
            onClick={() => setIsCancel(true)}
            disabled={isLoading}
          >
            <Typography>취소</Typography>
          </Button>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", alignItems: "center" }}>
            {alertMessage.message !== "" && <Alert color={alertMessage.type}>{alertMessage.message}</Alert>}
            <Button
              sx={{
                height: "35px",
                border: "1px solid #134cf8",
                color: "#134cf8",
              }}
              variant="outlined"
              startIcon={(<SaveIcon />)}
              onClick={handleSaveAsset}
              disabled={isLoading}
            >
              <Typography>저장</Typography>
            </Button>
          </div>
        </S.PrevAndSaveButtonContainer>

        {/* private public 설정 */}
        {/* <C.PrivatePublicSelectSwitch /> */}

        {/* User(Not Admin)의 경우, 기존 Asset을 선택할 수 있는 기능 추가 */}
        <Typography fontSize={13} color={"#8f8f8f"}>
          기존 ASSET 선택
        </Typography>

        <C.SelectExistingAsset
          selectKey="select-existing-asset"
          placeholder="기존 Asset 선택"
          value={""}
          options={publicAssets} />

        {/* ASSET 설정 */}
        <C.SetAsset />

        {/* source 설정 */}
        <Typography fontSize={13} color={"#8f8f8f"}>
          Sources From
        </Typography>
        <div id="source-type" key="source-type" style={{ display: "flex", width: "100%", gap: "10px", flexDirection: "row" }}>

          {
            publicSources.map(
              (source, index) => source.fileType === "excel" && (
                <Button
                  key={`source-type-select-btn-${index}`}
                  sx={{ flex: 1, height: "50px", borderRadius: "25px" }}
                  variant={isInMakeEditAsset(source.file?.fileId) ? "contained" : "outlined"}
                  onClick={() => handleMakeEditAsset(source)}>{source.assetType}</Button>
              )
            )
          }
        </div>

      </Box>

      {/* 오른쪽 설정 */}
      <Box
        sx={S.RightBoxContainer}
      >
        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset명
        </Typography>
        <Typography color={makeEditAsset.name === "" ? "red" : "black"}>
          {makeEditAsset.name === ""
            ? "Asset 명을 기입해주세요."
            : makeEditAsset.name}
        </Typography>

        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset 설정
        </Typography>
        <Typography color={makeEditAsset.isPrivate ? "red" : "blue"}>
          {makeEditAsset.isPrivate ? "비공개" : "공개"}
        </Typography>

        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset 설명
        </Typography>
        <Typography color={makeEditAsset.description === "" ? "red" : "black"}>
          {makeEditAsset.description === ""
            ? "Asset 설명을 작성해주세요."
            : makeEditAsset.description}
        </Typography>

        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset Sources
        </Typography>
        {makeEditAsset.sources.map((source, index) =>
          sourceMode.index === index
            ? (
              <Box
                key={`source-${index}`}
                sx={{
                  height: "40px",
                  borderBottom: "1px solid #dfdfdf",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#8f8f8f",
                  color: "white",
                  '& div button': {
                    background: "#8f8f8f",
                    color: "white",
                  }
                }}
              >
                <Typography>{source.sourceName}</Typography>
                <Typography>수정중...</Typography>
              </Box>
            )
            : (
              <Box
                key={`source-${index}`}
                sx={{
                  height: "40px",
                  borderBottom: "1px solid #dfdfdf",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  '&:hover': {
                    background: "#134cf8",
                    color: "white",
                    borderBottom: "1px solid white",
                    '& div button': {
                      color: "white",
                    }
                  }
                }}
              >
                <Typography>{source.sourceName}</Typography>
                <IconButton
                  onClick={() => handleMakeEditAsset(source)} >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
      </Box>
    </Box >
  );
}

export default MakeUserAssetContent;
