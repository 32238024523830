import { useAtomValue } from "jotai";
import { userAtom } from "../Atoms/UserAtom";
import { assetsPageAtom } from "../Atoms/AssetsPageAtom";
import { useEffect, useState } from "react";
import { AssetProps } from "../Atoms/AssetAtom";
import AssetsContents from "../Components/Contents/Assets";

const Assets = () => {
  const user = useAtomValue(userAtom);
  const assetsPage = useAtomValue(assetsPageAtom);
  const [assetList, setAssetList] = useState<AssetProps[]>([]);

  useEffect(() => {
    if (assetsPage.currentSearchText === '') {
      setAssetList(user.assets);
    } else {
      setAssetList(user.assets.filter((asset) => asset.assetName.includes(assetsPage.currentSearchText)));
    }
  }, [user, assetsPage]);

  return (
    <AssetsContents assets={assetList} />
  )
}

export default Assets;
