import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  z-index: 200;
  left: 0;
  right: 0;
  justify-content: center;
  padding: 0 20px;
  align-items: center;
  width: 100%;
  height: 55px;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export const ButtonStyle = {
  display: "flex",
  flexDirection: "row",
  border: "none",
  padding: "10px",
  height: "30px",
  backgroundColor: "transparent",
  gap: "10px",

  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
};

export const RightButtonContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  gap: 10px;
  align-items: center;
`;
