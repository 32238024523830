import Lottie from 'react-lottie';

interface LottieAnimationProps {
  animationData: object;
  width: number;
  height: number;
}

const LottieAnimation = ({ animationData, width, height }: LottieAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      progressiveLoad: true
    }
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  );
};

export default LottieAnimation;
