import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import { Provider as JotaiProvider } from 'jotai';
import { MemberstackProvider } from '@memberstack/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import router from './Router';
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareNeoBrg';
    src: url('/NanumSquareNeo-bRg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareNeoBrg', sans-serif;
    line-height: 1.7;
  }

  textarea , input , button, div, p, span, h1,h2,h3,h4,h5,h6, select,option {
    font-family: 'NanumSquareNeoBrg', sans-serif !important;
    line-height: 1.7;
  }

`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

const config = {
  publicKey: 'pk_7a16491c42dd9ddb7ea1',
};

root.render(
  <MemberstackProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <GlobalStyle />
        <RouterProvider router={router} />
      </JotaiProvider>
    </QueryClientProvider>
  </MemberstackProvider>
);
