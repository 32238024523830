import { Card, CardContent, Typography, List, Divider, Box, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CompanyInfoItem, selectedCompanyInfoAtom } from "../../../../Atoms/DashboardAtom";
import { useAtomValue, useAtom } from "jotai";
import { CommonChartProps } from "../..";
import * as S from "./styled";
import convertSales from "../../../../Utils/ConvertSales";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";
import { useData } from "../../../../Providers/Data";

const CompanyInfoList = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useAtom(selectedCompanyInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const { companies } = useData();

  const handleItemClicked = (item: CompanyInfoItem) => {
    const prevItem = selectedCompanyInfo.find((selectedItem) => selectedItem.pageId === studioHeader.currentPageId);
    if (prevItem) {
      setSelectedCompanyInfo(selectedCompanyInfo.map((selectedItem) => {
        if (selectedItem.pageId === studioHeader.currentPageId) {
          return { ...selectedItem, item };
        } else {
          return selectedItem;
        }
      }));
    } else {
      setSelectedCompanyInfo([...selectedCompanyInfo, { pageId: studioHeader.currentPageId, item }]);
    }
  }

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 10}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            기업정보
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '30%' }}>기업명</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>국가</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>매출액</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>사원수</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>웹사이트</Typography>
        </Box>
        <Divider />
        {companies.length > 0 ? (
          <List>
            {companies.map((item, index) => {
              const isSelected = selectedCompanyInfo.find((selectedItem) => selectedItem.item.index === item.index);
              return (
                <div key={index}>
                  <S.StyledListItem
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => handleItemClicked(item)}
                    sx={{
                      cursor: "pointer",
                      backgroundColor: isSelected ? "rgba(0, 123, 255, 0.1)" : "inherit",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <Typography variant="body2" sx={{ fontWeight: "600", width: '30%', fontSize: "0.9rem" }}>{item.companyName && item.companyName.length > 10 ? item.companyName?.substring(0, 10) + "..." : item.companyName}</Typography>
                      <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.country}</Typography>
                      {item.numericalSales ? <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{convertSales(item.numericalSales, "", "달러")}</Typography> : <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>-</Typography>}
                      <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.employee}</Typography>
                      {item.url ? <Link href={item.url} target="_blank" rel="noreferrer" sx={{ width: '20%', fontSize: "0.8rem" }}>바로가기</Link> : <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>-</Typography>}
                    </Box>
                  </S.StyledListItem>
                  <Divider />
                </div>
              )
            })}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary">
            데이터를 불러오는 중입니다...
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default CompanyInfoList;
