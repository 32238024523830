import { useAtomValue } from "jotai";
import * as S from "./styled"
import { useEffect, useMemo, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { CustomLayout } from "../../../Atoms/StudioAtom";
import Charts from "../../Charts";
import { dashboardAtom } from "../../../Atoms/DashboardAtom";
import { heightAtom } from "../../../Atoms/UserAtom";
import { commonLayoutVariable } from "../../../Atoms/GlobalVariable";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardContents = () => {
  const dashboard = useAtomValue(dashboardAtom);
  const [layouts, setLayouts] = useState<{ md: CustomLayout[] }>({ md: [] });
  const height = useAtomValue(heightAtom);

  const layout = useMemo(() => {
    const contents = dashboard.currentPageContents;
    if (contents.length === 0) return [];

    return contents.map((content) => {
      return {
        contentId: content.contentId,
        contentName: content.contentName,
        contentType: content.contentType,
        dataType: content.dataType,
        i: content.id,
        x: content.x,
        y: content.y,
        w: content.w,
        h: content.h,
        minW: content.minW,
        minH: content.minH,
        maxW: content.maxW,
        maxH: content.maxH,
      } as CustomLayout;
    });
  }, [dashboard]);

  useEffect(() => {
    setLayouts({ md: layout });
  }, [layout]);

  return (
    <S.Container>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: commonLayoutVariable, md: commonLayoutVariable, sm: commonLayoutVariable, xs: commonLayoutVariable, xxs: commonLayoutVariable }}
        rowHeight={(height - 112) / commonLayoutVariable - 12}
        maxRows={commonLayoutVariable}
        isResizable={true}
        isDraggable={true}
        style={{ width: '100%', minHeight: `${height - 112}px` }}
      >
        {layouts.md.map((item) => (
          <div key={item.i} className="dnd-movable-item" data-grid={{ ...item, static: "true" }} style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%", height: "100%" }} >
            <Charts chartData={item} contentId={item.i} />
          </div>
        ))}
      </ResponsiveGridLayout>
    </S.Container>
  )
}

export default DashboardContents;
