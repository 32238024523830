import { Option, Select } from "@mui/joy";
import { SourceProps } from "../../../Atoms/AssetAtom";

interface SelectProps {
  selectKey: string;
  placeholder: string;
  value: string;
  type: keyof SourceProps;
  options: { value: string; text: string }[];
  setSource: React.Dispatch<React.SetStateAction<SourceProps>>;
}

const SelectAsset = ({ selectKey, placeholder, options, value, type, setSource }: SelectProps) => {
  const handleSelectChange = (
    event: React.SyntheticEvent | null,
    newValue: string | null,
    key: keyof SourceProps
  ) => {


    setSource((current) => ({
      ...current,
      [key]: newValue ?? "",
      fileType: key === "assetType" ? "" : newValue ?? "",
      sourceName: "",
      file: null,
      haveTospecifyCategory: false,
      category: null,
      haveToRetrain: false,
      tableName: null,
      retrainSchedule: null,
      retrainStartDate: null,
      retrainEndDate: null,
      url: null,
      keywords: null,
      usingModel: key === "fileType" && newValue !== "excel" ? `${current.assetType} 전처리 모델` : null,
    }));
  };

  return (
    <Select
      key={`${selectKey}-select`}
      sx={{
        flex: 1,
        padding: "10px",
        height: "50px",
      }}
      placeholder={placeholder}
      value={value}
      onChange={(event, newValue) => handleSelectChange(event, newValue, type)}
    >
      {
        options.map((option) => (
          <Option key={`${selectKey}-${option.value}`} value={option.value}>{option.text}</Option>
        ))
      }
    </Select>
  );
};

export default SelectAsset;
