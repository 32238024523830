import { atom } from 'jotai';
import { PageContentProps, PageProps } from './AssetAtom';

interface AssetProps {
  type: string;
  setting: any;
}

interface MapperProps {
  category: string[] | [] | null;
  relate_category: string[] | [] | null;
  main_industry: string[] | [] | null;
  applcation_industry: string[] | [] | null;
  item_trend_keyword: string[] | [] | null;
  item_feature_keyword: string[] | [] | null;
  item_element_keyword: string[] | [] | null;
  review_keyword: string[] | [] | null;
  item_country: string[] | [] | null;
  price: string[] | [] | null;
  brand: string[] | [] | null;
  rate: string[] | [] | null;
  review_num: string[] | [] | null;
  tech_feature_keyword: string[] | [] | null;
  tech_element_keyword: string[] | [] | null;
  tech_publish_date: string[] | [] | null;
  tech_company_name: string[] | [] | null;
  tech_ref_num: string[] | [] | null;
}

interface TabProps {
  name: string;
  mapper: MapperProps;
  dashboards: AssetProps[] | [];
}

interface TabsProps {
  tabs: TabProps[];
}

export const myDashboardAtom = atom<TabsProps | [] | null>(null);

interface AdditionalData {
  id: string;
  value: any;
  average?: number;
}

export interface ItemData {
  index: number;
  id: string;
  name: string | null;
  productUrl: string | null;
  productImage: string | null;
  price: number | null;
  manufacturingCountry: string | null;
  manufacturingCompany: string | null;
  brand: string | null;
  industrialCategories: string[]; // 산업 카테고리를 배열로 관리
  itemCategories: string[]; // 품목 카테고리를 배열로 관리
  rating: number | null;
  productScoring: number | null;
  keywords: string[]; // 키워드를 배열로 관리
  products: AdditionalData[];
  trendData: AdditionalData[];
}

export interface CompanyInfoItem {
  index: number;
  companyName: string | null;
  itemNames: string[]; // 제품명을 배열로 관리
  industryCategories: string[]; // 산업 카테고리를 배열로 관리
  productCategories: string[]; // 제품 카테고리를 배열로 관리
  country: string | null;
  address: string | null;
  url: string | null;
  employee: string | null;
  companyFitnessScore: number | null;
  companyFitness: AdditionalData[];
  isMissing: string | null;
  elements: AdditionalData[];
  sales: string | null;
  numericalSales: number | null;
  establishmentYear: number | null;
  featureKeywordData: AdditionalData[];
  latitude: number | null;
  longitude: number | null;
}

export interface PatentInfoItem {
  index: number;
  id: string;
  patentTitle: string | null; // 특허명
  categoryIPC: string | null; // IPC 분류
  categoryCPC: string | null; // CPC 분류
  applicationNumber: string | null; // 출원번호
  applicationDate: string | null; // 출원일자
  applicant: string | null; // 출원인
  applicantNationality: string | null; // 출원인 국적
  inventor: string | null;  // 발명자
  inventorNationality: string | null; // 발명자 국적
  agent: string | null; // 대리인
  agentNationality: string | null;  // 대리인 국적
  finalHolder: string | null; // 최종권리자
  registrationNumberDate: string | null;  // 등록번호 / 일자
  publicationNumberDate: string | null; // 공개번호 / 일자
  announcementNumberDate: string | null;  // 공고번호 / 일자
  internationalApplicationNumberDate: string | null;  // 국제 출원번호 / 일자
  internationalPublicationNumberDate: string | null;  // 국제 공개번호 / 일자
  priorityInformation: string | null; // 우선권 정보
  citingInformationCountry: string | null; // 인용정보_국가
  citingInformationPublicationNumber: string | null; // 인용정보_공보번호
  citingInformationPublicationDate: string | null;  // 인용정보_공보일자
  citingInformationInventionName: string | null;  // 인용정보_발명의명칭
  citingInformationIPC: string | null;  // 인용정보_IPC
  citedInformationApplicationDate: string | null; // 피인용정보_출원일자
  citedInformationInventionName: string | null; // 피인용정보_발명의명칭
  citedInformationIPC: string | null; // 피인용정보_IPC
  citedApplicationNumber: string | null;  // 피인용정보_출원번호
  relatedApplicationNumber: string | null;  // 관련 출원 번호
  totalCitationCount: number | null;  // 총 인용횟수
  summary: string | null; // 요약
  summaryKeywords: string | null; // 요약키워드
  trendKeywords: AdditionalData[] | null; // Trend키워드_
}

export interface ThesisInfoItem {
  index: number;
  title: string | null; // 논문 제목
  url: string | null; // 논문 URL
  author: string | null; // 저자
  publishInstitution: string | null; // 발행기관
  scholarpaperName: string | null; // 학술지명
  featureKeywords: string | null; // 주제어_FeatureKeyword
  KDCDDC: string | null; // KDC / DDC
  generalPeriodName: string | null; // 일반주기명
  abstract: string | null; // 초록
  tableOfContents: string | null; // 목차
  paperContent: string | null; // 논문내용
  publishYear: number | null; // 발행연도
  totalCountsOfDetailCheck: number | null; // 상세조회횟수
  countsOfDetailCheck: AdditionalData[] | null; // 상세조회횟수_(2014-)
  countsOfDownload: number | null; // 다운로드횟수
  countsOfExport: number | null; // 내보내기횟수
  trendKeywords: AdditionalData[] | null; // Trend키워드_
}

export interface TrendGridItem {
  index: number;
  category: string;
  division: string;
  keyword: string;
  consumptionEffect: number;
  socialWave: number;
}

export interface ChartItemProps {
  pageId: string;
  item: ItemData | CompanyInfoItem | PatentInfoItem | ThesisInfoItem | any;
}

export interface PageStateProps {
  pageId: string;
  selectedKeyword: string;
}

// 각 Asset 별 유망아이템 리스트에서 선택한 아이템의 정보를 저장하는 Atom
export const selectedItemAtom = atom<ChartItemProps[]>([]);

export const selectedCompanyInfoAtom = atom<ChartItemProps[]>([]);

export const selectedPatentInfoAtom = atom<ChartItemProps[]>([]);

export const selectedThesisInfoAtom = atom<ChartItemProps[]>([]);

export const selectedTechInfoAtom = atom<PageStateProps[]>([]);

export interface DashboardAtomProps {
  currentAssetId: string | null;
  currentPageId: string | null;
  currentPageContents: PageContentProps[];
  pageList: PageProps[];
}

// 대시보드에서 사용할 Atom
export const dashboardAtom = atom<DashboardAtomProps>({
  currentAssetId: null,
  currentPageId: null,
  currentPageContents: [],
  pageList: [],
});

export interface ResponseFileData {
  id: string;
  name: string;
  product: string;
  ksic: string;
  keywords: string;
  sourceBy: string;
  object_id: string;
  dataDetails: any;
}
