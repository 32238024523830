import { useState } from "react";
import MakeAssetContent from "../Components/Contents/MakeAsset";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { initialMakeEditAsset, makeEditAssetAtom, publicSourceAtom } from "../Atoms/AssetAtom";
import { useNavigate } from "react-router-dom";
import LoadingDialog from "../Components/Dialog/Loading";
import { apiAddress } from "../Atoms/GlobalVariable";
import YesOrNoDialog from "../Components/Dialog/YesOrNo";
import { memberAtom } from "../Atoms/UserAtom";
import MakeUserAssetContent from "../Components/Contents/MakeAsset/userIndex";

const MakeAsset = () => {
  const member = useAtomValue(memberAtom);
  const membership = member?.customFields?.mbrship;
  const [isCancel, setIsCancel] = useState(false);
  const [makeEditAsset, setMakeEditAsset] = useAtom(makeEditAssetAtom);
  const setPublicSources = useSetAtom(publicSourceAtom);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCancleLoading, setIsCancelLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const deleteSource = async (index: number) => {
    console.log("deleteSource index: ", index);
    console.log("deleteSource makeEditAsset: ", makeEditAsset.sources[index]);
    try {
      const formData = new FormData();
      if (makeEditAsset.sources[index].id === "") {
        formData.append('fileId', makeEditAsset.sources[index].file?.fileId ?? '');
        const response = await fetch(`${apiAddress}/asset/file/delete`, {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          throw new Error('Failed to delete file');
        }
      } else {
        formData.append('sourceId', makeEditAsset.sources[index].id);
        const response = await fetch(`${apiAddress}/asset/source/delete`, {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          throw new Error('Failed to delete source');
        }
      }
      setMakeEditAsset(current => ({ ...current, sources: current.sources.filter((_, i) => i !== index) }))
    } catch (error) {
      console.error(error);
    }
  }

  // const userDeleteSource = async (index: number) => {
  //   console.log("deleteSource index: ", index);
  //   console.log("deleteSource makeEditAsset: ", makeEditAsset.sources[index]);
  //   try {
  //     const formData = new FormData();
  //     formData.append('sourceId', makeEditAsset.sources[index].id);
  //     const response = await fetch(`${apiAddress}/asset/source/user/delete`, {
  //       method: 'POST',
  //       body: formData
  //     });
  //     if (!response.ok) {
  //       throw new Error('Failed to delete source');
  //     }
  //     setMakeEditAsset(current => ({ ...current, sources: current.sources.filter((_, i) => i !== index) }))
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const handleCancel = async () => {
    setIsCancelLoading(true);
    console.log("makeEditAsset: ", makeEditAsset);
    try {
      for (let i = 0; i < makeEditAsset.sources.length; i++) {
        console.log("makeEditAsset.sources[i].id: ", makeEditAsset.sources[i].id);
        if (makeEditAsset.sources[i].id === "") {
          await deleteSource(i);
        }
      }
      setMakeEditAsset(initialMakeEditAsset);
      setIsCancel(false);
      navigate('/assets');
    } catch (error) {
      console.error("Error during cancellation:", error);
    } finally {
      setIsCancelLoading(false);
    }
  }

  const userHandleCancel = async () => {
    setIsCancelLoading(true);
    try {
      setMakeEditAsset(initialMakeEditAsset);
      setPublicSources([]);
      setIsCancel(false);
      navigate('/assets');
    } catch (error) {
      console.error("Error during cancellation:", error);
    } finally {
      setIsCancelLoading(false);
    }
  }

  return (
    <>
      {
        membership === "admin"
          ? (<MakeAssetContent setIsCancel={setIsCancel} isLoading={isLoading} setIsLoading={setIsLoading} setIsFileLoading={setIsFileLoading} />)
          : (<MakeUserAssetContent setIsCancel={setIsCancel} isLoading={isLoading} setIsLoading={setIsLoading} />)
      }
      {
        membership === "admin"
          ? (<YesOrNoDialog open={isCancel} setOpen={setIsCancel} title="작성중인 내용이 있습니다. 정말로 취소하시겠습니까?" textContents={["진행중인 사항은 저장되지 않습니다."]} yesText="확인" onYes={handleCancel} noText="취소" onNo={() => setIsCancel(false)} />)
          : (<YesOrNoDialog open={isCancel} setOpen={setIsCancel} title="작성중인 내용이 있습니다. 정말로 취소하시겠습니까?" textContents={["진행중인 사항은 저장되지 않습니다."]} yesText="확인" onYes={userHandleCancel} noText="취소" onNo={() => setIsCancel(false)} />)
      }

      <LoadingDialog isLoading={isLoading} text="저장중입니다. 잠시후 스튜디오로 이동합니다." />
      <LoadingDialog isLoading={isCancleLoading} text="취소 처리 중입니다. 잠시만 기다려주세요." />
      <LoadingDialog isLoading={isFileLoading} text="파일 검증 중입니다. 잠시만 기다려주세요." />
    </>
  );
}

export default MakeAsset;
