import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import { CustomLayout } from '../../Atoms/StudioAtom';

interface LayoutsProps {
  savedLayouts: CustomLayout[];
  unsavedLayouts: CustomLayout[];
}

interface LayoutContextProps {
  layouts: LayoutsProps;
  setLayouts: Dispatch<SetStateAction<LayoutsProps>>;
}

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined);

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [layouts, setLayouts] = useState<LayoutsProps>({ savedLayouts: [], unsavedLayouts: [] });

  return (
    <LayoutContext.Provider value={{ layouts, setLayouts }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayouts = (): LayoutContextProps => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};
