import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { CommonChartProps } from "../../..";
import { selectedThesisInfoAtom } from "../../../../../Atoms/DashboardAtom";
import { studioHeaderAtom } from "../../../../../Atoms/StudioAtom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colorMap } from "../../../../../Data/colorMap";
import { useData } from "../../../../../Providers/Data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ThesisYearlyChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const { thesis } = useData();
  const selectedThesisInfo = useAtomValue(selectedThesisInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const thesisCountByYear = thesis.reduce((acc: { [key: string]: number }, item) => {
      const year = item.publishYear ? item.publishYear : null;
      if (!year) return acc;
      if (!acc[year]) acc[year] = 0;
      acc[year] += 1;
      return acc;
    }, {});

    const labels = Object.keys(thesisCountByYear).sort();
    const data = labels.map(year => thesisCountByYear[year]);
    const selectedItem = selectedThesisInfo.find((item) => item.pageId === studioHeader.currentPageId);
    const selectedYear = selectedItem?.item?.publishYear ? selectedItem.item.publishYear as string : "";
    const backgroundColors = labels.map((year) => {
      return year === String(selectedYear) ? colorMap[0] : colorMap[2]
    });

    const chartData = {
      labels,
      datasets: [
        {
          label: '논문 발행 건수',
          data,
          backgroundColor: backgroundColors,
          borderWidth: 1,
        },
      ],
    };

    setChartData(chartData);
  }, [thesis, selectedThesisInfo, studioHeader]);

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          generateLabels: function (chart: any) {
            const data = chart.data;
            if (data.datasets.length) {
              return [
                {
                  text: '논문 발행 건수',
                  fillStyle: colorMap[2],
                  strokeStyle: colorMap[2],
                  lineWidth: 1,
                },
                {
                  text: '현재 선택한 논문',
                  fillStyle: colorMap[0],
                  strokeStyle: colorMap[0],
                  lineWidth: 1,
                },
              ];
            }
            return [];
          },
        },
      }
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            연도별 논문 발행 수
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box sx={{ display: "flex", maxHeight: `${customLayout.h * rowHeight - 90}px`, minHeight: `${customLayout.h * rowHeight - 90}px`, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          {chartData ? <Bar options={options} data={chartData} /> : <Typography>Loading chart...</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ThesisYearlyChart;
