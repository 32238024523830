/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { colorMap } from "../../../../Data/colorMap";
import { CommonChartProps } from "../../../Charts";
import BubbleChart from "../../../Charts/Utils/Bubble";
import { selectedTechInfoAtom, TrendGridItem } from "../../../../Atoms/DashboardAtom";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";
import useProcessTrendSourceData from "../../../../Hooks/useProcessSourceData/Trend";

const TechGrid = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const [filteredItems, setFilteredItems] = useState<TrendGridItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<TrendGridItem | null>(null);
  const [selectedTechInfo, setSelectedTechInfo] = useAtom(selectedTechInfoAtom);
  const [data, setData] = useState<any>({ datasets: [] });
  const studioHeader = useAtomValue(studioHeaderAtom);
  const categoryColorMap: { [key: string]: string } = {};

  const getColorForCategory = (category: string) => {
    if (!categoryColorMap[category]) {
      const index = Object.keys(categoryColorMap).length % colorMap.length;
      categoryColorMap[category] = colorMap[index];
    }
    return categoryColorMap[category];
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: '사회적 파장정도',
        },
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: '소비에 영향을 미치는 정도',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            const categories = Array.from(new Set(datasets.map((dataset: any) => dataset.division))) as string[];
            return categories.map((category) => ({
              text: category,
              fillStyle: getColorForCategory(category),
            }));
          }
        }
      },
      datalabels: {
        color: '#000',
        font: {
          weight: 'medium',
          size: 12,
        },
        align: 'center',
        textAlign: 'center',
        anchor: 'start',
        formatter: (value: any, context: any) => {
          return context.chart.data.datasets[context.datasetIndex].label
        },
      },
    },
  };

  useProcessTrendSourceData({
    customLayout,
    setFilteredItems,
    selectedItem,
    filterKeyword: "기술"
  });

  const handleItemClicked = (clickedItem: any) => {
    const currentSelectedItem = filteredItems.find((item) => item.keyword === clickedItem.label);
    if (!currentSelectedItem) return;
    const isSelected = selectedItem && selectedItem.keyword === clickedItem.label;
    const updatedTechInfo = selectedTechInfo.filter((info) => info.pageId !== studioHeader.currentPageId);
    setSelectedItem(isSelected ? null : currentSelectedItem);
    setSelectedTechInfo(isSelected ? updatedTechInfo : [...updatedTechInfo, { pageId: studioHeader.currentPageId, selectedKeyword: currentSelectedItem.keyword }]);
  };

  useEffect(() => {
    const datasets = filteredItems.map((item) => ({
      label: item.keyword,
      keyword: item.keyword,
      division: item.division,
      category: item.category,
      data: [{
        x: item.consumptionEffect,
        y: item.socialWave,
        r: selectedItem && selectedItem.keyword === item.keyword ? 15 : 7,
      }],
      backgroundColor: selectedItem && selectedItem.keyword === item.keyword
        ? getColorForCategory(item.division)
        : `${getColorForCategory(item.division)}`,
    }));
    setData({ datasets });
  }, [customLayout, filteredItems, selectedItem]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div" >
            기술 트렌드 그리드
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 90}px`, maxHeight: `${customLayout.h * rowHeight - 90}px`, gap: "5px" }} onMouseDown={(e) => e.stopPropagation()}>
          <BubbleChart data={data} options={options} onElementClick={handleItemClicked} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default TechGrid;
