import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  SourceProps,
  makeEditAssetAtom,
  sourceOptionAtom,
  alertMessageAtom,
  alertType,
  initialSource,
  initialMakeEditAsset,
  MatchedColumnsProps,
  MatchedColumns2Props,
} from "../../../Atoms/AssetAtom";
import {
  Alert,
  Option,
  Select,
} from "@mui/joy";
import Input from "@mui/joy/Input";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import { memberAtom, userAtom } from "../../../Atoms/UserAtom";
import { apiAddress } from "../../../Atoms/GlobalVariable";
import { dashboardBaseThumbnail } from "../../../Data/thumbnailMetadata";
import { studioHeaderAtom } from "../../../Atoms/StudioAtom";
import * as S from './styled';
import * as C from './containers';
import SelectAsset from "../../Select/Asset";
import { useUser } from "../../../Providers/User";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type FileType = 'excel' | 'api' | 'crawling' | 'video' | 'img';
interface FileExtensions {
  [key: string]: string[];
}

interface MakeAssetContentProps {
  setIsCancel: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MakeAssetContent = ({ setIsCancel, isLoading, setIsLoading, setIsFileLoading }: MakeAssetContentProps) => {
  const [makeEditAsset, setMakeEditAsset] = useAtom(makeEditAssetAtom);
  const [sourceOption] = useAtom(sourceOptionAtom);
  const [alertMessage, setAlertMessage] = useAtom(alertMessageAtom);
  const member = useAtomValue(memberAtom);
  const setUser = useSetAtom(userAtom);
  const setStudioHeader = useSetAtom(studioHeaderAtom);
  const navigate = useNavigate();
  const [source, setSource] = useState<SourceProps>(initialSource);
  const [sourceMode, setSourceMode] = useState<{
    mode: string;
    index: number;
  }>({
    mode: "create",
    index: -1,
  });
  const fileLabelRef = useRef<HTMLLabelElement | null>(null);
  const refetchUserData = useUser().refetchUserData;
  const [matchedColumns, setMatchedColumns] = useState<MatchedColumnsProps | null>(null);
  const [apiCrawlingOrders, setApiCrawlingOrders] = useState<{
    id: string,
    fileType: string,
    sourceName: string,
  }[]>([]);
  const [openDialog, setOpenDialog] = useState<MatchedColumns2Props | null>(null);
  const [dialogSearch, setDialogSearch] = useState<string>('');

  // Alert 메시지를 띄워주는 함수, 2초 후에 사라집니다.
  const handleAlertMessage = (message: string, type: alertType) => {
    setAlertMessage({
      message: message,
      type: type,
    });
    setTimeout(() => {
      setAlertMessage({
        message: "",
        type: "danger",
      });
    }, 2000);
  }

  const handleSourceName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSource((current) => ({ ...current, sourceName: event.target.value }));
  };

  const changeFileExtension = (fileType: FileType): string[] => {
    const fileExtensions: FileExtensions = {
      excel: ["xlsx", "xls", "csv"],
      api: ["py"],
      crawling: ["py"],
      video: ["mp4"],
      img: ["jpg", "jpeg", "png"],
      default: ["pdf"]
    };
    return fileExtensions[fileType] || fileExtensions.default;
  };

  const deleteExistingFile = async (fileId: string) => {
    try {
      const formData = new FormData();
      formData.append('fileId', fileId);
      const response = await fetch(`${apiAddress}/asset/file/delete`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to delete existing file');
      }
    } catch (error) {
      console.error('Error deleting existing file:', error);
    }
  };

  // const handleHaveToSpecifyCategory = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setSource((current) => ({
  //     ...current,
  //     haveTospecifyCategory: event.target.checked,
  //     category: event.target.checked ? current.category : null,
  //   }));
  // };

  // const handleSourceCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value !== "")
  //     setSource((current) => ({ ...current, category: event.target.value }));
  //   else setSource((current) => ({ ...current, category: null }));
  // };

  const handleSourceKeywords = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "")
      setSource((current) => ({ ...current, keywords: event.target.value }));
    else setSource((current) => ({ ...current, keywords: null }));
  };

  const handleSourceUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "")
      setSource((current) => ({ ...current, url: event.target.value }));
    else setSource((current) => ({ ...current, url: null }));
  };

  const handleSourceUsingModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== "")
      setSource((current) => ({ ...current, usingModel: event.target.value }));
    else setSource((current) => ({ ...current, usingModel: null }));
  };

  const handleTableName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    // Check if the new value contains only English letters
    if (/^[a-zA-Z]*$/.test(newValue)) {
      setSource((current) => ({ ...current, tableName: newValue }));
    }
  };

  const handleCardClick = () => {
    console.log(`source.assetType: ${source.assetType}, source.fileType: ${source.fileType}`);
    if (
      source.assetType !== "" &&
      source.fileType !== "" &&
      fileLabelRef.current
    ) {
      fileLabelRef.current.click();
    }
  };

  const handleSaveAsset = async () => {
    if (makeEditAsset.name === "" || makeEditAsset.description === "") {
      handleAlertMessage("Asset명과 설명을 입력해주세요.", "danger");
      return;
    } else if (makeEditAsset.sources.length === 0) { // Asset Sources가 비어있을 경우
      handleAlertMessage("Asset을 추가해주세요.", "danger");
      return;
    } else if (sourceMode.mode === "edit") { // 수정 중인 source가 있을 경우
      handleAlertMessage("Asset Source 수정 중입니다. 수정을 완료해주세요.", "danger");
      return;
    }
    setIsLoading(true);
    // 저장 버튼을 눌렀을 때의 동작
    const formData = new FormData();
    formData.append('assetName', makeEditAsset.name);
    formData.append('description', makeEditAsset.description);
    formData.append('isPrivate', makeEditAsset.isPrivate ? 'true' : 'false');
    formData.append('userId', member?.id ?? '');
    formData.append('sources', JSON.stringify(makeEditAsset.sources));
    formData.append('thumbnail', dashboardBaseThumbnail);
    let response = null;
    try {
      if (makeEditAsset.id !== "") {
        // asset을 수정하는 경우
        console.log("수정 요청 보내기");
        formData.append('assetId', makeEditAsset.id);
        response = await fetch(`${apiAddress}/asset/edit`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) throw new Error('수정에 실패했습니다.');
      } else {
        // asset을 새로 생성하는 경우
        response = await fetch(`${apiAddress}/asset/create`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) throw new Error('파일 업로드에 실패했습니다.');
      }
      setMakeEditAsset(initialMakeEditAsset);
    } catch (error: any) {
      handleAlertMessage(`${error.message}`, "danger");
    } finally {
      if (!response) return;
      const data = await response.json();
      refetchUserData();
      setUser((prev) => ({ ...prev, currentAsset: data.asset }));
      setStudioHeader((prev) => ({ ...prev, currentPageId: data.asset.pages[0].id }));
      setTimeout(() => {
        setIsLoading(false);
        navigate("/studio");
      }, 2000);
    }
  };

  const onAddButtonClick = () => {
    if (
      source.assetType !== "" && source.fileType !== "" && source.sourceName !== "" && source.file !== null &&
      // 카테고리 수동 설정했을 경우
      (source.haveTospecifyCategory ? source.category !== null : true) &&
      // 재학습을 한다고 했을 경우
      (source.haveToRetrain ? source.retrainSchedule !== null && source.retrainStartDate !== null && source.retrainEndDate !== null : true) &&
      // 어셋 타입이 etc 일 경우
      (source.assetType === "etc" && source.fileType === "excel" ? source.tableName !== null : true)
    ) {
      const newSource = { ...source, matchedColumns: matchedColumns };
      setMakeEditAsset((current) => ({
        ...current,
        sources:
          current.sources.length !== 0
            ? [...current.sources, newSource]
            : [newSource],
      }));
      console.log("source: ", newSource);
      setSource(initialSource);
      setMatchedColumns(null);
    } else {
      console.error("이건 못넣지!~~~");
    }
  };

  const onEditButtonClick = () => {
    if (
      source.assetType !== "" && source.fileType !== "" && source.sourceName !== "" && source.file !== null &&
      // 카테고리 수동 설정했을 경우
      (source.haveTospecifyCategory ? source.category !== null : true) &&
      // 재학습을 한다고 했을 경우
      (source.haveToRetrain ? source.retrainSchedule !== null && source.retrainStartDate !== null && source.retrainEndDate !== null : true) &&
      // 어셋 타입이 etc 일 경우
      (source.assetType === "etc" && source.fileType === "excel" ? source.tableName !== null : true)
    ) {
      const newSource = { ...source, matchedColumns: matchedColumns };
      setMakeEditAsset((current) => {
        // 기존 sources 배열을 복사합니다.
        const newSources = [...current.sources];
        newSources[sourceMode.index] = newSource; // newSource를 반영

        return {
          ...current,
          sources: newSources,
        };
      });
      setSource(initialSource);

      setSourceMode({
        mode: 'create',
        index: -1,
      })
    } else {
      console.error("이건 못넣지!~~~");
    }
  };

  const editSource = (index: number) => {
    setMatchedColumns({
      columns: makeEditAsset.sources[index].matchedColumns?.columns ?? [],
      matchedColumns: makeEditAsset.sources[index].matchedColumns?.matchedColumns ?? [],
    })
    setSource(makeEditAsset.sources[index]);
    setSourceMode({ mode: "edit", index: index });
  }

  const deleteSource = async (index: number) => {
    console.log("deleteSource index: ", index);
    console.log("deleteSource makeEditAsset: ", makeEditAsset.sources[index]);
    try {
      const formData = new FormData();
      if (makeEditAsset.sources[index].id === "") {
        formData.append('fileId', makeEditAsset.sources[index].file?.fileId ?? '');
        const response = await fetch(`${apiAddress}/asset/file/delete`, {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          throw new Error('Failed to delete file');
        }
      } else {
        formData.append('sourceId', makeEditAsset.sources[index].id);
        const response = await fetch(`${apiAddress}/asset/source/delete`, {
          method: 'POST',
          body: formData
        });
        if (!response.ok) {
          throw new Error('Failed to delete source');
        }
      }
      setMakeEditAsset(current => ({ ...current, sources: current.sources.filter((_, i) => i !== index) }))
    } catch (error) {
      console.error(error);
    }
  }

  const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length === 1) {
      const file = event.target.files[0];
      const fileSplitList = file.name.split(".");
      const fileExtension = changeFileExtension(source.fileType as FileType);
      if (!fileExtension.includes(fileSplitList[fileSplitList.length - 1])) {
        setSource((current) => ({ ...current, file: null }));
        handleAlertMessage(`파일 확장자가 올바르지 않습니다.    .${fileExtension.join(", ")} 파일을 업로드해주세요.`, "danger");
        return;
      }
      try {
        setIsFileLoading(true);
        if (source.file && source.file.fileId) {
          await deleteExistingFile(source.file.fileId);
        }
        const formData = new FormData();
        formData.append("fileType", source.fileType);
        formData.append("file", file);
        formData.append("sourceType", source.assetType);
        const response = await fetch(`${apiAddress}/asset/file/upload`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('파일 업로드에 실패했습니다.');
        }
        const data = await response.json();
        if (source.fileType === "excel") {
          setMatchedColumns({
            columns: data.columns,
            matchedColumns: data.matchedColumns
          });
        }
        setSource((current) => ({
          ...current,
          file: data.file,
          sourceName: source.fileType === "excel" ? file.name.replace(
            `.${fileSplitList[fileSplitList.length - 1]}`,
            ""
          ) : file.name,
        }));
      } catch (e) {
        console.error(e);
        handleAlertMessage('파일 업로드에 실패했습니다.', 'danger');
      }
    }
    setIsFileLoading(false);
  }

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(apiCrawlingOrders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    console.log("items: ", items);

    setApiCrawlingOrders(items);
  };

  useEffect(() => {
    const newApiCrawlingOrders = makeEditAsset.sources.filter((source) => source.fileType === "api" || source.fileType === "crawling").map((source) => ({
      id: source.file?.fileId ?? "",
      fileType: source.fileType,
      sourceName: source.sourceName,
    }));
    setApiCrawlingOrders(newApiCrawlingOrders);
  }, [makeEditAsset.sources]);

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 56px)" }} >
      {/* 왼쪽 설정 */}
      <Box sx={S.LeftBoxContainer}>
        <S.PrevAndSaveButtonContainer>
          <Button
            sx={{
              height: "35px",
              border: "1px solid #134cf8",
              color: "#134cf8",
            }}
            variant="outlined"
            startIcon={(<ArrowBackIcon />)}
            onClick={() => setIsCancel(true)}
            disabled={isLoading}
          >
            <Typography>취소</Typography>
          </Button>
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "center", alignItems: "center" }}>
            {alertMessage.message !== "" && <Alert color={alertMessage.type}>{alertMessage.message}</Alert>}
            <Button
              sx={{
                height: "35px",
                border: "1px solid #134cf8",
                color: "#134cf8",
              }}
              variant="outlined"
              startIcon={(<SaveIcon />)}
              onClick={handleSaveAsset}
              disabled={isLoading}
            >
              <Typography>저장</Typography>
            </Button>
          </div>
        </S.PrevAndSaveButtonContainer>

        {/* private public 설정 */}
        <C.PrivatePublicSelectSwitch />

        {/* User(Not Admin)의 경우, 기존 Asset을 선택할 수 있는 기능 추가 */}
        {/* {membership !== "admin" &&
          <>
            <Typography fontSize={13} color={"#8f8f8f"}>
              기존 ASSET 선택
            </Typography>
            <C.SelectExistingAsset selectKey="select-existing-asset" placeholder="기존 Asset 선택" value={""} options={publicAssets} />
          </>
        } */}

        {/* ASSET 설정 */}
        <C.SetAsset />

        {/* source 설정 */}
        <Typography fontSize={13} color={"#8f8f8f"}>
          Sources From
        </Typography>
        <div id="source-type" key="source-type" style={{ display: "flex", width: "100%", gap: "10px", flexDirection: "column" }}>
          {/* Source 유형 설정 */}
          <SelectAsset selectKey="source-type" placeholder="Source Type 설정" value={source.assetType} options={sourceOption.assetType} setSource={setSource} type="assetType" />

          {/* file Type 설정 */}
          {source.assetType !== "" &&
            <>
              <SelectAsset selectKey="file-type" placeholder="Asset으로 만들 File 유형 설정" options={sourceOption.assetType.filter((assetType) => assetType.value === source.assetType)[0].child} value={source.fileType} setSource={setSource} type="fileType" />

              {source.fileType !== "" &&
                <>
                  <Box sx={{ '& .MuiOutlinedInput-root': { height: '50px', minHeight: '50px' } }}>
                    <TextField id="outlined-basic" label={source.fileType === "excel" ? "SOURCE명" : "출처명"} variant="outlined"
                      placeholder={source.fileType === "excel" ? "ASSET source명을 기입해주세요." : "ASSET 출처명을 기입해주세요."} value={source.sourceName} onChange={handleSourceName} fullWidth />
                  </Box>
                  <Box sx={{ '& .MuiOutlinedInput-root': { height: 'auto', minHeight: '100px' } }}>
                    <TextField
                      id="outlined-basic"
                      name="description"
                      label="SOURCE 설명"
                      variant="outlined"
                      placeholder="SOURCE 설명을 기입해주세요."
                      value={source.description}
                      onChange={(e) => setSource((current) => ({ ...current, description: e.target.value }))}
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Box>

                  {/* 출처 URL 입력 */}
                  {
                    source.fileType !== "excel" &&
                    <Box sx={{ '& .MuiOutlinedInput-root': { height: '50px', minHeight: '50px' } }}>
                      <TextField id="outlined-basic"
                        label="URL"
                        variant="outlined"
                        placeholder="해당 url를 입력해주세요."
                        value={source.url ?? ""}
                        onChange={handleSourceUrl} fullWidth />
                    </Box>
                  }

                  {/* 파일 업로드 */}
                  <C.SourceForm source={source} sourceMode={sourceMode} handleCardClick={handleCardClick} handleFileInput={handleFileInput} fileLabelRef={fileLabelRef} />

                  {
                    matchedColumns &&
                    matchedColumns.matchedColumns
                      .filter((matchedColumn) => !matchedColumn.itemColumn.endsWith("_"))
                      .map((matchedColumn, index) => {
                        return (
                          <Box key={index} sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                width: '150px', // 고정된 너비로 설정
                                whiteSpace: 'nowrap', // 한 줄로 표시
                                overflow: 'hidden', // 넘치는 텍스트 숨김
                                textOverflow: 'ellipsis',
                              }}
                            >{matchedColumn.itemColumn}</Typography>
                            <Select
                              key={`${index}-select`}
                              sx={{
                                flex: 1,
                                padding: "10px",
                                height: "50px",
                              }}
                              placeholder="매칭할 컬럼을 선택해주세요."
                              value={matchedColumn.matchedColumns.length > 0 ? matchedColumn.matchedColumns[0] : ""}
                              onChange={(event, newValue) => {
                                if (!newValue) return;
                                const newMatchedColumns = matchedColumns.matchedColumns.map((matched) => {
                                  if (matched.itemColumn === matchedColumn.itemColumn) {
                                    return {
                                      ...matched,  // 기존 객체 복사
                                      matchedColumns: [newValue as string],  // 새로운 값으로 업데이트
                                    };
                                  }
                                  return matched;  // 조건에 맞지 않으면 원래 객체 반환
                                });
                                setMatchedColumns({
                                  ...matchedColumns,
                                  matchedColumns: newMatchedColumns,
                                });
                              }}
                            >
                              {matchedColumns.columns.map((option) => (
                                <Option key={`${index}-${option}`} value={option}>{option}</Option>
                              ))}
                            </Select>
                          </Box>
                        );
                      })
                  }
                  <Divider />
                  {
                    matchedColumns &&
                    matchedColumns.matchedColumns
                      .filter((matchedColumn) => matchedColumn.itemColumn.endsWith("_"))
                      .map((matchedColumn, index) => {
                        return (
                          <>
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px',
                              }}
                            >
                              {/* 마지막 문자가 _인 경우 제거 후 표시 */}
                              <Typography
                                sx={{
                                  width: `150px`, // 고정된 너비로 설정
                                  whiteSpace: 'nowrap', // 한 줄로 표시
                                  overflow: 'hidden', // 넘치는 텍스트 숨김
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {matchedColumn.itemColumn.slice(0, -1)}
                              </Typography>
                              <Box
                                sx={{
                                  minWidth: 'calc(100% - 220px)',
                                  maxWidth: 'calc(100% - 220px)', // 최대 너비 설정
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                  fontSize: '12px',
                                  gap: '10px',
                                  alignItems: 'center',
                                  // flex: 1, // 남은 공간을 차지
                                }}
                              >
                                {matchedColumn.matchedColumns.map((matchedColumnItem, i) => (
                                  <Box
                                    key={i}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      whiteSpace: 'nowrap',
                                      gap: '5px',
                                      alignItems: 'center',
                                      padding: '5px',
                                      backgroundColor: '#f0f0f0',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    {matchedColumnItem}
                                  </Box>
                                ))}
                              </Box>
                              <Button
                                variant="outlined"
                                sx={{ fontSize: "12px" }}
                                onClick={() => setOpenDialog(matchedColumn)}
                              >수정</Button>
                            </Box>
                            <Divider />
                          </>
                        );
                      })
                  }

                  {/* 카테고리 입력 */}
                  {
                    source.fileType !== "excel" &&
                    <Box sx={{ '& .MuiOutlinedInput-root': { height: '50px', minHeight: '50px' } }}>
                      <TextField id="outlined-basic"
                        label="키워드"
                        variant="outlined"
                        placeholder="해당 키워드를 입력해주세요."
                        value={source.keywords ?? ""}
                        onChange={handleSourceKeywords} fullWidth />
                    </Box>
                  }

                  {/* 사용 모델 */}
                  {
                    source.fileType !== "excel" &&
                    <Box sx={{ '& .MuiOutlinedInput-root': { height: '50px', minHeight: '50px' } }}>
                      <TextField id="outlined-basic"
                        label="사용 모델"
                        variant="outlined"
                        placeholder="해당 모델을 입력해주세요."
                        value={source.usingModel ?? ""}
                        onChange={handleSourceUsingModel} fullWidth disabled />
                    </Box>
                  }

                  {/* 카테고리 입력 */}
                  {/* <Checkbox
                    label="수집할 키워드 입력"
                    onChange={handleHaveToSpecifyCategory}
                  />
                  {source.haveTospecifyCategory &&
                    <Box sx={{ '& .MuiOutlinedInput-root': { height: '50px', minHeight: '50px' } }}>
                      <TextField id="outlined-basic" label="카테고리" variant="outlined" placeholder="해당 카테고리를 입력해주세요." value={source.category ?? ""} onChange={handleSourceCategory} fullWidth />
                    </Box>
                  } */}

                  {/* 테이블명 */}
                  {source.assetType === "etc" && source.fileType === "excel" ? (
                    <>
                      <Input
                        size={"md"}
                        placeholder="저장할 테이블명을 입력해주세요."
                        sx={{
                          padding: "10px",
                          width: "100%",
                          height: "50px",
                          "--Input-focusedThickness": "0.1rem",
                          "&:focus-within": {
                            "--Input-focusedHighlight": "#8faafb",
                          },
                        }}
                        value={source.tableName ?? ""}
                        onChange={handleTableName}
                      />

                      <div style={{ height: "15px" }}></div>
                    </>
                  ) : null}

                  {/* 재학습 반복 여부 관련 설정 */}
                  {(source.fileType === "api" || source.fileType === "crawling") &&
                    <C.SetRetrain source={source} setSource={setSource} />
                  }

                  <IconButton
                    sx={{
                      height: "50px",
                      width: "50px",
                      background: sourceMode.mode === "create" ? "#134cf8" : "#d943ff",
                      color: "white",
                      transition: "background 0.8s ease",
                      "&:hover": {
                        background:
                          sourceMode.mode === "create" ? "#134cf8" : "#d943ff",
                      },
                    }}
                    onClick={sourceMode.mode === "create" ? onAddButtonClick : onEditButtonClick}
                  >
                    {sourceMode.mode === "create" ? <AddIcon /> : <EditIcon />}
                  </IconButton>
                </>}
            </>}
        </div>

        <div style={{ height: "20px" }}></div>
      </Box>

      {/* 오른쪽 설정 */}
      <Box
        sx={S.RightBoxContainer}
      >
        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset명
        </Typography>
        <Typography color={makeEditAsset.name === "" ? "red" : "black"}>
          {makeEditAsset.name === ""
            ? "Asset 명을 기입해주세요."
            : makeEditAsset.name}
        </Typography>

        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset 설정
        </Typography>
        <Typography color={makeEditAsset.isPrivate ? "red" : "blue"}>
          {makeEditAsset.isPrivate ? "비공개" : "공개"}
        </Typography>

        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset 설명
        </Typography>
        <Typography color={makeEditAsset.description === "" ? "red" : "black"}>
          {makeEditAsset.description === ""
            ? "Asset 설명을 작성해주세요."
            : makeEditAsset.description}
        </Typography>

        <>
          {apiCrawlingOrders.length > 0 && (
            <>
              <Typography fontSize={13} color={"#8f8f8f"}>
                API 및 크롤링 코드 동작 우선순위
              </Typography>
              <Typography fontSize={12} color={"#8f8f8f"}>
                주기적으로 서버에서 실행될 때, 우선순위가 높은 코드부터 실행됩니다.
              </Typography>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="apiCrawlingOrders">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {apiCrawlingOrders.map((order, index) => (
                        <Draggable key={order.id} draggableId={order.id.toString()} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: '8px',
                                marginBottom: '4px',
                                backgroundColor: snapshot.isDragging ? '#e0e0e0' : '#f0f0f0',
                                borderRadius: '4px',
                                boxShadow: snapshot.isDragging ? '0 1px 5px rgba(0,0,0,0.2)' : '0 1px 3px rgba(0,0,0,0.2)',
                              }}
                            >
                              <Typography>{order.sourceName}</Typography>
                              <DragIndicatorIcon style={{ color: "#4f4f4f" }} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </>

        <Typography fontSize={13} color={"#8f8f8f"}>
          Asset Sources
        </Typography>
        {makeEditAsset.sources.map((source, index) =>
          sourceMode.index === index
            ? (
              <Box
                key={`source-${index}`}
                sx={{
                  height: "40px",
                  borderBottom: "1px solid #dfdfdf",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#8f8f8f",
                  color: "white",
                  '& div button': {
                    background: "#8f8f8f",
                    color: "white",
                  }
                }}
              >
                <Typography>{source.sourceName}</Typography>
                <Typography>수정중...</Typography>
              </Box>
            )
            : (
              <Box
                key={`source-${index}`}
                sx={{
                  height: "40px",
                  borderBottom: "1px solid #dfdfdf",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  '&:hover': {
                    background: "#134cf8",
                    color: "white",
                    borderBottom: "1px solid white",
                    '& div button': {
                      color: "white",
                    }
                  }
                }}
              >
                <Typography>{source.sourceName}</Typography>
                <div>
                  <IconButton
                    onClick={() => editSource(index)} >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => deleteSource(index)} >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Box>
            ))}
      </Box>
      <Dialog
        open={openDialog !== null}
        onClose={() => setOpenDialog(null)}
        fullWidth
        maxWidth="sm"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogTitle>칼럼 수정</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            fontSize: '12px',
            gap: '10px',
            alignItems: 'center',
            height: 'auto',
            maxHeight: '200px'
          }}
        >
          {openDialog && openDialog.matchedColumns.map((matchedColumnItem, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                gap: '5px',
                alignItems: 'center',
                padding: '5px',
                backgroundColor: '#f0f0f0',
                borderRadius: '10px',
              }}
            >
              {matchedColumnItem}
              <CloseIcon
                sx={{ width: "15px", height: "15px", cursor: "pointer" }}
                onClick={() => {
                  setOpenDialog({
                    ...openDialog,
                    matchedColumns: openDialog.matchedColumns.filter((column) => column !== matchedColumnItem),
                  })
                }}
              />
            </Box>
          ))}
        </DialogContent>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="컬럼 검색"
            variant="outlined"
            fullWidth
            value={dialogSearch}
            onChange={(e) => setDialogSearch(e.target.value)} // 검색어 변경 처리
          />
        </DialogContent>
        <DialogContent
          sx={{
            overflowY: 'auto', // 스크롤 바를 허용
            maxHeight: '300px', // 적절한 높이 설정
          }}
        >
          <List>
            <Divider />
            {matchedColumns && matchedColumns.columns
              .filter((column) => !openDialog?.matchedColumns.includes(column))
              .filter((column) => column.toLowerCase().includes(dialogSearch.toLowerCase()))
              .map((option, index) => (
                <div key={option}>
                  <ListItem
                    value={option}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#f5f5f5', // hover 시 배경색 변경
                      },
                      display: 'flex',
                      justifyContent: 'space-between', // 버튼을 오른쪽에 배치
                      alignItems: 'center', // 세로 정렬
                    }}
                  >
                    <Typography>{option}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        if (openDialog) {
                          setOpenDialog({
                            ...openDialog,
                            matchedColumns: [...openDialog.matchedColumns, option],
                          });
                        }
                      }}
                    >
                      추가
                    </Button>
                  </ListItem>
                  <Divider />
                </div>
              ))}
          </List>
        </DialogContent>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={() => setOpenDialog(null)}>닫기</Button>
          <Button onClick={() => {
            if (openDialog && matchedColumns) {
              setMatchedColumns({
                ...matchedColumns,
                matchedColumns: matchedColumns.matchedColumns.map((matched) => {
                  if (matched.itemColumn === openDialog.itemColumn) {
                    return {
                      ...matched,
                      matchedColumns: openDialog.matchedColumns,
                    };
                  }
                  return matched;
                }),
              });
              setOpenDialog(null);
            }
          }}>저장</Button>
        </DialogContent>
      </Dialog>
    </Box >
  );
}

export default MakeAssetContent;
