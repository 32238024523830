const convertSales = (value: number, front: string, back: string) => {
  if (value === null || value === undefined) return "-";

  // 백만 단위로 변환
  const tenThousand = 1e4;
  const billion = 1e8;

  if (value >= billion) {
    // 억 단위
    const billionValue = (value / billion).toFixed(1);
    return `${front && front} ${billionValue}억 ${back && back}`;
  } else if (value >= tenThousand) {
    // 만 단위
    const millionValue = (value / tenThousand).toFixed(1);
    return `${front && front} ${millionValue}만 ${back && back}`;
  } else {
    // 일반 단위
    return `${front && front} ${value} ${back && back}`;
  }
};

export default convertSales;
