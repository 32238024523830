import { useAtom } from 'jotai';
import { Avatar, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import * as S from './styled';
import { updateSizeAtom } from '../../ViewModels/HeaderViewModel';
import StudioHeader from './StudioHeader';
import { useLocation } from 'react-router-dom';
import AssetsHeader from './Assets';
import DashboardHeader from './Dashboard';
import { memberAtom } from '../../Atoms/UserAtom';

const additionalHeader = (path: string) => {
  switch (path) {
    case '/studio':
      return <StudioHeader />;
    case '/assets':
      return <AssetsHeader />;
    case '/':
      return <DashboardHeader />;
  }
  return null;
}

const calculateHeight = (path: string) => {
  switch (path) {
    case '/':
    case '/studio':
      return 112;
    default:
      return 56;
  }
}

const Header = () => {
  const [, updateSizeState] = useAtom(updateSizeAtom);
  const [user,] = useAtom(memberAtom);
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <S.Container style={{ height: `${calculateHeight(currentPath)}px` }}>
      <S.InnerContainer>
        <S.InnerInnerContainer $gap='16px'>
          <IconButton onClick={updateSizeState} sx={{ color: '#fff' }}>
            <MenuIcon />
          </IconButton>
        </S.InnerInnerContainer>
        <S.InnerInnerContainer $gap='30px'>
          <Button
            onClick={() => { }}
            variant="outlined" sx={{
              'color': '#fff', '@media (max-width: 500px)': {
                display: 'none'
              }
            }}>{
              user?.customFields['mbrship']
            }
          </Button>
          {/* <Button
            variant='text'
            sx={{
              color: '#fff',
              '@media (max-width: 500px)': {
                display: 'none',
              },
            }}
          >
            to Showcase
          </Button>
          <Button
            variant='text'
            sx={{
              color: '#fff',
              '@media (max-width: 500px)': {
                display: 'none',
              },
            }}
          >
            to affiliate
          </Button> */}
          <Avatar alt='Remy Sharp' src={''} />
          <div style={{ height: "5px" }}></div>
        </S.InnerInnerContainer>
      </S.InnerContainer>
      {additionalHeader(currentPath)}
    </S.Container>
  );
}

export default Header;
