export const mainColor: string = '#406AFD';
export const apiAddress: string = 'https://dashboard.mareummo.me/api';
// export const apiAddress: string = 'http://10.10.112.107:8000/api';
export const assetAddress: string = 'https://dashboard.mareummo.me/static';
// export const assetAddress: string = 'http://10.10.112.107:8000/static';
export const webAddress: string = 'https://dashboard.mareummo.me';
// export const webAddress: string = 'http://10.10.112.107:3000';

/**
 * 공통 레이아웃 변수
 * @type {number}
 * @description 모든 컴포넌트에서 사용하는 레이아웃 변수
 * @description 이 변수를 통해 width와 height의 단위값을 조정할 수 있습니다.
 */
export const commonLayoutVariable = 20;
