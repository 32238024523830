import styled from 'styled-components';

export const FullContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  display: block;
  min-height: 100%;
  min-width: 768px;
`;

const calculateHeight = (path: string) => {
  switch (path) {
    case '/':
    case '/studio':
      return 112;
    default:
      return 56;
  }
}

export const ContentContainer = styled.div<{ $currentPath: string }>`
  min-height: ${(props) => `calc(100vh - ${calculateHeight(props.$currentPath)}px)`};
  margin-top: ${(props) => calculateHeight(props.$currentPath)}px;
  margin-left: 0px;
  overflow-x: inherit;
  overflow-y: visible;
`;
