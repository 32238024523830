import {
  Box,
  Input,
  TextField,
  Typography
} from "@mui/material";
import { Card, Option, Radio, RadioGroup, Switch, Select } from "@mui/joy";
import { AssetProps, makeEditAssetAtom, publicSourceAtom, retrainScheduleOptionAtom, SourceProps } from "../../../Atoms/AssetAtom";
import * as S from "./styled";
import AnimatedButton from "../../Button/MakeAsset/AnimatedButton";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import { useAtom, useAtomValue } from "jotai";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

interface FileSelectorCardProps {
  source: SourceProps;
  sourceMode: { mode: string; index: number };
  handleCardClick: () => void;
  fileLabelRef: React.RefObject<HTMLLabelElement>;
}

/**
 * FileSelectorCard - 파일 선택 카드
 * @param {FileSelectorCardProps} {
 *  source, - SourceProps
 *  sourceMode, - { mode: string; index: number }
 *  fileLabelRef, - React.RefObject<HTMLLabelElement>
 *  handleCardClick - () => void
 * }
 * @returns {JSX.Element}
 */
const FileSelectorCard = ({ source, sourceMode, fileLabelRef, handleCardClick }: FileSelectorCardProps) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: "row",
      padding: "10px",
      height: "50px",
      boxSizing: "border-box",
      cursor:
        source.assetType !== "" && source.fileType !== ""
          ? "pointer"
          : "default",
      alignItems: "center",
    }}
    onClick={handleCardClick}
  >
    <label
      htmlFor="input-file"
      ref={fileLabelRef}
      onClick={(e) => e.stopPropagation()}
      style={{
        flex: 1,
        height: "100%",
        boxSizing: "border-box",
        background:
          source.assetType !== "" && source.fileType !== ""
            ? sourceMode.mode === "create" ? "#134cf8" : "#d943ff"
            : "#8f8f8f",
        padding: "3px 5px",
        border: "none",
        borderRadius: "5px",
        color: "white",
        textAlign: "center",
        marginRight: "15px",
        cursor:
          source.assetType !== "" && source.fileType !== ""
            ? "pointer"
            : "default",
      }}
    >
      파일선택
    </label>
    <Typography
      sx={{ flex: 5 }}
      color={source.file ? "black" : "#8f8f8f"}
    >
      {source.file?.originalName ?? "파일을 선택해 주세요."}
    </Typography>
  </Card>
);

interface SourceFormProps extends FileSelectorCardProps {
  handleFileInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * SourceForm - 파일 선택 카드와 파일 선택 input을 포함한 컴포넌트
 * @param {SourceFormProps} {
 *  source, - SourceProps
 *  sourceMode, - { mode: string; index: number }
 *  handleCardClick, - () => void
 *  handleFileInput, - (e: React.ChangeEvent<HTMLInputElement>) => void
 *  fileLabelRef - React.RefObject<HTMLLabelElement>
 * }
 * @returns {JSX.Element}
 */
export const SourceForm = ({
  source,
  sourceMode,
  handleCardClick,
  handleFileInput,
  fileLabelRef
}: SourceFormProps) => {
  return (
    <>
      <FileSelectorCard
        source={source}
        sourceMode={sourceMode}
        fileLabelRef={fileLabelRef}
        handleCardClick={handleCardClick}
      />
      <Input
        id="input-file"
        sx={{ display: "none" }}
        disabled={source.assetType !== "" && source.fileType !== "" ? false : true}
        onChange={handleFileInput}
        type="file"
      />
    </>
  )
}

/**
 * PrivatePublicSelectSwitch - private public 설정
 * @returns {JSX.Element}
 */
export const PrivatePublicSelectSwitch = () => {
  const [makeEditAsset, setMakeEditAsset] = useAtom(makeEditAssetAtom);
  return (
    <S.SwitchContainer>
      <S.SwitchContents>
        <AnimatedButton
          isactive={makeEditAsset.isPrivate}
          startIcon={<LockIcon />}
          onClick={() => setMakeEditAsset((current) => ({ ...current, isPrivate: true }))}
        >
          Private
        </AnimatedButton>
        <AnimatedButton
          isactive={!makeEditAsset.isPrivate}
          startIcon={<PublicIcon />}
          onClick={() => setMakeEditAsset((current) => ({ ...current, isPrivate: false }))}
        >
          Public
        </AnimatedButton>
      </S.SwitchContents>
    </S.SwitchContainer>
  )
}

interface HaveToRetrainTypographyProps {
  text: string;
}

const HaveToRetrainTypography = ({ text }: HaveToRetrainTypographyProps) => (
  <Typography fontSize={"13px"} color={"#134cf8"}>
    {text}
  </Typography>
);

interface CommonSourceProps {
  source: SourceProps;
  setSource: React.Dispatch<React.SetStateAction<SourceProps>>;
}

/**
 * SetRetrain - 재학습 설정
 * @param {CommonSourceProps} {
 *  source, - SourceProps
 *  setSource - React.Dispatch<React.SetStateAction<SourceProps>>
 * }
 * @returns {JSX.Element}
 */
export const SetRetrain = ({ source, setSource }: CommonSourceProps) => {
  const handlehaveToRetrain = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSource((current) => ({
      ...current,
      haveToRetrain: event.target.checked,
      retrainStartDate: null,
      retrainEndDate: null,
      retrainSchedule: null,
    }));
  };

  return (
    <div style={{ display: "flex", width: "100%", gap: "10px" }}>
      {/* 재학습 반복 여부 스위치 */}
      <div
        style={{
          flex: 1,
        }}
      >
        <Switch
          endDecorator={
            <Typography sx={{ color: source.haveToRetrain ? "#134cf8" : "#8f8f8f" }} >
              재학습 반복 여부
            </Typography>
          }
          onChange={handlehaveToRetrain}
        />
      </div>

      {/* 스케줄 설정  */}
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", flex: 3 }}>
        {
          source.haveToRetrain &&
          <>
            {/* 날짜 설정 */}
            <SetDate source={source} setSource={setSource} />
            {/* 주기 설정 라디오 그룹 */}
            <SetRadioGroup source={source} setSource={setSource} />
          </>
        }
      </div>
    </div>
  )
}

/**
 * SetDate - 시작날짜, 종료날짜 설정
 * @param {CommonSourceProps} {
 *  source, - SourceProps
 *  setSource - React.Dispatch<React.SetStateAction<SourceProps>>
 * }
 * @returns {JSX.Element}
 */
const SetDate = ({ source, setSource }: CommonSourceProps) => {
  const handleDateChange = (name: string, newValue: Dayjs | null) => {
    if (newValue) {
      setSource((current) => {
        const updatedSource = { ...current, [name]: newValue.toDate() };
        // startDate가 endDate보다 늦을 경우 endDate를 startDate로 설정
        if (name === 'retrainStartDate' && updatedSource.retrainEndDate && newValue.isAfter(dayjs(updatedSource.retrainEndDate))) {
          updatedSource.retrainEndDate = newValue.toDate();
        }
        return updatedSource;
      });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <HaveToRetrainTypography text="스케줄 설정" />
      <S.SetDateContainer>
        <DatePicker
          label="시작 날짜"
          name="startDate"
          minDate={dayjs().add(1, "day")}
          value={source.retrainStartDate === null ? null : dayjs(source.retrainStartDate)}
          onChange={(newValue) => handleDateChange('retrainStartDate', newValue)}
          disabled={!source.haveToRetrain}
        />
        <DatePicker
          label="종료 날짜"
          name="endDate"
          minDate={source.retrainStartDate === null ? dayjs().add(2, "day") : dayjs(source.retrainStartDate)}
          value={source.retrainEndDate === null ? null : dayjs(source.retrainEndDate)}
          onChange={(newValue) => handleDateChange('retrainEndDate', newValue)}
          disabled={!source.haveToRetrain}
        />
      </S.SetDateContainer>
    </div>
  );
}

/**
 * SetRadioGroup - 반복 주기 설정 라디오 그룹
 * @param {CommonSourceProps} {
 *  source, - SourceProps
 *  setSource - React.Dispatch<React.SetStateAction<SourceProps>>
 * }
 * @returns {JSX.Element}
 */
const SetRadioGroup = ({ source, setSource }: CommonSourceProps) => {
  const retrainScheduleOption = useAtomValue(retrainScheduleOptionAtom);
  const handleRetrainSchedule = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSource((current) => ({
      ...current,
      retrainSchedule: event.target.value as unknown as number,
    }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <HaveToRetrainTypography text="반복 주기 설정" />
      <RadioGroup
        orientation="horizontal"
        aria-labelledby="segmented-controls-example"
        name="justify"
        value={source.retrainSchedule}
        onChange={handleRetrainSchedule}
        sx={{
          display: "flex",
          minHeight: 48,
          padding: "4px",
          borderRadius: "12px",
          bgcolor: "neutral.softBg",
          "--RadioGroup-gap": "4px",
          "--Radio-actionRadius": "8px",
        }}
      >
        {retrainScheduleOption.map((item) => (
          <Radio
            disabled={source.haveToRetrain ? false : true}
            key={item.value}
            color="neutral"
            value={item.value}
            disableIcon
            label={item.text}
            variant="plain"
            sx={{
              flex: 1,
              px: 2,
              alignItems: "center",
              textAlign: "center",
            }}
            slotProps={{
              action: ({ checked }) => ({
                sx: {
                  ...(checked && {
                    bgcolor: "background.surface",
                    boxShadow: "sm",
                    "&:hover": {
                      bgcolor: "background.surface",
                    },
                  }),
                },
              }),
            }}
          />
        ))}
      </RadioGroup>
    </div>
  )
}

export const SetAsset = () => {
  const [makeEditAsset, setMakeEditAsset] = useAtom(makeEditAssetAtom);
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setMakeEditAsset((current) => ({ ...current, [name]: value }));
  };
  return (
    <>
      <Typography fontSize={13} color={"#8f8f8f"}>
        ASSET 설정
      </Typography>
      <Box sx={{ '& .MuiOutlinedInput-root': { height: '50px', minHeight: '50px' } }}>
        <TextField id="outlined-basic" label="ASSET명" variant="outlined" placeholder="ASSET 명을 기입해주세요." name="name" value={makeEditAsset.name} onChange={handleInputChange} fullWidth />
      </Box>
      <Box sx={{ '& .MuiOutlinedInput-root': { height: 'auto', minHeight: '100px' } }}>
        <TextField
          id="outlined-basic"
          name="description"
          label="ASSET 설명"
          variant="outlined"
          placeholder="ASSET 설명을 기입해주세요."
          value={makeEditAsset.description}
          onChange={handleInputChange}
          multiline
          rows={4}
          fullWidth
        />
      </Box>
    </>
  )
}

interface SelectExistingAssetProps {
  selectKey: string;
  placeholder: string;
  value: string;
  options: AssetProps[];
}

export const SelectExistingAsset = ({ selectKey, placeholder, options, value }: SelectExistingAssetProps) => {
  const [makeEditAsset, setMakeEditAsset] = useAtom(makeEditAssetAtom);
  const [publicSource, setPublicSource] = useAtom(publicSourceAtom);

  // const createSourceData = (sources: SourceProps[]) => {
  //   return sources.map((source) => ({
  //     ...source,
  //   }))
  // }

  const handleSelectChange = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const selectedAsset = options.find(option => option.id === newValue);
    if (selectedAsset) {
      console.log('newValue', selectedAsset);
      setPublicSource(selectedAsset.sources);
      const newAsset = { ...makeEditAsset, name: selectedAsset.assetName, description: selectedAsset.description };
      setMakeEditAsset(newAsset);
      console.log('newAsset', newAsset);
    }
  };

  return (
    <Select
      key={`${selectKey}-select`}
      sx={{
        flex: 1,
        padding: "10px",
        height: "50px",
        minHeight: "50px",
        maxHeight: "50px",
      }}
      placeholder={placeholder}
      value={value}
      onChange={handleSelectChange}
    >
      {options.map((option) => (
        <Option key={`${selectKey}-${option.id}`} value={option.id}>
          {option.assetName}
        </Option>
      ))}
    </Select>
  );
};
