import { PageStateProps, ResponseFileData, ThesisInfoItem } from "../../../../Atoms/DashboardAtom";
import { mapperOption } from "../../../../Atoms/UserAtom";

interface ProcessTechSourceDataProps {
  assetId: string;
  setThesis: (items: ThesisInfoItem[]) => void;
  fetchAssetData: (assetId: string, type: string) => Promise<any>;
  fetchAssetDataByMapper: (assetId: string, mapper: mapperOption[]) => Promise<any>;
  mapper: mapperOption[] | null;
  selectedTechInfo: PageStateProps[];
  currentPageId: string;
}

const processThesisData = async ({ assetId, setThesis, fetchAssetData, fetchAssetDataByMapper, mapper, selectedTechInfo, currentPageId }: ProcessTechSourceDataProps) => {
  // Fetch item data
  let itemResponse;
  if (mapper) {
    itemResponse = await fetchAssetDataByMapper(assetId, mapper);
  } else {
    itemResponse = await fetchAssetData(assetId, "thesis");
  }
  if (itemResponse && itemResponse.data) {
    const data = itemResponse.data;
    // 논문 데이터 처리
    const allTrendKeywords = new Set<string>();
    data.forEach((thesis: ResponseFileData) => {
      const trendKeywordsArray = thesis.keywords?.split(' ; ') || [];
      trendKeywordsArray.forEach((keyword: string) => allTrendKeywords.add(keyword));
    });

    const allTrendKeywordsArray = Array.from(allTrendKeywords).filter((keyword) => keyword !== '');

    const items: ThesisInfoItem[] = data.map((thesis: ResponseFileData, index: number) => {
      const trendKeywordsArray = thesis.keywords?.split(' ; ').map((keyword: string) => keyword.trim()) || [];

      const trendData = allTrendKeywordsArray.map((keyword) => ({
        id: keyword,
        value: trendKeywordsArray.includes(keyword) ? 1 : 0,
      }));

      return {
        index,
        title: thesis.dataDetails.title || null,
        url: thesis.dataDetails.url || null,
        author: thesis.dataDetails.author || null,
        publishInstitution: thesis.dataDetails.publishInstitution || null,
        scholarpaperName: thesis.dataDetails.scholarpaperName || null,
        featureKeywords: thesis.dataDetails.featureKeywords || null,
        KDCDDC: thesis.dataDetails.KDCDDC || null,
        generalPeriodName: thesis.dataDetails.generalPeriodName || null,
        abstract: thesis.dataDetails.abstract || null,
        tableOfContents: thesis.dataDetails.tableOfContents || null,
        paperContent: thesis.dataDetails.paperContent || null,
        publishYear: parseInt(thesis.dataDetails.publishYear) || null,
        totalCountsOfDetailCheck: parseFloat(thesis.dataDetails.totalCountsOfDetailCheck) || null,
        countsOfDetailCheck: thesis.dataDetails.countsOfDetailCheck?.map((data: any) => ({ id: data.id, value: data.value })) || null,
        countsOfDownload: parseFloat(thesis.dataDetails.countsOfDownload) || null,
        countsOfExport: parseFloat(thesis.dataDetails.countsOfExport) || null,
        trendKeywords: trendData.length > 0 ? trendData : null,
      };
    });

    // 모든 아이템의 trendData를 합쳐 평균을 계산
    const trendAverages: { [key: string]: number } = {};
    const allTrendData: { [key: string]: number[] } = {};

    items.forEach((item) => {
      item.trendKeywords?.forEach((trend) => {
        if (!allTrendData[trend.id]) {
          allTrendData[trend.id] = [];
        }
        allTrendData[trend.id].push(trend.value);
      });
    });

    for (const trendId in allTrendData) {
      const total = allTrendData[trendId].reduce((acc, value) => acc + value, 0);
      trendAverages[trendId] = total / allTrendData[trendId].length;
    }

    const updatedItems = items.map((item) => ({
      ...item,
      trendKeywords: item.trendKeywords?.map((trend) => ({
        ...trend,
        average: trendAverages[trend.id],
      })) || null,
    }));

    const uniqueItems = updatedItems.filter((item, index, self) =>
      index === self.findIndex((t) => t.title === item.title)
    );

    const techInfo = selectedTechInfo.find((info) => info.pageId === currentPageId);
    if (techInfo) {
      const finalItems = uniqueItems.filter((item) =>
        item.trendKeywords?.find((keyword) => keyword.id.includes(techInfo.selectedKeyword))?.value === 1
      );
      setThesis(finalItems);
    } else {
      setThesis(uniqueItems);
    }
  }
};

export default processThesisData;
