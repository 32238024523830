/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, Typography, List, ListItem, Divider, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ItemData, selectedItemAtom } from "../../../../Atoms/DashboardAtom";
import { useAtomValue, useAtom } from "jotai";
import { CommonChartProps } from "../..";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";
import { useData } from "../../../../Providers/Data";

const HopefulnessItemList = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const [selectedHopefulnessItem, setSelectedHopefulnessItem] = useAtom(selectedItemAtom);
  const { items } = useData();
  const studioHeader = useAtomValue(studioHeaderAtom);

  const handleItemClicked = (item: ItemData) => {
    const prevItem = selectedHopefulnessItem.find((selectedItem) => selectedItem.pageId === studioHeader.currentPageId);
    if (prevItem) {
      setSelectedHopefulnessItem(selectedHopefulnessItem.map((selectedItem) => {
        if (selectedItem.pageId === studioHeader.currentPageId) {
          return { ...selectedItem, item };
        } else {
          return selectedItem;
        }
      }));
    } else {
      setSelectedHopefulnessItem([...selectedHopefulnessItem, { pageId: studioHeader.currentPageId, item }]);
    }
  }

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 10}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            유망아이템
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '30%' }}>제품명</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>가격</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>제조국</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '30%' }}>브랜드</Typography>
        </Box>
        <Divider />
        {items.length > 0 ? (
          <List>
            {items.map((item, index) => {
              const isSelected = selectedHopefulnessItem.find((selectedItem) => selectedItem.item.index === item.index);
              return (<div key={index}>
                <ListItem
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => handleItemClicked(item)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: isSelected ? "rgba(0, 123, 255, 0.1)" : "inherit",
                  }}
                >
                  <Box sx={{
                    display: "flex", justifyContent: "space-between", width: "100%",
                  }}>
                    <Typography variant="body2" sx={{ fontWeight: "600", width: '30%' }}>{item.name && item.name.length > 10 ? item.name?.substring(0, 10) + "..." : item.name}</Typography>
                    <Typography variant="body2" sx={{ width: '20%' }}>{item.price}원</Typography>
                    <Typography variant="body2" sx={{ width: '20%' }}>{item.manufacturingCountry}</Typography>
                    <Typography variant="body2" sx={{ width: '30%' }}>{item.brand}</Typography>
                  </Box>
                </ListItem>
                <Divider />
              </div>
              )
            })}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary">
            데이터를 불러오는 중입니다...
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default HopefulnessItemList;
