import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { CommonChartProps } from "../../..";
import { selectedPatentInfoAtom } from "../../../../../Atoms/DashboardAtom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colorMap } from "../../../../../Data/colorMap";
import { studioHeaderAtom } from "../../../../../Atoms/StudioAtom";
import { useData } from "../../../../../Providers/Data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PatentYearlyChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedPatentInfo = useAtomValue(selectedPatentInfoAtom);
  const { techs } = useData();
  const [chartData, setChartData] = useState<any>(null);
  const studioHeader = useAtomValue(studioHeaderAtom);

  useEffect(() => {
    let uniqueItems = techs.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.patentTitle === item.patentTitle && t.applicationDate === item.applicationDate
      ))
    );

    // Group patents by year and count the number of applications per year
    const patentCountByYear = uniqueItems.reduce((acc: { [key: string]: number }, item) => {
      const itemApplicationDate = item.applicationDate ? item.applicationDate : '';
      const year = itemApplicationDate.split(".")[0];

      if (!acc[year]) acc[year] = 0;
      acc[year] += 1;

      return acc;
    }, {});

    const labels = Object.keys(patentCountByYear).sort();
    const data = labels.map(year => patentCountByYear[year]);
    const selectedItem = selectedPatentInfo.find((selectedItem) => selectedItem.pageId === studioHeader.currentPageId)?.item.applicationDate;
    const selectedYear = selectedItem ? selectedItem.split(".")[0] : "2000";
    const backgroundColors = labels.map((year) =>
      year === selectedYear ? colorMap[0] : colorMap[2]
    );

    const chartData = {
      labels,
      datasets: [
        {
          label: '특허 출원 건수',
          data,
          backgroundColor: backgroundColors,
          borderWidth: 1,
        },
      ],
    };

    setChartData(chartData);
  }, [techs, selectedPatentInfo, studioHeader.currentPageId]);

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top' as const,
        labels: {
          generateLabels: function (chart: any) {
            const data = chart.data;
            if (data.datasets.length) {
              return [
                {
                  text: '특허 출원 건수',
                  fillStyle: colorMap[2],
                  strokeStyle: colorMap[2],
                  lineWidth: 1,
                },
                {
                  text: '현재 선택한 항목',
                  fillStyle: colorMap[0],
                  strokeStyle: colorMap[0],
                  lineWidth: 1,
                },
              ];
            }
            return [];
          },
        },
      }
    },
  };


  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            연도별 특허 출원 건수
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box sx={{ display: "flex", maxHeight: `${customLayout.h * rowHeight - 90}px`, minHeight: `${customLayout.h * rowHeight - 90}px`, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          {chartData ? <Bar options={options} data={chartData} /> : <Typography>Loading chart...</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}

export default PatentYearlyChart;
