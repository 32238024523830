import CloseIcon from '@mui/icons-material/Close';
import * as S from './styled';
import * as C from './containers';

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Sidebar 컴포넌트
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
interface StudioSidebarProps {
  anchor: 'left' | 'right';
  open: boolean;
  onClose: (isOpen: boolean) => void;
}

const StudioSidebar = ({ anchor, open, onClose }: StudioSidebarProps) => {
  const DrawerList = (
    <S.StyledBox role="presentation">
      <S.Header>
        {anchor === 'left' ? (
          <>
            <h4>페이지 설정</h4>
            <CloseIcon onClick={() => onClose(false)} style={{ cursor: "pointer" }} />
          </>
        ) : (
          <>
            <CloseIcon onClick={() => onClose(false)} style={{ cursor: "pointer" }} />
            <h4>레이아웃 설정</h4>
          </>
        )}
      </S.Header>
      {anchor === 'left' ? (
        <C.PageContents />
      ) : (
        <C.LayoutContents />
      )}
    </S.StyledBox>
  );

  return (
    <S.Container $open={open} $anchor={anchor}>
      <S.StyledPaper>
        {DrawerList}
      </S.StyledPaper>
    </S.Container>
  );
}

export default StudioSidebar;
// ----------------------------------------------------------------------------------------
