export const colorMap = [
  // 기본 색상
  "#FF5733", // 1 - 빨간색
  "#33FF57", // 2 - 녹색
  "#3357FF", // 3 - 파란색
  "#F0FF33", // 4 - 노란색
  "#FF33A6", // 5 - 분홍색
  "#33FFF5", // 6 - 청록색
  "#FF8C33", // 7 - 주황색
  "#8C33FF", // 8 - 보라색
  "#33FF8C", // 9 - 연두색
  "#FF3380", // 10 - 마젠타

  // 파스텔톤 색상
  "#FFB3A7", // 11 - 파스텔 빨간색
  "#B3FFB3", // 12 - 파스텔 녹색
  "#B3B3FF", // 13 - 파스텔 파란색
  "#FFFFB3", // 14 - 파스텔 노란색
  "#FFB3D9", // 15 - 파스텔 분홍색
  "#B3FFFF", // 16 - 파스텔 청록색
  "#FFCCB3", // 17 - 파스텔 주황색
  "#DAB3FF", // 18 - 파스텔 보라색
  "#B3FFC9", // 19 - 파스텔 연두색
  "#FFB3BF", // 20 - 파스텔 마젠타

  // 형광톤 색상
  "#FF4500", // 21 - 형광 빨간색
  "#32CD32", // 22 - 형광 녹색
  "#1E90FF", // 23 - 형광 파란색
  "#FFFF00", // 24 - 형광 노란색
  "#FF69B4", // 25 - 형광 분홍색
  "#00FFFF", // 26 - 형광 청록색
  "#FF7F50", // 27 - 형광 주황색
  "#BA55D3", // 28 - 형광 보라색
  "#ADFF2F", // 29 - 형광 연두색
  "#FF1493"  // 30 - 형광 마젠타
];
