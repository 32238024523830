import { Divider, List, ListItemText, Tooltip } from "@mui/material";
import * as S from "./styled"
import { Fragment, useState } from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AssetProps, makeEditAssetAtom } from "../../../Atoms/AssetAtom";
import { useSetAtom } from "jotai";
import { userAtom } from "../../../Atoms/UserAtom";
import { useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { Dropdown, Menu, MenuButton, MenuItem, } from "@mui/joy";
import { apiAddress } from "../../../Atoms/GlobalVariable";
import { useUser } from "../../../Providers/User";
import YesOrNoDialog from "../../Dialog/YesOrNo";

interface AssetsContentsProps {
  assets: AssetProps[];
}

const AssetsContents = ({ assets }: AssetsContentsProps) => {
  const setUser = useSetAtom(userAtom);
  const setMakeEditAsset = useSetAtom(makeEditAssetAtom);
  const navigate = useNavigate();
  const refetchUserData = useUser().refetchUserData;
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(-1);

  const handleClick = (asset: AssetProps) => {
    setUser((prev) => ({ ...prev, currentAsset: asset }));
    navigate(`/studio`);
  }

  const handleEditAsset = (asset: AssetProps) => {
    setMakeEditAsset({
      id: asset.id,
      name: asset.assetName,
      description: asset.description,
      isPrivate: asset.isPrivate,
      sources: asset.sources,
    });
    navigate('/makeAsset');
  }

  const handleDeleteAsset = async () => {
    try {
      if (deleteIndex === -1) return;
      const formData = new FormData();
      formData.append('assetId', assets[deleteIndex].id);
      const response = await fetch(`${apiAddress}/asset/delete`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to delete asset');
      }
      refetchUserData();
    } catch (error) {
      console.error(error);
    }
    setOpenDialog(false);
    setDeleteIndex(-1);
  }

  return (
    <S.Container>
      {assets.length === 0 ?
        <S.Content style={{ justifyContent: "center" }}>
          <S.BlankContainer onClick={() => navigate('/makeAsset')}>
            <AddCircleOutlineIcon sx={{
              fontSize: '100px',
              color: '#d9d9d9',
            }} />
            <div>여기를 눌러 ASSET을 추가해보세요!</div>
          </S.BlankContainer>
        </S.Content>
        :
        <S.Content>
          <List>
            {assets.map((asset, index) => (
              <Fragment key={asset.id}>
                <S.ListItemStyle>
                  <ListItemText
                    primary={asset.assetName}
                    secondary={asset.description}
                  />
                  <Dropdown>
                    <MenuButton sx={S.ButtonStyle}>
                      <SettingsIcon style={{ cursor: "pointer" }} />
                    </MenuButton>
                    <Menu size="sm">
                      <MenuItem onClick={() => handleEditAsset(asset)} >수정</MenuItem>
                      <MenuItem onClick={() => {
                        setOpenDialog(true);
                        setDeleteIndex(index);
                      }}>삭제</MenuItem>
                    </Menu>
                  </Dropdown>
                  <Tooltip title="스튜디오로 이동">
                    <ArrowForwardIcon onClick={() => handleClick(asset)} style={{ cursor: "pointer" }} />
                  </Tooltip>
                </S.ListItemStyle>
                {index < assets.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </S.Content>
      }
      <YesOrNoDialog open={openDialog} setOpen={setOpenDialog} title="ASSET 삭제" textContents={["정말 삭제하시겠습니까?", "삭제된 데이터는 복구할 수 없습니다."]} yesText="삭제" onYes={handleDeleteAsset} noText="취소" onNo={() => { setOpenDialog(false); }} />
    </S.Container>
  )
}

export default AssetsContents;
