import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { CommonChartProps } from "../../..";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { colorMap } from "../../../../../Data/colorMap";
import { selectedTechInfoAtom } from "../../../../../Atoms/DashboardAtom";
import { studioHeaderAtom } from "../../../../../Atoms/StudioAtom";
import { useData } from "../../../../../Providers/Data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface KeywordFrequency {
  [key: string]: { [key: string]: number };
}

const PatentKeywordChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const [chartData, setChartData] = useState<any>(null);
  const selectedTechInfo = useAtomValue(selectedTechInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const { techs } = useData();

  useEffect(() => {
    const patentTitleData = techs.map((item) => item.patentTitle);
    const applicationDateData = techs.map((item) => item.applicationDate);
    const summaryKeywordsData = techs.map((item) => item.summaryKeywords);
    const trendKeywordsData = techs.map((item) => item.trendKeywords);

    const items = patentTitleData.map((patentTitle, index) => ({
      index: index,
      patentTitle: patentTitle,
      applicationDate: applicationDateData[index],
      summaryKeywords: summaryKeywordsData[index],
      trendKeywords: trendKeywordsData[index],
    }));

    let uniqueItems = items.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.patentTitle === item.patentTitle && t.applicationDate === item.applicationDate
      ))
    );

    const techInfo = selectedTechInfo.find((info) => info.pageId === studioHeader.currentPageId);
    if (techInfo) {
      uniqueItems = uniqueItems.filter((item) => item.trendKeywords?.find((keyword) => keyword.id.includes(techInfo.selectedKeyword))?.value === 1);
    }


    const keywordFrequencyByYear = uniqueItems.reduce((acc: KeywordFrequency, item) => {
      const itemApplicationNumberDate = item.applicationDate || '';
      const year = itemApplicationNumberDate.split(".")[0];

      if (!item.summaryKeywords) return acc;

      const keywords = item.summaryKeywords.split('|').map((k: string) => k.split(':'));

      keywords.forEach((keywordData: string[]) => {
        if (keywordData.length < 2) return; // Skip if the split didn't result in a pair
        const [keyword, count] = keywordData;

        if (!acc[year]) acc[year] = {};
        if (!acc[year][keyword]) acc[year][keyword] = 0;
        acc[year][keyword] += parseInt(count);
      });

      return acc;
    }, {});

    const labels = Object.keys(keywordFrequencyByYear).sort();
    const datasets = Object.entries(keywordFrequencyByYear).reduce((acc: { [key: string]: { label: string, data: number[], borderColor?: string, backgroundColor?: string } }, [year, keywords]) => {
      Object.entries(keywords).forEach(([keyword, count]) => {
        if (!acc[keyword]) acc[keyword] = { label: keyword, data: Array(labels.length).fill(0) };
        acc[keyword].data[labels.indexOf(year)] = count;
      });
      return acc;
    }, {});

    let chartData = {
      labels,
      datasets: Object.values(datasets),
    };

    // 데이터를 마지막 인덱스 기준으로 정렬 후 상위 10개 추출
    chartData.datasets.sort((a, b) => b.data[b.data.length - 1] - a.data[a.data.length - 1]);
    chartData.datasets = chartData.datasets.slice(0, 10);

    // 색상을 인덱스에 따라 설정
    chartData.datasets = chartData.datasets.map((dataset, index) => ({
      ...dataset,
      borderColor: colorMap[index % colorMap.length],
      backgroundColor: `${colorMap[index % colorMap.length]}80`,
    }));
    setChartData(chartData);
  }, [techs, selectedTechInfo, studioHeader]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right' as const,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      }
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            특허 키워드 빈도 차트
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box sx={{ display: "flex", maxHeight: `${customLayout.h * rowHeight - 90}px`, minHeight: `${customLayout.h * rowHeight - 90}px`, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          {chartData ? <Line options={options} data={chartData} /> : <Typography>Loading chart...</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}

export default PatentKeywordChart;
