import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import LoadingAnimation from "../../../Assets/Lotties/LoadingAnimation.json";
import LottieAnimation from '../../LottieAnimation';

interface LoadingDialogProps {
  isLoading: boolean;
  text: string;
}

const LoadingDialog = ({ isLoading, text }: LoadingDialogProps) => {
  return (
    <Dialog open={isLoading} sx={{ padding: "20px" }}>
      <DialogTitle>{text}</DialogTitle>
      <DialogContent>
        <LottieAnimation animationData={LoadingAnimation} width={200} height={200} />
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog;
