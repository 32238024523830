import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, ChartDataLabels);

interface BubbleChartProps {
  data: any;
  options?: any;
  onElementClick: (element: any) => void;
}

const BubbleChart = ({ data, options, onElementClick }: BubbleChartProps) => {
  const handleClick = (event: any, elements: any) => {
    if (elements.length > 0) {
      const datasetIndex = elements[0].datasetIndex;
      const index = elements[0].index;
      const dataset = data.datasets[datasetIndex];
      const dataPoint = dataset.data[index];
      const label = dataset.label;
      onElementClick({ label, ...dataPoint, category: dataset.category });
    }
  };

  return (
    <Bubble
      data={data}
      options={{
        ...options,
        onClick: handleClick,
      }}
    />
  );
};

export default BubbleChart;
