import { Button, ButtonProps, styled } from "@mui/material";

interface AnimatedButtonProps extends ButtonProps {
  isactive: boolean;
}

const AnimatedButton = styled(({ isactive, ...props }: AnimatedButtonProps) => (
  <Button {...props} />
))(({ isactive }) => ({
  height: "35px",
  borderRadius: "25px",
  padding: "6px 16px",
  color: isactive ? "white" : "#8f8f8f",
  background: isactive ? "#134cf8" : "none",
  "&:hover": {
    background: isactive ? "#134cf8" : "none",
  },
}));

export default AnimatedButton;
