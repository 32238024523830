import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { CommonChartProps } from '../..';
import { useAtomValue } from 'jotai';
import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { colorMap } from '../../../../Data/colorMap';
import { selectedPatentInfoAtom, selectedTechInfoAtom } from '../../../../Atoms/DashboardAtom';
import { studioHeaderAtom } from '../../../../Atoms/StudioAtom';
import { commonLayoutVariable } from '../../../../Atoms/GlobalVariable';
import { useData } from '../../../../Providers/Data';

interface Patent extends d3.SimulationNodeDatum {
  id: string;
  index: number;
  title: string;
  group: string;
  totalCitations: number;
  publicNumber: string;
  citingInformationPublicationNumbers: string[];
  trendKeywords: { id: string, data: number | null }[];
}

interface Citation {
  source: Patent;
  target: Patent;
  value: number;
}

const PatentCitationGraph: React.FC<CommonChartProps> = ({ customLayout, contentId, handleDelete, rowHeight }) => {
  const selectedTechInfo = useAtomValue(selectedTechInfoAtom);
  const selectedPatentInfo = useAtomValue(selectedPatentInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const { techs } = useData();

  const svgRef = useRef<SVGSVGElement | null>(null);
  const nodeRef = useRef<d3.Selection<d3.BaseType | SVGCircleElement, Patent, SVGGElement, unknown> | null>(null);
  const linkRef = useRef<d3.Selection<d3.BaseType | SVGLineElement, Citation, SVGGElement, unknown> | null>(null);
  const textRef = useRef<d3.Selection<d3.BaseType | SVGTextElement, Patent, SVGGElement, unknown> | null>(null);
  const parentToSubNodesMap = useRef<{ [key: string]: string[] }>({});

  useEffect(() => {
    let width = customLayout.w * (window.innerWidth / commonLayoutVariable);
    let height = customLayout.h * rowHeight - 90;
    if (width < height) height = width;
    else width = height;

    const patentTitleData = techs.map((item) => item.patentTitle);
    const totalCitationCountData = techs.map((item) => item.totalCitationCount);
    const citingInformationCountryData = techs.map((item) => item.citingInformationCountry);
    const citingInformationPublicationDateData = techs.map((item) => item.citingInformationPublicationDate);
    const citingInformationInventionNameData = techs.map((item) => item.citingInformationInventionName);
    const publicationNumberDateData = techs.map((item) => item.publicationNumberDate);
    const citingInformationPublicationNumberData = techs.map((item) => item.citingInformationPublicationNumber);
    const trendKeywordsData = techs.map((item) => item.trendKeywords);

    if (!patentTitleData || !totalCitationCountData || !citingInformationCountryData || !citingInformationPublicationDateData || !citingInformationInventionNameData || !publicationNumberDateData || !citingInformationPublicationNumberData || !trendKeywordsData) {
      console.error('Required data is missing');
      return;
    }

    const allPatents: Patent[] = patentTitleData.map((title, index) => ({
      id: `patent-${index}`,
      index: index,
      title: title ?? '',
      group: (index % 3).toString(), // 그룹을 임의로 지정
      totalCitations: totalCitationCountData[index] ?? 0, // 총 인용 횟수를 추가
      x: width / 4 + Math.random() * 50 - 25,  // 초기 위치를 중심 주변에 배치
      y: height / 4 + Math.random() * 50 - 25, // 초기 위치를 중심 주변에 배치
      vx: 0,
      vy: 0,
      fx: null,
      fy: null,
      publicNumber: publicationNumberDateData[index]?.split(' ')[0] || '',
      citingInformationPublicationNumbers: citingInformationPublicationNumberData[index] ? [citingInformationPublicationNumberData[index]?.split(' ')[0] || ''] : [],
      trendKeywords: trendKeywordsData[index]?.map((data) => ({ id: data.id, data: data.value })) ?? [],
    }));


    let patents: Patent[] = allPatents;
    const techInfo = selectedTechInfo.find((info) => info.pageId === studioHeader.currentPageId);
    if (techInfo) {
      patents = allPatents.filter((item) => item.trendKeywords.find((keyword) => keyword.id.includes(techInfo.selectedKeyword))?.data === 1);
    }

    // 하위 노드를 추가 및 메인 노드 간의 링크 생성
    const subNodes: Patent[] = [];
    const subCitations: Citation[] = [];
    const newCitations: Citation[] = [];

    patents.forEach((patent, index) => {
      parentToSubNodesMap.current[patent.id] = [];
      for (let i = 0; i < patent.totalCitations; i++) {
        const subNodeId = `sub-patent-${index}-${i}`;
        const subNodeTitle = `${citingInformationInventionNameData[patent.index]} - ${citingInformationCountryData[patent.index]} - ${citingInformationPublicationDateData[patent.index]}`;

        patents.forEach(targetPatent => {
          if (patent.citingInformationPublicationNumbers.includes(targetPatent.publicNumber)) {
            newCitations.push({
              source: patent,
              target: targetPatent,
              value: 1
            });
          }
        });

        parentToSubNodesMap.current[patent.id].push(subNodeId);
        subNodes.push({
          id: subNodeId,
          index: index,
          title: subNodeTitle,
          group: patent.group,
          totalCitations: 0,
          x: patent.x! + Math.random() * 20 - 10, // 하위 노드를 중심 주변에 배치
          y: patent.y! + Math.random() * 20 - 10,
          vx: 0,
          vy: 0,
          fx: null,
          fy: null,
          publicNumber: '',
          citingInformationPublicationNumbers: [],
          trendKeywords: [],
        });
        subCitations.push({
          source: patent,
          target: subNodes[subNodes.length - 1],
          value: 1
        });
      }
    });

    const allNodes = patents.concat(subNodes);
    const allCitations = subCitations.concat(newCitations);

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-width / 2, -height / 2, width, height])
      .attr("style", "max-width: 100%; height: auto;");

    svg.selectAll("*").remove();

    const linkDistance = Math.min(width, height) * 0.07;
    const chargeStrength = -Math.min(width, height) * 0.07;

    const simulation = d3.forceSimulation(allNodes)
      .force("link", d3.forceLink(allCitations).id((d: any) => d.id).distance(linkDistance)) // 간격을 좁게 설정
      .force("charge", d3.forceManyBody().strength(chargeStrength)) // 더 약한 반발력 설정
      .force("center", d3.forceCenter(0, 0))
      .force("x", d3.forceX())
      .force("y", d3.forceY());

    const link = svg.append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(allCitations)
      .join("line")
      .attr("stroke-width", d => Math.sqrt(d.value));

    const node = svg.append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(allNodes)
      .join("circle")
      .attr("r", d => (d.id.startsWith("sub-") ? 5 : 7)) // 하위 노드는 작은 원으로 설정
      .attr("fill", colorMap[2]);

    node.append("title")
      .text(d => d.title);

    // 노드에 텍스트 추가
    const text = svg.append("g")
      .selectAll("text")
      .data(allNodes)
      .join("text")
      .attr("x", d => d.x!)
      .attr("y", d => d.y!)
      .attr("dy", ".35em")
      .attr("text-anchor", "middle")
      .attr("font-size", d => d.id.startsWith("sub-") ? "10px" : "12px")
      .attr("font-weight", "regular")
      .attr('font-family', 'nanum-square')
      .attr("fill", "#999")
      .text(d => d.id.startsWith("sub-") ? d.title.slice(0, 2) : d.title.slice(0, 5));

    node.call(d3.drag<any, Patent>()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended));

    simulation.on("tick", () => {
      link
        .attr("x1", d => (d.source as unknown as Patent).x!)
        .attr("y1", d => (d.source as unknown as Patent).y!)
        .attr("x2", d => (d.target as unknown as Patent).x!)
        .attr("y2", d => (d.target as unknown as Patent).y!);

      node
        .attr("cx", d => d.x!)
        .attr("cy", d => d.y!);

      text
        .attr("x", d => d.x!)
        .attr("y", d => d.y! + 10);
    });

    function dragstarted(event: any) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event: any) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event: any) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    nodeRef.current = node;
    linkRef.current = link;
    textRef.current = text;
  }, [techs, selectedTechInfo, studioHeader, customLayout, rowHeight]);

  // selectedPatentInfo가 변경될 때만 색상을 업데이트하는 useEffect 추가
  useEffect(() => {
    if (nodeRef.current && textRef.current && linkRef.current) {
      let subNodeIds: string[] = [];
      nodeRef.current
        .attr("fill", (d: Patent) => {
          const isSelected = selectedPatentInfo.some(selectedItem =>
            selectedItem.pageId === studioHeader.currentPageId &&
            selectedItem.item.patentTitle === d.title
          );
          if (isSelected) {
            const parentId = d.id.startsWith('sub-') ? d.id.split('-').slice(0, 3).join('-') : d.id;
            const mainToMain = linkRef.current?.data().filter(link => (link.source.id === parentId && !link.target.id.startsWith('sub-')) || (link.target.id === parentId && !link.source.id.startsWith('sub-')));
            if (!mainToMain) return colorMap[2];
            const mainToMainIds = mainToMain.map(link => link.source.id === parentId ? link.target.id : link.source.id);
            subNodeIds = parentToSubNodesMap.current[parentId] || [];
            subNodeIds.push(parentId);
            subNodeIds.push(...mainToMainIds);
            subNodeIds.push(...mainToMainIds.map(id => parentToSubNodesMap.current[id]).flat());
            return colorMap[0];
          }
          return colorMap[2];
        })
        .each(function (d: Patent) {
          const isSelected = subNodeIds.includes(d.id);
          d3.select(this)
            .attr("fill", isSelected ? colorMap[0] : colorMap[2])
            .attr("r", isSelected ? (d.id.startsWith("sub-") ? 7 : 10) : (d.id.startsWith("sub-") ? 5 : 7));
        });

      textRef.current
        .attr("font-size", (d: Patent) => {
          const isSelected = subNodeIds.includes(d.id);
          return isSelected ? (d.id.startsWith("sub-") ? "12px" : "14px") : (d.id.startsWith("sub-") ? "10px" : "12px");
        })
        .attr("font-weight", (d: Patent) => {
          const isSelected = subNodeIds.includes(d.id);
          return isSelected ? "bold" : "regular";
        })
        .attr("fill", (d: Patent) => {
          const isSelected = subNodeIds.includes(d.id);
          return isSelected ? "#000" : "#999";
        });
    }
  }, [selectedPatentInfo, studioHeader.currentPageId]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }} >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            특허 인용 관계 그래프
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box sx={{ display: "flex", maxHeight: `${customLayout.h * rowHeight - 90}px`, minHeight: `${customLayout.h * rowHeight - 90}px`, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          <svg id="graph" ref={svgRef} style={{ width: "100%", height: "100%" }} onMouseDown={(e) => e.stopPropagation()}></svg>
        </Box>
      </CardContent>
    </Card>
  )
};

export default PatentCitationGraph;
