import { useAtomValue } from "jotai";
import { CustomLayout, studioHeaderAtom } from "../../../Atoms/StudioAtom";
import { MouseEvent, useRef } from "react";
import { heightAtom, userAtom } from "../../../Atoms/UserAtom";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import Charts from "../../Charts";
import { useUser } from "../../../Providers/User";
import { apiAddress, commonLayoutVariable } from "../../../Atoms/GlobalVariable";

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Contents 컴포넌트
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
const ResponsiveGridLayout = WidthProvider(Responsive);

interface LayoutComponentProps {
  layouts: { md: CustomLayout[] };
  setLayouts: React.Dispatch<React.SetStateAction<{ md: CustomLayout[]; }>>;
}

export const LayoutComponent = ({ layouts, setLayouts }: LayoutComponentProps) => {
  const user = useAtomValue(userAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const prevLayoutsRef = useRef<{ md: CustomLayout[] }>({ md: [] });
  const refetchUserData = useUser().refetchUserData;
  const height = useAtomValue(heightAtom);

  const handleDeleteContent = async (event: MouseEvent, contentId: string) => {
    event.stopPropagation();
    try {
      const formData = new FormData();
      formData.append('contentId', contentId);
      const response = await fetch(`${apiAddress}/asset/page/content/delete`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error('Failed to delete content');
      }
      refetchUserData();
      console.log("user data refetched", user);
    } catch (error) {
      console.error(error);
    }
  }

  const handleLayoutChange = (currentLayout: Layout[]) => {
    const updatedLayouts = {
      md: currentLayout.map(layout => ({
        ...layout,
        contentId: layouts.md.find(l => l.i === layout.i)?.contentId || null,
        contentType: layouts.md.find(l => l.i === layout.i)?.contentType || null,
        contentName: layouts.md.find(l => l.i === layout.i)?.contentName || null,
        dataType: layouts.md.find(l => l.i === layout.i)?.dataType || null
      })) as CustomLayout[]
    };
    setLayouts(updatedLayouts);
    const changedLayouts = getChangedLayouts(prevLayoutsRef.current.md, updatedLayouts.md);
    if (changedLayouts.length > 0) {
      updateLayouts(changedLayouts);
      refetchUserData();
    }
    prevLayoutsRef.current = updatedLayouts;
  };

  const getChangedLayouts = (prevLayouts: CustomLayout[], currentLayouts: CustomLayout[]): CustomLayout[] => {
    const changedLayouts: CustomLayout[] = [];
    currentLayouts.forEach((layout) => {
      const prevLayout = prevLayouts.find((l) => l.i === layout.i);
      if (!prevLayout || JSON.stringify(prevLayout) !== JSON.stringify(layout)) {
        changedLayouts.push(layout);
      }
    });
    return changedLayouts;
  };

  const updateLayouts = async (changedLayouts: CustomLayout[]) => {
    try {
      const formData = new FormData();
      formData.append('contents', JSON.stringify(changedLayouts));
      console.log("changedLayouts", changedLayouts);
      const response = await fetch(`${apiAddress}/asset/page/content/update`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to save layout');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: commonLayoutVariable, md: commonLayoutVariable, sm: commonLayoutVariable, xs: commonLayoutVariable, xxs: commonLayoutVariable }}
      rowHeight={(height - 112) / commonLayoutVariable - 12}
      maxRows={commonLayoutVariable}
      onLayoutChange={handleLayoutChange}
      isResizable={true}
      isDraggable={true}
      style={{ width: '100%', minHeight: `${height - 112}px`, backgroundColor: "#f2f2f2" }}
    >
      {layouts.md.map((item) => (
        <div key={item.i} className="dnd-movable-item" data-grid={item} style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%", height: `${item.h * ((height - 112) / commonLayoutVariable - 20) - 40}` }} >
          <Charts chartData={item} contentId={item.i} handleDelete={handleDeleteContent} />
        </div>
      ))}
    </ResponsiveGridLayout>
  );
}
