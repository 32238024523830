import Assets from './Pages/Assets';
import MakeAsset from './Pages/MakeAsset';
import Dashboard from './Pages/Dashboard';
import Studio from './Pages/Studio';
import Root from './Root';
import { Route, Routes, createBrowserRouter } from 'react-router-dom';

const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Root />}>
        <Route path='' element={<Dashboard />} />
        <Route path='makeAsset' element={<MakeAsset />} />
        <Route path='studio' element={<Studio />} />
        <Route path='assets' element={<Assets />} />
      </Route>
    </Routes>
  );
};

const router = createBrowserRouter([
  {
    path: '*',
    element: <AppRouter />,
  },
]);

export default router;
