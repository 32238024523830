import styled from 'styled-components';

export const AddPageButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AddPageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  color: #777777;
  border: 1px dashed #777777;
  border-radius: 5px;
  cursor: pointer;
`;

export const DropdownMenu = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 60px; /* 버튼 높이 + 약간의 여유 공간 */
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
