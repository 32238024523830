import { Box, IconButton, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from "react";
import { apiAddress } from "../../Atoms/GlobalVariable";
import { useAtomValue } from "jotai";
import { mapperOption, userAtom } from "../../Atoms/UserAtom";

export interface SearchResult {
  id: string;
  name: string;
  keywords: string;
  sourceBy: string;
}

interface ListSelectorProps {
  openDialog: mapperOption[] | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<mapperOption[] | null>>;
}

interface MapperOptions {
  item: string[];
  trend: string[];
  ksic: string[];
}

const initMapperOptions: MapperOptions = {
  item: [],
  trend: [],
  ksic: [],
};

const translateToKorean = (item: string) => {
  switch (item) {
    case 'item':
      return '아이템';
    case 'company':
      return '기업';
    case 'tech':
      return '기술';
    case 'trend':
      return '트렌드';
    case 'review':
      return '리뷰';
    case 'smallBusiness':
      return '소부장';
    case 'thesis':
      return '논문';
    case 'patent':
      return '특허';
    case 'thesisKeyword':
      return '논문키워드';
    default:
      return item;
  }
}

export const ListSelector = ({ openDialog, setOpenDialog }: ListSelectorProps) => {
  const user = useAtomValue(userAtom);
  const [mapperOptions, setMapperOptions] = useState<MapperOptions>(initMapperOptions);

  useEffect(() => {
    if (user.currentAsset) {
      const items = user.currentAsset.sources.map((source) => source.assetType);
      const trends = user.currentAsset.sources.map((source) =>
        source.uniqueKeywords ? source.uniqueKeywords.split(' ; ') : []
      );
      const ksic = user.currentAsset.sources.map((source) =>
        source.uniqueKsic ? source.uniqueKsic.split(' ; ') : []
      );

      // Set Mapper Options
      const itemsSet = new Set(items);
      const trendsSet = new Set(trends.flat());
      const ksicSet = new Set(ksic.flat());

      setMapperOptions({
        item: Array.from(itemsSet).map((it) => translateToKorean(it)),
        trend: Array.from(trendsSet),
        ksic: Array.from(ksicSet),
      });
      // console.log('items', items, 'trends', Array.from(trendsSet), 'ksic', Array.from(ksicSet));
    }
  }, [user])

  return (
    <Box
      key='list-selector'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      {[
        { title: '항목', items: mapperOptions.item },
        { title: '트렌드', items: mapperOptions.trend },
        { title: 'KSIC분류', items: mapperOptions.ksic },
      ].map((category, index) => (
        <Box
          key={`${category.title}`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: "30%",
            border: '2px solid #f0f0f0',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <Typography
            variant='h6'
            sx={{ marginBottom: '10px', width: '100%' }}
          >
            {category.title}
          </Typography>
          <Box
            key={`${category.title}-${index}`}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              borderRadius: '10px',
              height: '250px', // 고정된 높이로 설정
              overflowY: 'auto' // 스크롤 가능하게 설정
            }}
          >
            <List>
              {category.items.map((item, idx) => (
                <ListItem
                  key={`${item}-${idx}`}
                  sx={{
                    padding: '5px 10px',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: openDialog?.map((item) => item.value).includes(item) ? '#5340FF' : '#f0f0f0',
                    },
                    backgroundColor: openDialog?.map((item) => item.value).includes(item) ? '#5340FF' : 'inherit',
                    color: openDialog?.map((item) => item.value).includes(item) ? 'white' : 'inherit',
                  }}
                  onClick={() => {
                    setOpenDialog((prev) => {
                      const newOption = { type: category.title, value: item };

                      if (prev === null) {
                        return [newOption];
                      }

                      if (prev.some((opt) => opt.value === item)) {
                        return prev.filter((opt) => opt.value !== item); // 기존 배열에서 클릭한 항목 제거
                      }

                      return [...prev, newOption]; // 기존 배열에 새로운 항목 추가
                    });
                  }}
                >
                  {item}
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

interface SelectedOptionsProps {
  openDialog: mapperOption[] | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<mapperOption[] | null>>;
}

export const SelectedOptions = ({ openDialog, setOpenDialog }: SelectedOptionsProps) => {
  return (
    openDialog &&
    <Box
      sx={{
        width: '100%', // 최대 너비 설정
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontSize: '12px',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      {openDialog.map((item, i) => (
        <Box
          key={`${item}-${i}`}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            whiteSpace: 'nowrap',
            gap: '5px',
            alignItems: 'center',
            padding: '5px',
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
          }}
        >
          {item.value}
          <CloseIcon
            sx={{ width: "15px", height: "15px", cursor: "pointer" }}
            onClick={() => {
              setOpenDialog((prev) => {
                if (prev === null) {
                  return null;
                }
                return prev.filter((prevItem) => prevItem.value !== item.value); // 기존 배열에서 클릭한 항목 제거
              });
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

interface MapperTextFieldProps {
  setSearchResults: React.Dispatch<React.SetStateAction<{
    id: string;
    name: string;
    keywords: string;
    sourceBy: string;
  }[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MapperTextField = ({ setSearchResults, setLoading }: MapperTextFieldProps) => {
  const [dialogSearch, setDialogSearch] = useState('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const clearSearch = () => {
    setDialogSearch('');
    setSearchResults([]);
  };

  const handleSearch = (search: string) => {
    setLoading(true);
    setDialogSearch(search);
    if (timer) {
      clearTimeout(timer);
    }
    if (search === '') {
      setSearchResults([]);
      setLoading(false);
      return;
    }
    setTimer(setTimeout(async () => {
      try {
        const formData = new FormData();
        formData.append('search', search);
        const response = await fetch(`${apiAddress}/file-data/search`, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        setSearchResults(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, 1500));
  };

  return (
    <TextField
      label='원하시는 타겟군을 입력하시면 산업을 매핑해드릴게요!'
      variant='standard'
      sx={{ width: '100%', height: '50px' }}
      value={dialogSearch}
      onChange={(e) => handleSearch(e.target.value)}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={clearSearch}
            sx={{ visibility: dialogSearch ? 'visible' : 'hidden' }}
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  )
}

interface SearchResultsProps {
  searchResults: SearchResult[];
  openDialog: mapperOption[] | null;
  setOpenDialog: React.Dispatch<React.SetStateAction<mapperOption[] | null>>;
}

export const SearchResults = ({ searchResults, openDialog, setOpenDialog }: SearchResultsProps) => {
  const headers = [
    { id: 'name', label: '명칭', maxWidth: '150px' },
    { id: 'keywords', label: '키워드', maxWidth: '250px' },
    { id: 'sourceBy', label: '출처', maxWidth: '150px' },
  ];

  const createSearchResultData = (searchResults: SearchResult[], headers: { id: string, label: string, maxWidth: string }[]) => {
    // 동적으로 rows를 생성
    const rows = searchResults.map((result) => {
      let row: { [key: string]: string } = {};
      headers.forEach(header => {
        // header id를 사용하여 result 객체의 속성을 가져와서 row 객체에 추가
        row[header.id] = result[header.id as keyof SearchResult] || '';
      });
      return row;
    });

    return { headers, rows };
  };

  const { headers: searchResultHeaders, rows: searchResultRows } = createSearchResultData(searchResults, headers);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%',
        maxHeight: '400px',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {searchResultHeaders.map((header) => (
              <TableCell key={header.id} sx={{ maxWidth: header.maxWidth }}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResultRows.map((row, idx) => (
            <TableRow
              key={idx}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: openDialog?.map((item) => item.value).includes(row.name) ? '#5340FF' : '#f0f0f0',
                },
                backgroundColor: openDialog?.map((item) => item.value).includes(row.name) ? '#5340FF' : 'inherit',
                color: openDialog?.map((item) => item.value).includes(row.name) ? 'white' : 'inherit',
              }}
              onClick={() => {
                setOpenDialog((prev) => {
                  const newItem: mapperOption = {
                    type: 'someType',  // 'someType'은 실제 카테고리 타입으로 변경해야 합니다. 예: 'item', 'trend', 'ksic'
                    value: row.name,
                  };

                  if (prev === null) {
                    return [newItem];
                  }

                  const exists = prev.some((prevItem) => prevItem.value === row.name);

                  if (exists) {
                    return prev.filter((prevItem) => prevItem.value !== row.name);
                  }

                  return [...prev, newItem];
                });
              }}
            >
              {searchResultHeaders.map((header) => (
                <TableCell
                  key={header.id}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: header.maxWidth,
                  }}
                >
                  {row[header.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
