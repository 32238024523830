import * as S from "./styled";
import * as C from "./containers";
import { useAtom, useSetAtom } from "jotai";
import { CustomLayout, studioContentAtom, studioHeaderAtom } from "../../../Atoms/StudioAtom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect, useMemo, useRef, useState } from "react";
import { userAtom } from "../../../Atoms/UserAtom";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { AssetProps } from "../../../Atoms/AssetAtom";

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Contents 컴포넌트
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
interface StudioContentsProps {
  currentAsset: AssetProps;
}

const StudioContents = ({ currentAsset }: StudioContentsProps) => {
  const [studioHeader, setStudioHeader] = useAtom(studioHeaderAtom);
  const setUser = useSetAtom(userAtom);
  const setStudioContent = useSetAtom(studioContentAtom);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [layouts, setLayouts] = useState<{ md: CustomLayout[] }>({ md: [] });
  const contentRef = useRef<HTMLDivElement>(null);

  const handleOpenLayoutSidebar = () => {
    setStudioHeader((prev) => {
      return { ...prev, layoutSidebarOpen: true, pageSidebarOpen: false };
    });
  };

  const layout = useMemo(() => {
    if (!currentAsset || currentAsset.pages.length === 0) return [];

    const currentPage = currentAsset.pages[currentPageIndex];
    if (!currentPage || !currentPage.contents) return [];

    const contents = currentPage.contents;
    if (contents.length === 0) return [];
    console.log('currentAsset', currentAsset);
    return contents.map((content) => {
      return {
        contentId: content.contentId,
        contentName: content.contentName,
        contentType: content.contentType,
        dataType: content.dataType,
        i: content.id,
        x: content.x,
        y: content.y,
        w: content.w,
        h: content.h,
        minW: content.minW,
        minH: content.minH,
        maxW: content.maxW,
        maxH: content.maxH,
      } as CustomLayout;
    });
  }, [currentAsset, currentPageIndex]);

  useEffect(() => {
    setLayouts({ md: layout });
  }, [layout]);

  useEffect(() => {
    if (currentAsset && currentAsset.pages.length > 0) {
      setCurrentPageIndex(currentAsset.pages.findIndex((page) => page.id === studioHeader.currentPageId));
    }
  }, [currentAsset, studioHeader.currentPageId]);

  useEffect(() => {
    console.log('contentRef', contentRef);
    setStudioContent({ contentRef: contentRef });
  }, [contentRef, setStudioContent]);

  useEffect(() => {
    // Assign the contentRef to the current page
    setUser((prevUser) => {
      if (!prevUser.currentAsset) return prevUser;
      const updatedPages = prevUser.currentAsset.pages.map((page) => {
        if (page.id === studioHeader.currentPageId) {
          return { ...page, contentRef: contentRef };
        }
        return page;
      });
      const updatedCurrentAsset = { ...prevUser.currentAsset, pages: updatedPages };
      return { ...prevUser, currentAsset: updatedCurrentAsset };
    });
  }, [setUser, studioHeader.currentPageId]);

  return (
    <S.Container ref={contentRef}>
      {currentAsset && currentAsset.pages[currentPageIndex] && currentAsset.pages[currentPageIndex].contents && currentAsset.pages[currentPageIndex].contents.length > 0 ? (
        <C.LayoutComponent layouts={layouts} setLayouts={setLayouts} />
      ) : (
        <S.DashboardBlankContainer onClick={handleOpenLayoutSidebar}>
          <AddCircleOutlineIcon sx={{
            fontSize: '100px',
            color: '#d9d9d9',
          }} />
          <div>여기를 눌러 차트를 추가해보세요!</div>
        </S.DashboardBlankContainer>
      )}
    </S.Container>
  );
}

export default StudioContents;
// ----------------------------------------------------------------------------------------
