import { useEffect, useState } from 'react';

function easeOutExpo(t: number): number {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
}

const useCount = (start: number, end: number, duration = 2000) => {
  const [count, setCount] = useState(start);
  const frameRate = 1000 / 60; // 60 FPS
  const totalFrame = Math.round(duration / frameRate);

  useEffect(() => {
    let frame = 0;
    const step = end > start ? 1 : -1;
    const counter = setInterval(() => {
      frame++;
      const progress = easeOutExpo(frame / totalFrame);
      const currentCount = start + step * (end > start ? end - start : start - end) * progress;
      setCount(Math.round(currentCount));

      if (frame === totalFrame) {
        clearInterval(counter);
      }
    }, frameRate);

    return () => clearInterval(counter);
  }, [end, start, totalFrame, frameRate]);

  return count;
};

export default useCount;
