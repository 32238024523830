import { PageStateProps, PatentInfoItem, ResponseFileData } from "../../../../Atoms/DashboardAtom";
import { mapperOption } from "../../../../Atoms/UserAtom";

interface ProcessTechSourceDataProps {
  assetId: string;
  setTechs: (items: PatentInfoItem[]) => void;
  fetchAssetData: (assetId: string, type: string) => Promise<any>;
  fetchAssetDataByMapper: (assetId: string, mapper: mapperOption[]) => Promise<any>;
  mapper: mapperOption[] | null;
  selectedTechInfo: PageStateProps[];
  currentPageId: string;
}

const processTechData = async ({ assetId, setTechs, fetchAssetData, fetchAssetDataByMapper, mapper, selectedTechInfo, currentPageId }: ProcessTechSourceDataProps) => {
  // Fetch item data
  let itemResponse;
  if (mapper) {
    itemResponse = await fetchAssetDataByMapper(assetId, mapper);
  } else {
    itemResponse = await fetchAssetData(assetId, "tech");
  }
  if (itemResponse && itemResponse.data) {
    const items: PatentInfoItem[] = itemResponse.data.map((patent: ResponseFileData, i: number) => {
      return {
        index: i,
        id: patent.id,
        patentTitle: patent.name || null,
        categoryIPC: patent.dataDetails.categoryIPC || null,
        categoryCPC: patent.dataDetails.categoryCPC || null,
        applicationNumber: patent.dataDetails.applicationNumber || null,
        applicationDate: patent.dataDetails.applicationDate || null,
        applicant: patent.dataDetails.applicant || null,
        applicantNationality: patent.dataDetails.applicantNationality || null,
        inventor: patent.dataDetails.inventor || null,
        inventorNationality: patent.dataDetails.inventorNationality || null,
        agent: patent.dataDetails.agent || null,
        agentNationality: patent.dataDetails.agentNationality || null,
        finalHolder: patent.dataDetails.finalHolder || null,
        registrationNumberDate: patent.dataDetails.registrationNumberDate || null,
        publicationNumberDate: patent.dataDetails.publicationNumberDate || null,
        announcementNumberDate: patent.dataDetails.announcementNumberDate || null,
        internationalApplicationNumberDate: patent.dataDetails.internationalApplicationNumberDate || null,
        internationalPublicationNumberDate: patent.dataDetails.internationalPublicationNumberDate || null,
        priorityInformation: patent.dataDetails.priorityInformation || null,
        citingInformationCountry: patent.dataDetails.citingInformationCountry || null,
        citingInformationPublicationNumber: patent.dataDetails.citingInformationPublicationNumber || null,
        citingInformationPublicationDate: patent.dataDetails.citingInformationPublicationDate || null,
        citingInformationInventionName: patent.dataDetails.citingInformationInventionName || null,
        citingInformationIPC: patent.dataDetails.citingInformationIPC || null,
        citedInformationApplicationDate: patent.dataDetails.citedInformationApplicationDate || null,
        citedInformationInventionName: patent.dataDetails.citedInformationInventionName || null,
        citedInformationIPC: patent.dataDetails.citedInformationIPC || null,
        citedApplicationNumber: patent.dataDetails.citedApplicationNumber || null,
        relatedApplicationNumber: patent.dataDetails.relatedApplicationNumber || null,
        totalCitationCount: parseInt(patent.dataDetails.totalCitationCount) || null,
        summary: patent.dataDetails.summary || null,
        summaryKeywords: patent.dataDetails.summaryKeywords || null,
        trendKeywords: patent.dataDetails.trendKeywords || [],
      };
    });

    const uniqueItems = items.filter((item, index, self) =>
      index === self.findIndex((t) => t.patentTitle === item.patentTitle)
    );

    const techInfo = selectedTechInfo.find((info) => info.pageId === currentPageId);
    if (techInfo) {
      const finalItems = uniqueItems.filter((item) =>
        item.trendKeywords?.find((keyword) => keyword.id.includes(techInfo.selectedKeyword))?.value === 1
      );
      setTechs(finalItems);
    } else {
      setTechs(uniqueItems);
    }
  }
};

export default processTechData;
