import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useEffect } from "react";
import { NavermapsProvider } from "react-naver-maps";
import { UserProvider } from "./User";
import { LayoutProvider } from "../Hooks/useLayouts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSetAtom } from "jotai";
import { heightAtom } from "../Atoms/UserAtom";
import { DataProvider } from "./Data";

const queryClient = new QueryClient();

interface ProvidersProps {
  children: ReactNode;
}

/**
 * Providers - 전역 상태 관리를 위한 Provider
 * - QueryClientProvider: react-query를 위한 Provider
 * - NavermapsProvider: 네이버 지도 API를 위한 Provider
 * - UserProvider: 사용자 정보를 위한 Provider
 * - LayoutProvider: 레이아웃 정보를 위한 Provider
 * - LocalizationProvider: 날짜 형식을 위한 Provider
 * @param {ReactNode} children - 자식 컴포넌트
 * @returns {ReactNode} Providers 컴포넌트
 */
const Providers: React.FC<ProvidersProps> = ({ children }) => {
  const setHeight = useSetAtom(heightAtom);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <NavermapsProvider ncpClientId='95emx5fzsf'>
        <UserProvider>
          <DataProvider>
            <LayoutProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {children}
              </LocalizationProvider>
            </LayoutProvider>
          </DataProvider>
        </UserProvider>
      </NavermapsProvider>
    </QueryClientProvider>
  );
};

export default Providers;
