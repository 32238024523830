import { useAtom, useAtomValue } from "jotai";
import { AddPageButton } from "../../Button/Studio";
import * as S from "./styled";
import { PageProps } from "../../../Atoms/AssetAtom";
import { userAtom } from "../../../Atoms/UserAtom";
import { studioHeaderAtom } from "../../../Atoms/StudioAtom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dropdown, Input, Menu, MenuButton, MenuItem } from "@mui/joy";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';
import { apiAddress, commonLayoutVariable } from "../../../Atoms/GlobalVariable";
import { useUser } from "../../../Providers/User";
import BarChartImage from "../../../Assets/Images/Charts/BarChart.png"
import ItemDetailImage from "../../../Assets/Images/Charts/ItemDetail.png"
import CompanyTemplateImage from "../../../Assets/Images/Charts/CompanyTemplate.png"
import TechTemplateImage from "../../../Assets/Images/Charts/TechTemplate.png"
import LottieAnimation from "../../LottieAnimation";
import LoadingAnimation from "../../../Assets/Lotties/LoadingAnimation.json";

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지의 Sidebar에서 사용하는 컴포넌트들
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
interface PageContentProps extends PageProps {
  index: number;
}

const PageContent = ({ id, pageType, title, thumbnail, index }: PageContentProps) => {
  const [studioHeader, setStudioHeader] = useAtom(studioHeaderAtom);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditPage, setIsEditPage] = useState(false);
  const [pageName, setPageName] = useState('');
  const user = useAtomValue(userAtom);
  const refetchUserData = useUser().refetchUserData;

  const handleDeletePage = () => {
    setIsDialogOpen(true);
  }

  const handleDialogClose = async (confirmed: boolean) => {
    setIsDialogOpen(false);

    if (confirmed) {
      try {
        // 페이지가 하나밖에 없을 경우 페이지를 삭제하면 페이지를 초기화한다.
        if (user.currentAsset?.pages.length === 1) {
          const formData = new FormData();
          formData.append('pageId', id);
          const response = await fetch(`${apiAddress}/asset/page/clear`, {
            method: 'POST',
            body: formData,
          });
          if (!response.ok) {
            throw new Error('Failed to clear page');
          }
          const editFormData = new FormData();
          editFormData.append('pageId', id);
          editFormData.append('name', "페이지1");
          const editResponse = await fetch(`${apiAddress}/asset/page/edit/name`, {
            method: 'POST',
            body: editFormData,
          });
          if (!editResponse.ok) {
            throw new Error('Failed to update page name');
          }
          setStudioHeader((prev) => ({
            ...prev,
            pageSidebarOpen: false,
          }));
          refetchUserData();
          return;
        }
        // 페이지 삭제
        const formData = new FormData();
        formData.append('pageId', id);
        const response = await fetch(`${apiAddress}/asset/page/delete`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('Failed to delete page');
        }
        // 페이지 삭제 후 페이지 목록 다시 불러오기
        const pages = user.currentAsset?.pages ?? [];
        const currentPageIndex = pages.findIndex((page) => page.id === id);
        const prevPageIndex = (currentPageIndex - 1 + pages.length) % pages.length;
        setStudioHeader((prev) => ({
          ...prev,
          currentPageId: pages[prevPageIndex].id,
        }));
        refetchUserData();
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleEditPage = async (confirmed: boolean) => {
    setIsEditPage(false);
    if (confirmed) {
      try {
        const formData = new FormData();
        formData.append('pageId', id);
        formData.append('name', pageName);
        const response = await fetch(`${apiAddress}/asset/page/edit/name`, {
          method: 'POST',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('Failed to update page');
        }
        refetchUserData();
      } catch (error) {
        console.error(error);
      }
    }
    setPageName('');
  }

  return (
    <S.PageContentContainer
      onClick={() => {
        setStudioHeader((prev) => ({ ...prev, currentPageId: id }));
      }}
      $selected={studioHeader.currentPageId === id}
    >
      <S.PageContentTextContainer>
        <S.PageContentText $fontSize={11} $fontWeight="bold">
          {pageType === 'dashboard' ? '대시보드' : '문서'}
        </S.PageContentText>
        <Dropdown>
          <MenuButton sx={S.ButtonStyle}>
            <MoreHorizIcon />
          </MenuButton>
          <Menu>
            <MenuItem onClick={() => setIsEditPage(true)}>페이지명 수정</MenuItem>
            <MenuItem onClick={handleDeletePage}>페이지 삭제</MenuItem>
          </Menu>
        </Dropdown>
      </S.PageContentTextContainer>
      <S.PageContentText $fontSize={14} $fontWeight="600">
        {title}
      </S.PageContentText>
      <S.PageThumbnail>
        {thumbnail ? <S.ThumbnailImage src={thumbnail} alt="Thumbnail" /> : '페이지 썸네일'}
      </S.PageThumbnail>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>페이지 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText>
            정말 삭제하시겠습니까?
          </DialogContentText>
          <DialogContentText>
            삭제된 페이지는 복구할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)}>취소</Button>
          <Button onClick={() => handleDialogClose(true)} color="primary">삭제</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isEditPage}
        onClose={() => handleEditPage(false)}
      >
        <DialogTitle>페이지명 수정</DialogTitle>
        <DialogContent>
          <Input
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            placeholder="페이지명을 입력해주세요."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleEditPage(false)}>취소</Button>
          <Button onClick={() => handleEditPage(true)} color="primary">수정</Button>
        </DialogActions>
      </Dialog>
    </S.PageContentContainer>
  );
};

export const PageContents = () => {
  const user = useAtomValue(userAtom); // 현재 선택된 Asset
  const asset = user.currentAsset;

  return (
    <S.ContentsContainer>
      {asset && asset.pages.map((page, index) => (
        <PageContent key={page.id} {...page} index={index} />
      ))}
      <AddPageButton />
    </S.ContentsContainer>
  );
}

export const LayoutContents = () => {
  const user = useAtomValue(userAtom);
  const [studioHeader, setStudioHeader] = useAtom(studioHeaderAtom);
  const [addLayoutIndex, setAddLayoutIndex] = useState<number>(0);
  const [sourceTypes, setSourceTypes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const refetchUserData = useUser().refetchUserData;

  const handleAddLayout = async (componentName: string) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('pageId', studioHeader.currentPageId);
      formData.append('componentName', componentName);
      formData.append('cols', commonLayoutVariable.toString());
      const response = await fetch(`${apiAddress}/asset/page/content/add`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to add layout');
      }
      setStudioHeader((prev) => ({ ...prev, layoutSidebarOpen: false }));
      refetchUserData();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const handleAddTemplate = async (templateType: string) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('cols', commonLayoutVariable.toString());
      formData.append('pageId', studioHeader.currentPageId);
      formData.append('templateType', templateType);
      const response = await fetch(`${apiAddress}/asset/page/template/add`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to add template');
      }
      setStudioHeader((prev) => ({ ...prev, layoutSidebarOpen: false }));
      refetchUserData();
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (user.currentAsset) {
      const sources = user.currentAsset.sources.map((source) => source.assetType);
      // sources의 중복 제거
      setSourceTypes(sources.filter((source, index) => sources.indexOf(source) === index));
      console.log('Types of sources', sources.filter((source, index) => sources.indexOf(source) === index));
    }
  }, [user.currentAsset]);

  return (
    <S.ContentsContainer>
      {/* 페이지 레이아웃 설정 */}
      <S.LayoutContainer>
        <S.PageContentText $fontSize={14} $fontWeight="600">
          레이아웃
        </S.PageContentText>
        <S.AddLayoutContainer>
          <S.AddLayoutHeader>
            <S.AddLayoutHeaderTab $selected={addLayoutIndex === 0} onClick={() => setAddLayoutIndex(0)}>추천 템플릿</S.AddLayoutHeaderTab>
            <Divider orientation="vertical" flexItem sx={{ borderColor: "#222" }} />
            <S.AddLayoutHeaderTab $selected={addLayoutIndex === 1} onClick={() => setAddLayoutIndex(1)}>추천 컴포넌트</S.AddLayoutHeaderTab>
          </S.AddLayoutHeader>
          <S.AddLayoutContents>
            {/* 추천 컴포넌트 */}
            {addLayoutIndex === 0 ? (
              <>
                {
                  sourceTypes.includes('item') &&
                  <S.AddLayoutContent onClick={() => handleAddTemplate("hopefulnessItemList")}>
                    <S.PageContentText $fontSize={12} $fontWeight="600">
                      유망아이템 템플릿
                    </S.PageContentText>
                    <S.AddLayoutContentImage src={ItemDetailImage} alt="ItemDetail" />
                  </S.AddLayoutContent>
                }
                {
                  sourceTypes.includes('company') &&
                  <S.AddLayoutContent onClick={() => handleAddTemplate("companyInfoTemplate")}>
                    <S.PageContentText $fontSize={12} $fontWeight="600">
                      기업정보 템플릿
                    </S.PageContentText>
                    <S.AddLayoutContentImage src={CompanyTemplateImage} alt="ItemDetail" />
                  </S.AddLayoutContent>
                }
                {
                  sourceTypes.includes('tech') &&
                  <S.AddLayoutContent onClick={() => handleAddTemplate("techInfoTemplate")}>
                    <S.PageContentText $fontSize={12} $fontWeight="600">
                      기술 템플릿
                    </S.PageContentText>
                    <S.AddLayoutContentImage src={TechTemplateImage} alt="ItemDetail" />
                  </S.AddLayoutContent>
                }
                {
                  sourceTypes.includes('tech') && // 나중에 patent로 변경
                  <S.AddLayoutContent onClick={() => handleAddTemplate("patentInfoTemplate")}>
                    <S.PageContentText $fontSize={12} $fontWeight="600">
                      기술특허 템플릿
                    </S.PageContentText>
                    <S.AddLayoutContentImage src={TechTemplateImage} alt="ItemDetail" />
                  </S.AddLayoutContent>
                }
                {
                  sourceTypes.includes('thesis') &&
                  <S.AddLayoutContent onClick={() => handleAddTemplate("thesisInfoTemplate")}>
                    <S.PageContentText $fontSize={12} $fontWeight="600">
                      논문 템플릿
                    </S.PageContentText>
                    <S.AddLayoutContentImage src={TechTemplateImage} alt="ItemDetail" />
                  </S.AddLayoutContent>
                }
                {
                  sourceTypes.includes('trend') &&
                  <S.AddLayoutContent onClick={() => handleAddTemplate("trendGridTemplate")}>
                    <S.PageContentText $fontSize={12} $fontWeight="600">
                      트렌드 그리드 템플릿
                    </S.PageContentText>
                    <S.AddLayoutContentImage src={TechTemplateImage} alt="ItemDetail" />
                  </S.AddLayoutContent>
                }
              </>
            ) : (
              <>
                <S.AddLayoutContent onClick={() => handleAddLayout("trendGrid")}>
                  <S.PageContentText $fontSize={12} $fontWeight="600">
                    트렌드 그리드
                  </S.PageContentText>
                  <S.AddLayoutContentImage src={BarChartImage} alt="Bar Chart" />
                </S.AddLayoutContent>
              </>
            )}
          </S.AddLayoutContents>
        </S.AddLayoutContainer>
      </S.LayoutContainer>
      {/* 페이지 태그 설정 */}
      <S.LayoutContainer style={{ height: "35%" }}>
        <S.PageContentText $fontSize={14} $fontWeight="600">
          페이지 태그
        </S.PageContentText>
      </S.LayoutContainer>
      <Dialog open={isLoading} sx={{ padding: "20px" }}>
        <DialogTitle>적용중입니다. 잠시만 기다려주세요.</DialogTitle>
        <DialogContent>
          <LottieAnimation animationData={LoadingAnimation} width={200} height={200} />
        </DialogContent>
      </Dialog>
    </S.ContentsContainer>
  )
}
// ----------------------------------------------------------------------------------------
