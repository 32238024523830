import { Button } from '@mui/joy';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  textContents: string[];
  yesText: string;
  onYes: () => void;
  noText: string;
  onNo: () => void;
}

const YesOrNoDialog = ({ open, setOpen, title, textContents, yesText, onYes, noText, onNo }: Props) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {textContents.map((text, index) => (
          <DialogContentText key={index}>{text}</DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>{noText}</Button>
        <Button onClick={onYes} color="primary">{yesText}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default YesOrNoDialog;
