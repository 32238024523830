import { useAtomValue, useSetAtom } from "jotai";
import DashboardContents from "../Components/Contents/Dashboard";
import { userAtom } from "../Atoms/UserAtom";
import { useEffect } from "react";
import { dashboardAtom } from "../Atoms/DashboardAtom";
import { studioHeaderAtom } from "../Atoms/StudioAtom";

const Dashboard = () => {
  const user = useAtomValue(userAtom);
  const setDashboard = useSetAtom(dashboardAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);

  useEffect(() => {
    if (!user || !user.currentAsset) return;
    console.log("dashboard", user);
    setDashboard({
      currentAssetId: user.currentAsset.id,
      currentPageId: user.currentAsset.pages.find((page) => page.id === studioHeader.currentPageId)?.id || user.currentAsset.pages[0].id,
      currentPageContents: user.currentAsset.pages.find((page) => page.id === studioHeader.currentPageId)?.contents || user.currentAsset.pages[0].contents,
      pageList: user.currentAsset.pages
    });
  }, [user, setDashboard, studioHeader]);

  return (
    <DashboardContents />
  );
}

export default Dashboard;
