import { atom } from 'jotai';
import { Layout } from 'react-grid-layout';

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Atom
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
export interface CustomLayout extends Layout {
  contentId: string;
  contentType: string;
  contentName: string;
  dataType: string;
}

export interface StudioHeaderProps {
  pageSidebarOpen: boolean;
  layoutSidebarOpen: boolean;
  currentPageId: string;
  thumbnail: string | null;
}

export const studioHeaderAtom = atom<StudioHeaderProps>({
  pageSidebarOpen: false,
  layoutSidebarOpen: false,
  currentPageId: '',
  thumbnail: null,
});

export const studioContentAtom = atom<{
  contentRef: React.RefObject<HTMLElement> | null;
}>({
  contentRef: null
});
// ----------------------------------------------------------------------------------------
