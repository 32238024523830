import { CustomLayout } from "../../Atoms/StudioAtom";
import HopefulnessItemList from "./Item/HopefulnessList";
import ItemDetailInfo from "./Item/DetailInfo";
import ItemFeatureInfo from "./Item/FeatureInfo";
import ItemRankInfo from "./Item/RankInfo";
import PriceXScoreBubbleChart from "./Item/PriceXScoreBubble";
import TrendRadarChart from "./Item/TrendRadar";
import TestChart from "./Test";
import { useAtomValue } from "jotai";
import { heightAtom } from "../../Atoms/UserAtom";
import CompanyInfoList from "./Company/InfoList";
import CompanyMap from "./Company/Map";
import CompanyFinancialLine from "./Company/FinancialLine";
import CompanyFitnessRadarChart from "./Company/FitnessRadar";
import CompanyLeaderListBar from "./Company/LeaderListBar";
import TrendGrid from "./Trend/Grid";
import TechGrid from "./Tech/Grid";
import PatentList from "./Tech/List/Patent";
import PatentKeywordChart from "./Tech/KeywordChart/Patent";
import PatentCitationGraph from "./Tech/CitiationGraph";
import PatentYearlyChart from "./Tech/YearlyChart/Patent";
import ThesisList from "./Tech/List/Thesis";
import ThesisCheckChart from "./Tech/CheckChart";
import ThesisKeywordChart from "./Tech/KeywordChart/Thesis";
import ThesisYearlyChart from "./Tech/YearlyChart/Thesis";
import TrendMarketSize from "./Trend/MarketSize";
import { commonLayoutVariable } from "../../Atoms/GlobalVariable";

interface ChartsProps {
  chartData: CustomLayout;
  contentId: string;
  handleDelete?: (event: React.MouseEvent, contentId: string) => void;
}

export interface CommonChartProps {
  customLayout: CustomLayout;
  contentId: string;
  handleDelete?: (event: React.MouseEvent, contentId: string) => void;
  rowHeight: number;
}

const Charts = ({ chartData, contentId, handleDelete }: ChartsProps) => {
  const height = useAtomValue(heightAtom);
  const rowHeight = (height - 112) / commonLayoutVariable;
  switch (chartData.contentId) {
    // 유망아이템템플릿
    case "hopefulnessItemList":
      return <HopefulnessItemList customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "itemDetailInfo":
      return <ItemDetailInfo customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "itemRankInfo":
      return <ItemRankInfo customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "trendRadarChart":
      return <TrendRadarChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "priceXscoreBubbleChart":
      return <PriceXScoreBubbleChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "itemFeatureInfo":
      return <ItemFeatureInfo customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    // 기업정보템플릿
    case "companyInfoList":
      return <CompanyInfoList customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "companyAddressMap":
      return <CompanyMap customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "companyFinancialLineChart":
      return <CompanyFinancialLine customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "companyFitnessRadarChart":
      return <CompanyFitnessRadarChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "companyLeaderList":
      return <CompanyLeaderListBar contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} customLayout={chartData} />;
    // 기술 템플릿
    case "techGrid":
      return <TechGrid customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "patentInfoList":
      return <PatentList customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "patentCitationChart":
      return <PatentCitationGraph customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "patentKeywordChart":
      return <PatentKeywordChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "patentYearlyChart":
      return <PatentYearlyChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "thesisInfoList":
      return <ThesisList customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "thesisYearlyChart":
      return <ThesisYearlyChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "thesisCheckChart":
      return <ThesisCheckChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "thesisKeywordChart":
      return <ThesisKeywordChart customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    // 트렌드그리드템플릿
    case "trendGrid":
      return <TrendGrid customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    case "trendMarketSize":
      return <TrendMarketSize customLayout={chartData} contentId={contentId} handleDelete={handleDelete} rowHeight={rowHeight} />;
    default:
      return <TestChart contentId={contentId} handleDelete={handleDelete} />;
  }
}

export default Charts;
