import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  // position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 100;
  background-color: #f2f2f2;
  overflow-y: auto;
  overflow-x: hidden;
`;
