import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { ItemData, selectedItemAtom } from "../../../../Atoms/DashboardAtom";
import RadarChart from "../../Utils/Radar";
import { CommonChartProps } from "../..";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";

const TrendRadarChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedItems = useAtomValue(selectedItemAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedItem = selectedItems.find((item) => item.pageId === studioHeader.currentPageId)?.item as ItemData ?? null;

  const data = {
    labels: selectedItem?.trendData?.map((data) => data.id) ?? [],
    datasets: [
      {
        label: '선택한 아이템',
        data: selectedItem?.trendData?.map((data) => data.value) ?? [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: '평균',
        data: selectedItem?.trendData?.map((data) => data.average) ?? [],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        // position: 'bottom',
      },
      datalabels: {
        display: false,
      }
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div" >
            트렌드 적합도
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 90}px`, maxHeight: `${customLayout.h * rowHeight - 90}px`, gap: "5px" }}>
          {selectedItem ?
            <RadarChart data={data} options={options} /> :
            <Typography variant="body2" component="div" sx={{ textAlign: "center" }}>
              리스트에서 아이템을 선택해주세요.
            </Typography>
          }
        </Box>
      </CardContent>
    </Card>
  );
}

export default TrendRadarChart;
