import { Card, CardContent, Typography, List, Divider, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue, useAtom } from "jotai";
import { PatentInfoItem, selectedPatentInfoAtom } from "../../../../../Atoms/DashboardAtom";
import { studioHeaderAtom } from "../../../../../Atoms/StudioAtom";
import { CommonChartProps } from "../../../../Charts";
import { useData } from "../../../../../Providers/Data";

const PatentList = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const { techs } = useData();
  const [selectedPatentInfo, setSelectedPatentInfo] = useAtom(selectedPatentInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);

  const handleItemClicked = (item: PatentInfoItem) => {
    const prevItem = selectedPatentInfo.find((selectedItem) => selectedItem.pageId === studioHeader.currentPageId);
    if (prevItem) {
      setSelectedPatentInfo(selectedPatentInfo.map((selectedItem) => {
        if (selectedItem.pageId === studioHeader.currentPageId) {
          return { ...selectedItem, item };
        } else {
          return selectedItem;
        }
      }));
    } else {
      setSelectedPatentInfo([...selectedPatentInfo, { pageId: studioHeader.currentPageId, item }]);
    }
  }

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 10}px`, minHeight: `${customLayout.h * rowHeight - 10}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            특허정보
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-around", padding: "10px" }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '30%' }}>특허명</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>출원인</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>출원일자</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>출원인 국적</Typography>
          {/* <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>요약</Typography> */}
        </Box>
        <Divider />
        {techs.length > 0 ? (
          <List>
            {techs.map((item, index) => {
              const isSelected = selectedPatentInfo.find(selectedItem => selectedItem.item.id === item.id);
              return (
                <div key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      cursor: "pointer",
                      padding: "10px 0",
                      backgroundColor: isSelected ? "rgba(0, 123, 255, 0.1)" : "inherit" // 선택된 아이템의 배경색을 변경
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => handleItemClicked(item)}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "600", width: '30%', fontSize: "0.9rem" }}>{item.patentTitle && item.patentTitle.length > 10 ? item.patentTitle.substring(0, 10) + "..." : item.patentTitle}</Typography>
                    <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.applicant}</Typography>
                    <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.applicationDate}</Typography>
                    <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.applicantNationality}</Typography>
                    {/* <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.summary}</Typography> */}
                  </Box>
                  <Divider />
                </div>
              );
            })}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary">
            데이터를 불러오는 중입니다...
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default PatentList;
