import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { CompanyInfoItem, ItemData, PatentInfoItem, selectedTechInfoAtom, ThesisInfoItem } from '../../Atoms/DashboardAtom';
import { userAtom } from '../../Atoms/UserAtom';
import { useUser } from '../User';
import processItemData from './Processing/Item';
import processCompanyData from './Processing/Company';
import { studioHeaderAtom } from '../../Atoms/StudioAtom';
import processTechData from './Processing/Tech';
import processThesisData from './Processing/Thesis';

interface DataContextProps {
  items: ItemData[];
  companies: CompanyInfoItem[];
  techs: PatentInfoItem[];
  thesis: ThesisInfoItem[];
  loading: boolean;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const user = useAtomValue(userAtom);
  const { fetchAssetData, fetchAssetDataByMapper } = useUser();
  const [items, setItems] = useState<ItemData[]>([]);
  const [companies, setCompanies] = useState<CompanyInfoItem[]>([]);
  const [techs, setTechs] = useState<PatentInfoItem[]>([]);
  const [thesis, setThesis] = useState<ThesisInfoItem[]>([]);
  const [loading, setLoading] = useState(true);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedTechInfo = useAtomValue(selectedTechInfoAtom);

  useEffect(() => {
    console.log("studioHeader", studioHeader);
    if (!studioHeader.currentPageId) return;
    const userSourceTypes = user.currentAsset?.pages.find((page) => page.id === studioHeader.currentPageId)?.contents.map((content) => content.dataType) || [];
    const userSourceTypesSet = new Set(userSourceTypes);
    const fetchData = async () => {
      if (user.currentAsset?.id) {
        setLoading(true);
        const mapper = user.currentMapper?.find((mapper) => mapper.id === user.currentAsset?.id)?.mapper || null;

        // Fetch item data
        if (userSourceTypesSet.has("item")) {
          processItemData({ assetId: user.currentAsset.id, setItems, fetchAssetData, fetchAssetDataByMapper, mapper });
        }

        // Fetch company data
        if (userSourceTypesSet.has("company")) {
          processCompanyData({ assetId: user.currentAsset.id, setCompanies, fetchAssetData, fetchAssetDataByMapper, mapper });
        }

        if (userSourceTypesSet.has("tech")) {
          processTechData({ assetId: user.currentAsset.id, setTechs, fetchAssetData, fetchAssetDataByMapper, mapper, selectedTechInfo, currentPageId: studioHeader.currentPageId });
        }

        if (userSourceTypesSet.has("thesis")) {
          processThesisData({ assetId: user.currentAsset.id, setThesis, fetchAssetData, fetchAssetDataByMapper, mapper, selectedTechInfo, currentPageId: studioHeader.currentPageId });
        }

        setLoading(false);
      }
    };

    fetchData();
  }, [user.currentAsset, fetchAssetData, fetchAssetDataByMapper, user.currentMapper, studioHeader.currentPageId, selectedTechInfo]);

  return (
    <DataContext.Provider value={{ items, companies, techs, thesis, loading }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
