import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react"
import { userAtom } from "../Atoms/UserAtom";
import StudioContents from "../Components/Contents/Studio";
import { studioHeaderAtom } from "../Atoms/StudioAtom";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
const Studio = () => {
  const navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const [studioHeader, setStudioHeader] = useAtom(studioHeaderAtom);

  useEffect(() => {
    if (user && user.currentAsset && studioHeader.currentPageId === '') {
      setStudioHeader({
        pageSidebarOpen: false,
        layoutSidebarOpen: false,
        currentPageId: user.currentAsset.pages[0].id,
        thumbnail: user.currentAsset.pages[0].thumbnail,
      });
    }
  }, [user, studioHeader, setStudioHeader]);

  useEffect(() => {
    if (user) {
      if (user.assets.length === 0) {
        navigate('/assets');
        return;
      }
    }
  }, [user, navigate]);

  return user.currentAsset && <StudioContents currentAsset={user.currentAsset} />
}

export default Studio;
// ----------------------------------------------------------------------------------------
