import { useQuery } from '@tanstack/react-query';
import { apiAddress } from '../Atoms/GlobalVariable';
import { AssetProps, SourceProps } from '../Atoms/AssetAtom';

// ----------------------------------------------------------------------------------------
// 설명 : mongodb에서 내 대시보드 설정된 부분 가져오기
// 날짜 : 2024.06.25
// 담당자 : 권순형
export const fetchDashboardData = async (
  id: string,
  plan: string
): Promise<any> => {
  console.log('hi');

  const response = await fetch(`${apiAddress}/mysql/myDashboard`, {
    method: 'POST', // HTTP 메서드를 명시합니다.
    headers: {
      'Content-Type': 'application/json', // JSON 형식의 데이터를 보내기 위해 헤더를 설정합니다.
    },
    body: JSON.stringify({
      id: id,
      plan: plan,
    }),
  });

  console.log('hi2');

  if (!response.ok) {
    return null;
  }

  const data = await response.json();

  // if (data === false){
  //     return null;
  // }
  console.log(data);
  return data;
};

export function useGetFetchDashboardData(id: string, plan: string) {
  return useQuery({
    queryKey: ['dashboardData', id, plan],
    queryFn: () => fetchDashboardData(id, plan),
    staleTime: Infinity,
  });
}

// ----------------------------------------------------------------------------------------
// 설명 : mongodb에서 유저의 정보 가져오기
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
export const fetchUserData = async (
  id: string,
): Promise<any> => {
  const formData = new FormData();
  formData.append('userId', id);
  const response = await fetch(`${apiAddress}/user/load`, {
    method: 'POST', // HTTP 메서드를 명시합니다.
    body: formData,
  });

  if (!response.ok) {
    return null;
  }

  const data = await response.json();
  if (data.user && data.user.assets) {
    data.user.assets.forEach((asset: AssetProps) => {
      asset.sources.forEach((source: SourceProps) => {
        if (source.file && typeof source.file.fileData === 'string') {
          try {
            let fileDataString = source.file.fileData;
            // Replace NaN values with null or a valid JSON string representation
            fileDataString = fileDataString.replace(/NaN/g, 'null');
            // Attempt to parse the fileData string
            source.file.fileData = JSON.parse(fileDataString);
          } catch (e) {
            console.error('Error parsing fileData:', e);
          }
        }
      });
    });
  }
  console.log("user data", data);
  return data;
};

export function useGetFetchUserData(id: string) {
  return useQuery({
    queryKey: ['userData', id],
    queryFn: () => fetchUserData(id),
    enabled: !!id && id.trim() !== '',
    staleTime: Infinity,
  });
}
// ----------------------------------------------------------------------------------------
