import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  background: linear-gradient(
    280deg,
    #d943ff 0%,
    #5340ff 44%,
    #134cf8 81%,
    #0ae4f0 100%
  );
  z-index: 1000;
  width: 100%;
  min-width: 768px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // border-bottom:1px solid #ececec;
`;

interface InnerInnerContainerProps {
  $gap: string;
}

export const InnerInnerContainer = styled.div<InnerInnerContainerProps>`
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.$gap};
`;
