import { Box, Card, CardContent, Typography } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import CloseIcon from '@mui/icons-material/Close';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface TestChartProps {
  contentId: string;
  handleDelete?: (event: React.MouseEvent, contentId: string) => void;
}

const TestChart = ({ contentId, handleDelete }: TestChartProps) => {
  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 0.7)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Sample Chart",
      },
    },
  };

  return (
    <Card style={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            테스트 차트
          </Typography>
          {handleDelete &&
            <CloseIcon
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Bar data={data} options={options} />
      </CardContent>
    </Card>
  );
};

export default TestChart;
