import * as S from './styled';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppsIcon from '@mui/icons-material/Apps';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StudioSidebar from '../../Sidebar/Studio';
import { useAtom, useAtomValue } from 'jotai';
import { studioContentAtom, studioHeaderAtom } from '../../../Atoms/StudioAtom';
import { userAtom } from '../../../Atoms/UserAtom';
import { Button, Dropdown, Menu, MenuButton, MenuItem, Tooltip } from '@mui/joy';
import { useUser } from '../../../Providers/User';
import { apiAddress } from '../../../Atoms/GlobalVariable';
import { AssetProps } from '../../../Atoms/AssetAtom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import html2canvas from 'html2canvas';
import LoadingDialog from '../../Dialog/Loading';
import YesOrNoDialog from '../../Dialog/YesOrNo';

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Header 컴포넌트
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
const StudioHeader = () => {
  const [studioHeader, setStudioHeader] = useAtom(studioHeaderAtom);
  const [user, setUser] = useAtom(userAtom);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const studioContent = useAtomValue(studioContentAtom);
  const refetchUserData = useUser().refetchUserData;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handlePageSidebar = async (isOpen: boolean) => {
    if (isOpen) {
      setIsLoading(true);
      await handleGenerateThumbnail();
      setStudioHeader((prev) => ({ ...prev, pageSidebarOpen: isOpen }));
      setIsLoading(false);
    } else {
      setStudioHeader((prev) => ({ ...prev, pageSidebarOpen: isOpen }));
    }
  }

  const handleLayoutSidebar = () => {
    setStudioHeader((prev) => ({ ...prev, layoutSidebarOpen: !prev.layoutSidebarOpen }));
  }

  const handleGenerateThumbnail = async () => {
    if (!user.currentAsset || !studioContent.contentRef || !studioContent.contentRef.current) return;
    const element = studioContent.contentRef.current;
    // 원래 높이와 너비를 저장
    const originalHeight = element.style.height;
    const originalWidth = element.style.width;
    if (element.scrollHeight === 0) {
      // 높이와 너비를 강제로 설정
      element.style.height = '768px';
      element.style.width = '1280px';
    }

    try {
      const canvas = await html2canvas(studioContent.contentRef.current);
      const dataUrl = canvas.toDataURL('image/png');
      const formData = new FormData();
      formData.append('pageId', studioHeader.currentPageId);
      formData.append('thumbnail', dataUrl);
      formData.append('title', user.currentAsset?.pages.find((page) => page.id === studioHeader.currentPageId)?.title ?? '');
      const response = await fetch(`${apiAddress}/asset/page/update`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to update page');
      }
      refetchUserData();
    } catch (error) {
      console.error(error);
    } finally {
      // 원래 높이와 너비로 복원
      element.style.height = originalHeight;
      element.style.width = originalWidth;
    }
  }

  const requestAddPage = async (item: string) => {
    try {
      if (!user.currentAsset) {
        return;
      }
      const formData = new FormData();
      formData.append('assetId', user.currentAsset.id);
      formData.append('pageType', item);
      const cols = window.innerWidth > 1200 ? 4 : window.innerWidth > 996 ? 3 : window.innerWidth > 768 ? 3 : window.innerWidth > 480 ? 2 : 1;
      formData.append('cols', cols.toString());
      const response = await fetch(`${apiAddress}/asset/page/add`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to add page');
      }
      // 페이지 추가 후 페이지 목록 다시 불러오기
      refetchUserData();
    } catch (error) {
      console.error(error);
    }
  }

  const handlePageIndexUp = async () => {
    await handleGenerateThumbnail();
    setStudioHeader((prev) => {
      const pages = user.currentAsset?.pages ?? [];
      const currentPageIndex = pages.findIndex((page) => page.id === prev.currentPageId);
      const nextPageIndex = (currentPageIndex + 1) % pages.length;
      return {
        ...prev,
        currentPageId: pages[nextPageIndex].id,
      };
    });
  };

  const handlePageIndexDown = async () => {
    await handleGenerateThumbnail();
    setStudioHeader((prev) => {
      const pages = user.currentAsset?.pages ?? [];
      const currentPageIndex = pages.findIndex((page) => page.id === prev.currentPageId);
      const prevPageIndex = (currentPageIndex - 1 + pages.length) % pages.length;
      return {
        ...prev,
        currentPageId: pages[prevPageIndex].id,
      };
    });
  };

  const handleGoToAsset = async () => {
    setIsLoading(true);
    await handleSave();
    navigate('/assets');
    setIsLoading(false);
  }

  const handleSave = async () => {
    setIsLoading(true);
    await handleGenerateThumbnail();
    try {
      const formData = new FormData();
      const asset = user.currentAsset;

      if (asset) {
        formData.append('id', asset.id);
        formData.append('assetName', asset.assetName);
        formData.append('description', asset.description);
        formData.append('isPrivate', asset.isPrivate ? 'True' : 'False');
        formData.append('userId', user.userId);

        // 페이지 데이터에서 순환 참조 제거
        const pages = asset.pages.map(page => ({
          id: page.id,
          pageType: page.pageType,
          title: page.title,
          thumbnail: page.thumbnail,
          contents: page.contents.map(content => ({
            id: content.id,
            contentType: content.contentType,
            contentName: content.contentName,
            contentId: content.contentId,
            dataType: content.dataType,
            x: content.x,
            y: content.y,
            w: content.w,
            h: content.h,
            minW: content.minW,
            minH: content.minH,
            maxW: content.maxW,
            maxH: content.maxH,
          })),
        }));
        formData.append('pages', JSON.stringify(pages));

        // 소스 데이터에서 순환 참조 제거
        const sources = asset.sources.map(source => ({
          id: source.id,
          assetType: source.assetType,
          category: source.category,
          file: source.file,
          fileType: source.fileType,
          haveToRetrain: source.haveToRetrain,
          haveTospecifyCategory: source.haveTospecifyCategory,
          retrainEndDate: source.retrainEndDate,
          retrainSchedule: source.retrainSchedule,
          retrainStartDate: source.retrainStartDate,
          sourceName: source.sourceName,
          tableName: source.tableName,
        }));
        formData.append('sources', JSON.stringify(sources));

        const response = await fetch(`${apiAddress}/asset/save`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to save asset');
        }
        refetchUserData();
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const handleDeleteAsset = async () => {
    setIsDialogOpen(false);
    try {
      if (!user.currentAsset) {
        return;
      }
      // 유저의 이전 Asset의 id를 저장
      const changeAssetId = user.assets.findIndex((asset) => asset.id === user.currentAsset?.id) - 1;
      let changeAsset: AssetProps;
      let goToAsset: boolean = false;
      if (user.assets.length === 1) {
        goToAsset = true;
      } else if (changeAssetId === -1) {
        changeAsset = user.assets[1];
      } else {
        changeAsset = user.assets[changeAssetId];
      }
      const formData = new FormData();
      formData.append('assetId', user.currentAsset.id);
      const response = await fetch(`${apiAddress}/asset/delete`, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Failed to delete asset');
      }
      if (goToAsset) {
        refetchUserData();
        navigate('/assets');
        return;
      }
      // 페이지 추가 후 페이지 목록 다시 불러오기
      setUser((prev) => ({ ...prev, currentAsset: changeAsset }));
      refetchUserData();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <S.Container>
      <S.ButtonContainer style={{ left: 20 }}>
        <StudioSidebar anchor='left' open={studioHeader.pageSidebarOpen} onClose={handlePageSidebar} />
        <StudioSidebar anchor='right' open={studioHeader.layoutSidebarOpen} onClose={handleLayoutSidebar} />
        <Tooltip title="내 ASSET으로 이동">
          <Button size="sm" sx={S.ButtonStyle}>
            <ArrowBackIcon style={{ color: "#222", cursor: "pointer" }} onClick={handleGoToAsset} />
          </Button>
        </Tooltip>
        <Tooltip title="페이지 사이드바 열기">
          <Button size="sm" sx={S.ButtonStyle}>
            <SpaceDashboardIcon style={{ color: "#222", cursor: "pointer" }} onClick={() => handlePageSidebar(true)} />
          </Button>
        </Tooltip>
        <Dropdown>
          <Tooltip title="페이지 추가">
            <MenuButton size="sm" sx={S.ButtonStyle}>
              <AddIcon style={{ color: "#222", cursor: "pointer" }} />
            </MenuButton>
          </Tooltip>
          <Menu size="sm">
            <MenuItem onClick={() => requestAddPage("dashboard")}>대시보드</MenuItem>
            <MenuItem onClick={() => requestAddPage("document")}>문서</MenuItem>
          </Menu>
        </Dropdown>
        <Tooltip title="이전 페이지로 이동">
          <Button size="sm" sx={S.ButtonStyle}>
            <KeyboardArrowUpIcon style={{ color: "#222", cursor: "pointer" }} onClick={handlePageIndexDown} />
          </Button>
        </Tooltip>
        <Tooltip title="다음 페이지로 이동">
          <Button size="sm" sx={S.ButtonStyle}>
            <KeyboardArrowDownIcon style={{ color: "#222", cursor: "pointer" }} onClick={handlePageIndexUp} />
          </Button>
        </Tooltip>
        <Tooltip title="저장">
          <Button size="sm" sx={S.ButtonStyle} onClick={handleSave}>
            <SaveIcon style={{ color: "#222", cursor: "pointer" }} />
          </Button>
        </Tooltip>
      </S.ButtonContainer>
      <S.ButtonContainer>
        <Dropdown>
          {/* <Tooltip title="ASSET 변경"> */}
          <MenuButton size="sm" sx={S.ButtonStyle}>
            <div style={{ paddingLeft: "10px" }}>{user.currentAsset?.assetName ?? ""}</div>
            <ArrowDropDownIcon />
          </MenuButton>
          {/* </Tooltip> */}
          <Menu size="sm">
            {user.assets.map((asset) => (
              asset.id === user.currentAsset?.id ? null :
                <MenuItem key={asset.id} onClick={() => {
                  setUser((prev) => ({ ...prev, currentAsset: asset }));
                  setStudioHeader({ pageSidebarOpen: false, layoutSidebarOpen: false, currentPageId: asset.pages[0].id, thumbnail: asset.pages[0].thumbnail });
                  refetchUserData();
                  console.log("asset change", asset);
                }}>
                  {asset.assetName}
                </MenuItem>
            ))}
          </Menu>
        </Dropdown>
        <Tooltip title="현재 ASSET 삭제">
          <DeleteIcon style={{ color: "#222", cursor: "pointer" }} onClick={() => setIsDialogOpen(true)} />
        </Tooltip>
      </S.ButtonContainer>
      <S.ButtonContainer style={{ right: 20 }}>
        <Tooltip title="레이아웃 사이드바 열기">
          <Button size="sm" sx={S.ButtonStyle}>
            <AppsIcon style={{ color: "#222", cursor: "pointer" }} onClick={handleLayoutSidebar} />
          </Button>
        </Tooltip>
      </S.ButtonContainer>
      <YesOrNoDialog open={isDialogOpen} setOpen={setIsDialogOpen} title="ASSET 삭제" textContents={["정말 삭제하시겠습니까?", "삭제된 ASSET은 복구할 수 없습니다."]} yesText="삭제" onYes={handleDeleteAsset} noText="취소" onNo={() => setIsDialogOpen(false)} />
      <LoadingDialog isLoading={isLoading} text="저장중입니다. 잠시만 기다려주세요." />
    </S.Container>
  );
}

export default StudioHeader;
// ----------------------------------------------------------------------------------------
