import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { selectedCompanyInfoAtom } from "../../../../Atoms/DashboardAtom";
import { CommonChartProps } from "../..";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CompanyFinancialLine = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedCompanyInfo = useAtomValue(selectedCompanyInfoAtom);
  const [firstCompany, setFirstCompany] = useState(selectedCompanyInfo.length > 0 ? selectedCompanyInfo[0].item : null);

  const options: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    // stacked: false,
    plugins: {
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return `${context[0].label}년`;
          },
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}%`;
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const labels = ['2018', '2019', '2020', '2021', '2022', '2023'];

  const data = {
    labels,
    datasets: [
      {
        label: '매출액',
        data: [6.5, 9.3, 8.5, 5.7, 10.9, 8.2],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
      },
      {
        label: '영업이익',
        data: [10.5, 5.3, 7.5, 8.7, 7.9, 9.2],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1',
      },
    ],
  };

  useEffect(() => {
    setFirstCompany(selectedCompanyInfo.length > 0 ? selectedCompanyInfo[0].item : null);
  }, [selectedCompanyInfo]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div" >
            기업 연 매출액 & 영업이익 성장률
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px" }}>
          {firstCompany ?
            <Line data={data} options={options} />
            :
            <Typography variant="body2" component="div" sx={{ textAlign: "center" }}>
              리스트에서 아이템을 선택해주세요.
            </Typography>
          }
        </Box>
      </CardContent>
    </Card>
  );
}

export default CompanyFinancialLine;
