/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtom, useAtomValue } from "jotai";
import { ItemData, selectedItemAtom } from "../../../../Atoms/DashboardAtom";
import BubbleChart from "../../Utils/Bubble";
import { useState, useEffect, useCallback, useMemo } from "react";
import { colorMap } from "../../../../Data/colorMap";
import { CommonChartProps } from "../..";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";
import { useData } from "../../../../Providers/Data";

const PriceXScoreBubbleChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const [selectedItems, setSelectedItems] = useAtom(selectedItemAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedItem = selectedItems.find((item) => item.pageId === studioHeader.currentPageId)?.item;
  const { items } = useData(); // useData 훅으로 데이터 가져오기
  const [data, setData] = useState<any>({ datasets: [] });

  // Create a map for categories to colors
  const categoryColorMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    return map;
  }, []);

  const getColorForCategory = useCallback((category: string) => {
    if (category === "") return "#000000";
    if (!categoryColorMap[category]) {
      const index = Object.keys(categoryColorMap).length % colorMap.length;
      categoryColorMap[category] = colorMap[index];
    }
    return categoryColorMap[category];
  }, [categoryColorMap]);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        min: 4,
        max: 5
      },
      x: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true, // Show the legend
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            const categories = Array.from(new Set(datasets.map((dataset: any) => dataset.category))) as string[];
            return categories.filter((category) => category !== "").map((category) => ({
              text: category,
              fillStyle: getColorForCategory(category)
            }));
          }
        },
      },
      datalabels: {
        display: false,
      }
    },
  };

  const processFileData = useCallback((items: ItemData[]) => {
    const idData = items.map((item) => item.id);
    const nameData = items.map((item) => item.name ?? "");
    const priceData = items.map((item) => item.price ?? 0);
    const productScoringData = items.map((item) => item.productScoring ?? 0);
    const ratingData = items.map((item) => item.rating);
    if (!nameData || !priceData || !productScoringData || !ratingData || !idData) return;


    const scoreDividedByPriceData = priceData.map((price: number, i: number) => {
      if (price === 0) return 0;
      return productScoringData[i] / price * 10000;
    });

    const itemLastCategory = items.map((item) => item.itemCategories[item.itemCategories.length - 1]);

    const datasets = idData.map((id: string, i: number) => {
      const isSelected = selectedItem && selectedItem.id === id;
      return {
        label: nameData[i],
        category: itemLastCategory[i],
        data: [{
          x: scoreDividedByPriceData[i],
          y: ratingData[i],
          r: isSelected ? 10 : 5 // Adjust radius size for selected item
        }],
        backgroundColor: isSelected
          ? getColorForCategory(itemLastCategory[i])
          : `${getColorForCategory(itemLastCategory[i])}60`, // Add some transparency to the color
      };
    });
    setData({ datasets });
  }, [items, selectedItem, getColorForCategory]);

  useEffect(() => {
    processFileData(items);
  }, [items, processFileData]);

  const handleItemClicked = useCallback((clickedItem: any) => {
    const item = items.find((item) => item.name === clickedItem.label);
    if (!item) return;
    const newSelectedItems = selectedItems.map((selectedItem) =>
      selectedItem.pageId === studioHeader.currentPageId
        ? { ...selectedItem, item }
        : selectedItem
    );
    if (!selectedItems.some((selectedItem) => selectedItem.pageId === studioHeader.currentPageId)) {
      newSelectedItems.push({ pageId: studioHeader.currentPageId, item });
    }
    setSelectedItems(newSelectedItems);
  }, [items, selectedItems, setSelectedItems, studioHeader.currentPageId]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div" >
            BMO
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 90}px`, maxHeight: `${customLayout.h * rowHeight - 90}px`, gap: "5px" }} onMouseDown={(e) => e.stopPropagation()}>
          <BubbleChart data={data} options={options} onElementClick={handleItemClicked} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default PriceXScoreBubbleChart;
