import { Card, CardContent, Typography, List, Divider, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue, useAtom } from "jotai";
import { CommonChartProps } from "../../..";
import { selectedThesisInfoAtom, ThesisInfoItem } from "../../../../../Atoms/DashboardAtom";
import { studioHeaderAtom } from "../../../../../Atoms/StudioAtom";
import { useData } from "../../../../../Providers/Data";

const getRepresentativeAuthor = (author: string | null) => {
  if (!author) {
    return "";
  }
  const authors = author.split(';');
  if (authors.length > 1) {
    return authors[0].split(" ")[0] + " 외 " + (authors.length - 1) + "명";
  }
  return authors[0];
}

const ThesisList = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const { thesis } = useData();
  const [selectedThesisInfo, setSelectedThesisInfo] = useAtom(selectedThesisInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);

  const handleItemClicked = (item: ThesisInfoItem) => {
    const prevItem = selectedThesisInfo.find((selectedItem) => selectedItem.pageId === studioHeader.currentPageId);
    if (prevItem) {
      setSelectedThesisInfo(selectedThesisInfo.map((selectedItem) => {
        if (selectedItem.pageId === studioHeader.currentPageId) {
          return { ...selectedItem, item };
        } else {
          return selectedItem;
        }
      }));
    } else {
      setSelectedThesisInfo([...selectedThesisInfo, { pageId: studioHeader.currentPageId, item }]);
    }
  }

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 10}px`, minHeight: `${customLayout.h * rowHeight - 10}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            논문정보
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-around", padding: "10px" }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '30%' }}>논문명</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>저자</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>발행연도</Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', width: '20%' }}>발행기관</Typography>
        </Box>
        <Divider />
        {thesis.length > 0 ? (
          <List>
            {thesis.map((item, index) => {
              const isSelected = selectedThesisInfo.find(selectedItem => selectedItem.item.index === item.index);
              return (
                <div key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                      cursor: "pointer",
                      padding: "10px 0",
                      backgroundColor: isSelected ? "rgba(0, 123, 255, 0.1)" : "inherit" // 선택된 아이템의 배경색을 변경
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={() => handleItemClicked(item)}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "600", width: '30%', fontSize: "0.9rem" }}>{item.title && item.title.length > 10 ? item.title.substring(0, 10) + "..." : item.title}</Typography>
                    <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{getRepresentativeAuthor(item.author)}</Typography>
                    <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.publishYear}</Typography>
                    <Typography variant="body2" sx={{ width: '20%', fontSize: "0.8rem" }}>{item.publishInstitution}</Typography>
                  </Box>
                  <Divider />
                </div>
              );
            })}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary">
            데이터를 불러오는 중입니다...
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default ThesisList;
