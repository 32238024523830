import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { CompanyInfoItem, selectedCompanyInfoAtom } from "../../../../Atoms/DashboardAtom";
import RadarChart from "../../Utils/Radar";
import { CommonChartProps } from "../..";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";

const CompanyFitnessRadarChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedCompanyInfoItems = useAtomValue(selectedCompanyInfoAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedCompanyInfoItem = selectedCompanyInfoItems.find((item) => item.pageId === studioHeader.currentPageId)?.item as CompanyInfoItem ?? null;

  const data = {
    labels: selectedCompanyInfoItem?.companyFitness?.map((data, index) => data.id.replace(/\(.*?\)/g, "").replace("기업적합도_", "")) ?? [],
    datasets: [
      {
        label: '선택한 아이템',
        data: selectedCompanyInfoItem?.companyFitness?.map((data) => data.value) ?? [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: '평균',
        data: selectedCompanyInfoItem?.companyFitness?.map((data) => data.average) ?? [],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }
    ],
  };

  const options = {
    scales: {
      r: {
        min: 0, // 최소값 설정
        max: 20, // 최대값 설정
        ticks: {
          stepSize: 5, // 눈금 간격 설정
        },
      },
    },
    plugins: {
      legend: {
        // display: false, // Hide the legend
      },
      title: {
        display: true,
        text: '기업 적합도', // 차트 제목 설정
        font: {
          size: 18,
          weight: '500'
        }
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems: any) {
            const index = tooltipItems[0].dataIndex;
            return selectedCompanyInfoItem?.companyFitness[index].id ?? '';
          }
        }
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 40}px`, maxHeight: `${customLayout.h * rowHeight - 40}px`, gap: "5px" }}>
          {selectedCompanyInfoItem ?
            <RadarChart data={data} options={options} /> :
            <Typography variant="body2" component="div" sx={{ textAlign: "center" }}>
              리스트에서 기업을 선택해주세요.
            </Typography>
          }
        </Box>
        {handleDelete &&
          <CloseIcon
            sx={{ cursor: "pointer", position: "absolute", top: "15px", right: "15px" }}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => handleDelete(e, contentId)}
          />
        }
      </CardContent>
    </Card>
  );
}

export default CompanyFitnessRadarChart;
