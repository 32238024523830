import { useEffect, useState } from 'react';
import { useMemberstack, useMemberstackModal } from '@memberstack/react';
import { useAtom } from 'jotai';
import { Outlet, useLocation } from 'react-router-dom';
import { memberAtom, testMember } from './Atoms/UserAtom';
import * as S from './styled';
import { ModalSidebar } from './Components/Sidebar/ModalSIdebar';
import { NotLogin } from './Pages/NotLogin';
import Header from './Components/Header';
import Providers from './Providers';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

function Root() {
  const memberstack = useMemberstack();
  const { openModal, hideModal } = useMemberstackModal();
  const location = useLocation();
  const currentPath = location.pathname;
  const [width, setWidth] = useState(window.innerWidth);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [member, setMember] = useAtom(memberAtom);

  useEffect(() => {
    // 메타 태그 추가
    const metaNaver = document.createElement('meta');
    metaNaver.name = 'naver-site-verification';
    metaNaver.content = '45352a7f345d864f1994f26462c09186720768e6';
    document.head.appendChild(metaNaver);

    // 페이지 제목 설정
    document.title = '마름모™ | mareummo | (주)이음인터렉티브';

    // 컴포넌트 언마운트 시 메타 태그 제거
    return () => {
      document.head.removeChild(metaNaver);
    };
  }, []);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember().then(({ data: member }) => {
        if (member) {
          console.log('member', member);
          setMember(member);
        } else {
          openModal({
            type: 'LOGIN',
          }).then(() => {
            memberstack.getCurrentMember().then(({ data: member }) => {
              console.log(member);
              setMember(member);
              hideModal();
            });
          });
        }
      });
      // setMember(testMember);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (width <= 768) {
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(false);
    }
  }, [width]);

  return (
    <Providers>
      <S.FullContainer>
        {member === null ? (
          <NotLogin />
        ) : (
          <>
            <Header />
            <ModalSidebar />
            <S.ContentContainer $currentPath={currentPath}>
              <Outlet />
            </S.ContentContainer>
          </>
        )}
      </S.FullContainer>
      <Dialog open={isDialogOpen}>
        <DialogContent>
          <DialogTitle>모바일 환경에서는 이용이 불가능합니다.</DialogTitle>
          <DialogTitle>PC 환경에서 이용해주세요.</DialogTitle>
        </DialogContent>
      </Dialog>
    </Providers>
  );
}

export default Root;
