import * as S from './styled';
import { useAtom } from 'jotai';
import { Button } from '@mui/joy';
import { TextField } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { assetsPageAtom } from '../../../Atoms/AssetsPageAtom';
import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------------------------
// 설명 : Studio 페이지에서 사용하는 Header 컴포넌트
// 날짜 : 2024.07.10
// 담당자 : 정찬웅
const AssetsHeader = () => {
  const [assetsPage, setAssetsPage] = useAtom(assetsPageAtom);
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.ButtonContainer style={{ left: 20 }}>
        <Button variant='outlined' sx={{ height: "40px" }}>
          <TextField variant='standard' value={assetsPage.currentSearchText} onChange={(event: ChangeEvent<HTMLInputElement>) => setAssetsPage((prev) => ({ ...prev, currentSearchText: event.target.value }))} ></TextField>
        </Button>
      </S.ButtonContainer>
      <div>ASSET</div>
      <S.ButtonContainer style={{ right: 20 }}>
        <Button startDecorator={<Add />} onClick={() => navigate('/makeAsset')} >ASSET 추가</Button>
      </S.ButtonContainer>
    </S.Container>
  );
}

export default AssetsHeader;
// ----------------------------------------------------------------------------------------
