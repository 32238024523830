import { Box, Card, CardContent, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { ItemData, selectedItemAtom } from "../../../../Atoms/DashboardAtom";
import useCount from "../../../../Hooks/useCount";
import { CommonChartProps } from "../..";
import { studioHeaderAtom } from "../../../../Atoms/StudioAtom";

const ItemRankInfo = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const selectedItems = useAtomValue(selectedItemAtom);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedItem = selectedItems.find((item) => item.pageId === studioHeader.currentPageId)?.item as ItemData ?? null;
  const rank = useCount(500, selectedItem?.index ?? 499);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * 300 - 40}px`, overflowY: "auto", overflowX: "hidden" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: "50px" }}>
          <Typography variant="h6" component="div" >
            유망아이템 순위
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={async (e) => await handleDelete(e, contentId)}
            />
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", minHeight: `${customLayout.h * rowHeight - 90}px`, maxHeight: `${customLayout.h * rowHeight - 90}px`, gap: "5px" }}>
          {selectedItem ?
            <>
              <Typography variant="h1" component="div" sx={{ textAlign: "center" }}>
                {rank + 1}
              </Typography>
              {selectedItem && rank === selectedItem.index && (
                <Typography variant="h5" component="div" sx={{ textAlign: "center" }}>
                  위
                </Typography>
              )}
            </> :
            <Typography variant="body2" component="div" sx={{ textAlign: "center" }}>
              리스트에서 아이템을 선택해주세요.
            </Typography>
          }
        </Box>
      </CardContent>
    </Card>
  );
}

export default ItemRankInfo;
