import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { CommonChartProps } from "../..";
import KeyValueLeftBarPlot from "../../Utils/KeyValueLeftBarPlot";
import { useData } from "../../../../Providers/Data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CompanyLeaderListBar = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const [sortedItems, setSortedItems] = useState<any[]>([]);
  const { companies } = useData();

  // 데이터가 변경될 때마다 정렬 작업 수행
  useEffect(() => {
    const sorted = companies.sort((a, b) => (b.numericalSales ?? 0) - (a.numericalSales ?? 0)).slice(0, 10);
    setSortedItems(sorted);
  }, [companies]);

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            선도기업(매출액)
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box
          sx={{ height: `${customLayout.h * rowHeight - 90}px` }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <KeyValueLeftBarPlot data={sortedItems.map((item) => { return { key: item.companyName, value: item.numericalSales } })} xlabel="매출액" ylabel="기업명" title="선도기업" description="선도기업의 매출액을 나타낸 차트입니다." />
        </Box>
      </CardContent>
    </Card>
  );
}

export default CompanyLeaderListBar;
