import { useQuery } from '@tanstack/react-query';
import { apiAddress } from '../Atoms/GlobalVariable';
import { mapperOption } from '../Atoms/UserAtom';

// API 요청 메서드
export const fetchDataByAssetType = async (
  assetId: string,
  type: string
): Promise<any> => {
  const formData = new FormData();
  formData.append('assetId', assetId);
  formData.append('type', type);

  const response = await fetch(`${apiAddress}/file-data/asset-type`, {
    method: 'POST', // HTTP 메서드를 명시합니다.
    body: formData,
  });

  if (!response.ok) {
    console.error('Error fetching data by asset type:', response.statusText);
    return null;
  }

  const data = await response.json();
  return data;
};

// React Query를 사용하는 커스텀 훅
export function useFetchDataByAssetType(assetId: string, type: string) {
  return useQuery({
    queryKey: ['assetData', assetId, type],
    queryFn: () => fetchDataByAssetType(assetId, type),
    enabled: !!assetId && !!type && assetId.trim() !== '' && type.trim() !== '',
    staleTime: Infinity,
  });
}

export const fetchDataByMapper = async (
  assetId: string,
  mapper: mapperOption[]
): Promise<any> => {
  const formData = new FormData();
  formData.append('assetId', assetId);
  formData.append('mapper', JSON.stringify(mapper));

  const response = await fetch(`${apiAddress}/file-data/mapper`, {
    method: 'POST', // HTTP 메서드를 명시합니다.
    body: formData,
  });

  if (!response.ok) {
    console.error('Error fetching data by mapper:', response.statusText);
    return null;
  }

  const data = await response.json();
  return data;
}

export function useFetchDataByMapper(assetId: string, mapper: mapperOption[]) {
  return useQuery({
    queryKey: ['assetDataByMapper', assetId, mapper],
    queryFn: () => fetchDataByMapper(assetId, mapper),
    enabled: !!assetId && !!mapper && assetId.trim() !== '',
    staleTime: Infinity,
  });
}
