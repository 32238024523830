import { atom } from 'jotai';
import { RefObject } from 'react';

export interface MatchedColumns2Props {
  itemColumn: string;
  matchedColumns: string[];
}

export interface MatchedColumnsProps {
  columns: string[];
  matchedColumns: MatchedColumns2Props[];
}

export const initialSource = {
  id: "",
  assetType: "",
  fileType: "",
  sourceName: "",
  description: "",
  file: null,
  haveTospecifyCategory: false,
  category: null,
  haveToRetrain: false,
  tableName: null,
  retrainSchedule: null,
  retrainStartDate: null,
  retrainEndDate: null,
  url: null,
  usingModel: null,
  keywords: null,
  matchedColumns: null,
  uniqueKeywords: "",
  uniqueKsic: "",
};

export interface SourceProps {
  id: string;
  assetType: string;
  category: string | null;
  file: FileProps | null;
  fileType: string;
  haveToRetrain: boolean;
  haveTospecifyCategory: boolean;
  sourceName: string;
  description: string;
  tableName: string | null;
  retrainSchedule: number | null;
  retrainEndDate: Date | null;
  retrainStartDate: Date | null;
  url: string | null;
  usingModel: string | null;
  keywords: string | null;
  matchedColumns: MatchedColumnsProps | null;
  uniqueKeywords: string;
  uniqueKsic: string;
}

interface makeEditAssetProps {
  id: string;
  name: string;
  isPrivate: boolean;
  description: string;
  sources: SourceProps[] | [];
}

export const initialMakeEditAsset = {
  id: '',
  name: '',
  isPrivate: false,
  description: '',
  sources: [],
};

export const makeEditAssetAtom = atom<makeEditAssetProps>(initialMakeEditAsset);

export const publicSourceAtom = atom<SourceProps[]>([]);

export const sourceOptionAtom = atom({
  assetType: [
    {
      value: 'item',
      text: '아이템',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'tech',
      text: '기술',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'company',
      text: '기업',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'thesis',
      text: '논문',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'thesisKeyword',
      text: '논문키워드',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'manager',
      text: '기업 담당자',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'consumer',
      text: '소비자',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'trend',
      text: '트렌드',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'review',
      text: '리뷰',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        {
          value: 'api',
          text: 'API Code File',
        },
        {
          value: 'crawling',
          text: 'Crawling Code File',
        },
      ],
    },
    {
      value: 'etc',
      text: '기타',
      child: [
        {
          value: 'excel',
          text: '엑셀파일',
        },
        // {
        //     value : "api",
        //     text : "API Code File",
        // },
        // {
        //     value : "crawling",
        //     text : "Crawling Code File",
        // },
        {
          value: 'video',
          text: '동영상',
        },
        {
          value: 'img',
          text: '이미지',
        },
        {
          value: 'PDF',
          text: 'PDF 파일',
        },
      ],
    },
  ],
});

export const retrainScheduleOptionAtom = atom([
  {
    value: 1,
    text: '매일',
  },
  {
    value: 7,
    text: '매주',
  },
  {
    value: 30,
    text: '매월',
  },
  {
    value: 365,
    text: '매년',
  },
]);

export type alertType = 'primary' | 'neutral' | 'success' | 'warning' | 'danger';

interface alertMessageProps {
  message: string;
  type: alertType;
}

export const alertMessageAtom = atom<alertMessageProps>({
  message: '',
  type: 'danger',
});

export interface ExcelFileDataProps {
  id: string;
  data: any[];
}

export interface GeocodingDataProps {
  id: string;
  latitude: number[];
  longitude: number[];
}

export interface ExcelFileProps {
  categoricalColumns: string[];
  numericalColumns: string[];
  geocodingColumns: string[];
  categoricalData: ExcelFileDataProps[];
  numericalData: ExcelFileDataProps[];
  geocodingData: GeocodingDataProps[];
}

export interface FileProps {
  fileId: string;
  fileType: string;
  file: File | null;
  originalName: string | null;
  fileData: ExcelFileProps | any;
}

export interface AssetProps {
  id: string;
  assetName: string;
  description: string;
  isPrivate: boolean;
  pages: PageProps[];
  sources: SourceProps[];
}

export interface PageProps {
  id: string;
  pageType: string;
  title: string;
  thumbnail: string | null;
  currentRef?: RefObject<HTMLDivElement>;
  contents: PageContentProps[];
}

export interface PageContentProps {
  id: string;
  contentId: string;
  contentType: 'chart' | 'text' | 'image';
  contentName: string;
  dataType: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
}

export const assetAtom = atom<AssetProps[]>([]);
