import { ListItem } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 112px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 112px);
  min-height: calc(100vh - 112px);
  background-color: #d9d9d9;
  overflow-y: hidden;
  overflow-x: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 80%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
`;


export const ListItemStyle = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  transition: background-color 0.3s ease;
  gap: 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const BlankContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-height: 90%;
  min-height: 90%;
  border: 2px dashed #d9d9d9;
  border-radius: 10px;
  gap: 20px;
  color: #d9d9d9;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  align-self: center;
`;

export const ButtonStyle = {
  display: "flex",
  flexDirection: "row",
  border: "none",
  padding: 0,
  height: "30px",
  backgroundColor: "transparent",
  gap: "10px",

  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
};
