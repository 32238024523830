import { CompanyInfoItem, ResponseFileData } from "../../../../Atoms/DashboardAtom";
import { mapperOption } from "../../../../Atoms/UserAtom";

interface ProcessCompanySourceDataProps {
  assetId: string;
  setCompanies: (companies: CompanyInfoItem[]) => void;
  fetchAssetData: (assetId: string, type: string) => Promise<any>
  fetchAssetDataByMapper: (assetId: string, mapper: mapperOption[]) => Promise<any>;
  mapper: mapperOption[] | null;
}

const processCompanyData = async ({ assetId, setCompanies, fetchAssetData, fetchAssetDataByMapper, mapper }: ProcessCompanySourceDataProps) => {
  let itemResponse;
  if (mapper) {
    itemResponse = await fetchAssetDataByMapper(assetId, mapper);
  } else {
    itemResponse = await fetchAssetData(assetId, "company");
  }
  if (itemResponse && itemResponse.data) {
    const items: CompanyInfoItem[] = itemResponse.data.map((data: ResponseFileData, index: number) => {
      // 데이터를 바탕으로 각 항목을 구성
      const industryCategories = data.dataDetails.industrialCategories.split(' ; ') || [];
      const productCategories = data.dataDetails.itemCategories.split(' ; ') || [];
      const companyFitness = data.dataDetails.companyFitness.map((item: any) => ({
        id: item.id,
        value: item.value,
      })) || [];
      const elements = data.dataDetails.elements.map((item: any) => ({
        id: item.id,
        value: item.value,
      }));
      const featureKeywordData = data.dataDetails.featureKeywordData.map((item: any) => ({
        id: item.id,
        value: item.value,
      }));

      return {
        index: index,
        companyName: data.dataDetails.companyName || null,
        itemNames: data.dataDetails.itemNames,
        industryCategories: industryCategories.map((id: string) => ({
          id,
          value: id,
        })),
        productCategories: productCategories.map((id: string) => ({
          id,
          value: id,
        })),
        country: data.dataDetails.country || null,
        address: data.dataDetails.address || null,
        companyFitnessScore: parseFloat(data.dataDetails.companyFitnessScore) || null,
        companyFitness,
        isMissing: data.dataDetails.isMissing || null,
        elements,
        sales: data.dataDetails.sales || null,
        numericalSales: parseFloat(data.dataDetails.numericalSales) || null,
        establishmentYear: parseInt(data.dataDetails.establishmentYear, 10) || null,
        featureKeywordData,
        latitude: parseFloat(data.dataDetails.latitude) || null,
        longitude: parseFloat(data.dataDetails.longitude) || null,
        url: data.dataDetails.url || null,
        employee: data.dataDetails.employee || null,
      };
    });

    // 중복된 항목 제거
    const uniqueItems = items.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.companyName === item.companyName
      ))
    );

    setCompanies(uniqueItems);
  }
};

export default processCompanyData;
