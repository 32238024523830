import { Box, Button, Typography } from '@mui/material';
import * as S from './styled';
import { useAtom } from 'jotai';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { mapperOption, userAtom } from '../../../Atoms/UserAtom';
import { studioHeaderAtom } from '../../../Atoms/StudioAtom';
import { useUser } from '../../../Providers/User';
import { useEffect, useState } from 'react';
import Mapper from '../../Mapper';
// import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';

const DashboardHeader = () => {
  const [user, setUser] = useAtom(userAtom);
  const [studioHeader, setStudioHeader] = useAtom(studioHeaderAtom);
  const refetchUserData = useUser().refetchUserData;
  const [numOfPages, setNumOfPages] = useState(user.currentAsset?.pages.length || 0);
  const [pageTitle, setPageTitle] = useState(user.currentAsset?.pages.find((page) => page.id === studioHeader.currentPageId)?.title || "");
  const [openDialog, setOpenDialog] = useState<mapperOption[] | null>(null);

  useEffect(() => {
    setNumOfPages(user.currentAsset?.pages.length || 0);
  }, [user.currentAsset?.pages.length]);

  useEffect(() => {
    if (studioHeader.currentPageId === "") {
      setStudioHeader((prev) => ({ ...prev, currentPageId: user.currentAsset?.pages[0].id || "", thumbnail: user.currentAsset?.pages[0].thumbnail || "" }));
    } else {
      setPageTitle(user.currentAsset?.pages.find((page) => page.id === studioHeader.currentPageId)?.title || "");
    }
  }, [studioHeader.currentPageId, user.currentAsset?.pages, setStudioHeader]);

  const handleOpenMapper = () => {
    const currentMapper = user.currentMapper.find((mapper) => mapper.id === user.currentAsset?.id);
    if (currentMapper) {
      setOpenDialog(currentMapper.mapper);
    } else {
      setOpenDialog([]);
    }
  }

  const handleClearMapper = () => {
    setUser((prev) => ({ ...prev, currentMapper: prev.currentMapper.filter((mapper) => mapper.id !== user.currentAsset?.id) }));
    setOpenDialog(null);
  }

  return (
    <S.Container>
      {
        numOfPages <= 1
          ?
          <Box sx={{ paddingLeft: "10px" }}>{pageTitle}</Box>
          :
          <Dropdown>
            <MenuButton size="sm" sx={{ ...S.ButtonStyle }}>
              <div >{pageTitle}</div>
              <ArrowDropDownIcon />
            </MenuButton>
            <Menu size="sm">
              {user.currentAsset?.pages.map((page) => (
                page.id === studioHeader.currentPageId ? null :
                  <MenuItem key={page.id} onClick={() => {
                    setStudioHeader((prev) => ({ ...prev, currentPageId: page.id, thumbnail: page.thumbnail }));
                    console.log("page change", page);
                  }}
                    sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
                  >
                    {page.title}
                  </MenuItem>
              ))}
            </Menu>
          </Dropdown>
      }
      <S.RightButtonContainer>
        {
          user.currentMapper.find((mapper) => mapper.id === user.currentAsset?.id) &&
          <Button onClick={handleClearMapper} sx={{ color: 'red', height: '40px', fontSize: '12px' }}>MAPPER 초기화</Button>
        }
        <Button
          variant='outlined'
          sx={{ color: '#5340ff', height: '40px', fontSize: '15px' }}
          onClick={handleOpenMapper}
        >
          mapper
        </Button>
        <Button
          variant='outlined'
          sx={{ color: '#5340ff', height: '40px', fontSize: '15px' }}
        >
          재학습
        </Button>
      </S.RightButtonContainer>
      <Mapper openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </S.Container>
  );
}

export default DashboardHeader;
