import {
  Box,
  Button,
  Drawer,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAtom, useSetAtom } from 'jotai';
import CloseIcon from '@mui/icons-material/Close';
import DatasetIcon from '@mui/icons-material/Dataset';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';
import { useAuth } from '@memberstack/react';
import { memberAtom, userAtom } from '../../Atoms/UserAtom';
import { isOpen } from '../../Atoms/SidebarAtom';
import { studioHeaderAtom } from '../../Atoms/StudioAtom';
import { useUser } from '../../Providers/User';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  padding: '25px',
};
const GradientText = styled.span`
  background: linear-gradient(
    280deg,
    #d943ff 0%,
    #5340ff 44%,
    #134cf8 81%,
    #0ae4f0 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;

export function ModalSidebar() {
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const setMember = useSetAtom(memberAtom);
  const [dashboardToggle, setDashboardToggle] = useState(false);
  const [openState, setOpenSidebar] = useAtom(isOpen);
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const setStudioHeader = useSetAtom(studioHeaderAtom);
  const refetchUserData = useUser().refetchUserData;

  function changeopenState() {
    setOpenSidebar(false);
  }

  const DrawerList = (
    <Box
      sx={{
        width: '292px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'space-between',
      }}
      role='presentation'
    // onClick={changeopenState}
    >
      <div>
        <div
          style={{
            width: '100%',
            height: '56px',
            display: 'flex',
            padding: '8px 12px',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            alignItems: 'center',
            borderBottom: '1px solid #ececec',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
            onClick={() => {
              navigate('/');
              changeopenState();
            }}
          >
            <img
              src='/mareummo_logo.png'
              alt='MAREUMMO™'
              style={{
                height: '32px',
                objectFit: 'contain',
              }}
            />
            <div style={{ width: '10px' }}></div>
            <GradientText>MAREUMMO™</GradientText>
          </div>
          <IconButton onClick={changeopenState}>
            <CloseIcon />
          </IconButton>
        </div>
        <Button
          startIcon={<DashboardIcon />}
          onClick={() => {
            setDashboardToggle(!dashboardToggle);
          }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            padding: '10px 20px',
            color: 'black',
            gap: '16px',
          }}
          endIcon={dashboardToggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        >
          Dashboard
        </Button>
        {
          dashboardToggle && user.assets.length > 0 && user.assets.map((asset) => (
            <Button
              onClick={() => {
                setUser({ ...user, currentAsset: asset });
                setStudioHeader({ pageSidebarOpen: false, layoutSidebarOpen: false, currentPageId: asset.pages[0].id, thumbnail: asset.pages[0].thumbnail });
                refetchUserData();
                navigate('/');
                changeopenState();
              }}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                padding: '10px 20px',
                color: 'black',
                backgroundColor: '#f5f5f5',
                gap: '16px',
              }}
            >
              {asset.assetName}
            </Button>
          ))
        }
        <div style={{ height: '10px' }}></div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Button
            startIcon={<DatasetIcon />}
            onClick={() => {
              navigate('/assets');
              changeopenState();
            }}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              padding: '10px 20px',
              color: 'black',
              gap: '16px',
            }}
          >
            ASSET
          </Button>
          <Button
            startIcon={<DesignServicesIcon />}
            onClick={() => {
              navigate('/studio');
              changeopenState();
            }}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'start',
              padding: '10px 20px',
              color: 'black',
              gap: '16px',
            }}
          >
            studio
          </Button>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '100%',
            height: '56px',
            display: 'flex',
            padding: '8px 12px',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
            alignItems: 'center',
            borderBottom: '1px solid #ececec',
          }}
        ></div>
        <Button
          startIcon={<LogoutIcon />}
          onClick={() => {
            auth.signOut().then(() => setMember(null));
            changeopenState();
          }}
          sx={{
            background: '#134cf8',
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            padding: '10px 20px',
            color: 'white',
            gap: '16px',
            borderRadius: 0,
            '&:hover': {
              background: '#134cf8',
            },
          }}
        >
          LOGOUT
        </Button>
      </div>
    </Box>
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography color={'#8f8f8f'} id='modal-modal-title'>
            데모 버전 생성 제한
          </Typography>
          <hr style={{ width: '100%', border: '1px solid #ececec' }} />
          <Typography color={'#5340ff'} id='modal-modal-description'>
            데모 버전에서는 최대 한개의 챗봇만 생성하실 수 있어요.
          </Typography>
          <div style={{ height: '20px' }}></div>
          <Typography color={'#5340ff'} id='modal-modal-description'>
            정식버전에서 만나요 우리~~
          </Typography>
        </Box>
      </Modal>
      <div style={{ display: openState ? 'block' : 'none' }}>
        <Drawer
          open={openState}
          onClose={changeopenState}
          style={{ display: openState ? 'block' : 'none' }}
        >
          {DrawerList}
        </Drawer>
      </div>
    </>
  );
}
