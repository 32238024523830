import styled from 'styled-components';

export const LeftBoxContainer = {
  display: "flex",
  flexDirection: "column",
  width: "calc(100% - 350px)",
  height: "100%",
  borderRight: "1px solid #ececec",
  boxSizing: "border-box",
  gap: "10px",
  padding: 3,
  overflowY: "auto",
};

export const RightBoxContainer = {
  display: "flex",
  flexDirection: "column",
  width: "350px",
  height: "100%",
  boxSizing: "border-box",
  gap: "10px",
  padding: 3,
  overflowY: "auto",
};

export const PrevAndSaveButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  gap: 10px;
  padding: 10px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SwitchContents = styled.div`
  display: flex;
  gap: 5px;
  background: #ececec;
  padding: 10px;
  border-radius: 35px;
  justify-self: flex-end;
`;

export const SetDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2;
`;
