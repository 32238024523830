import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue } from "jotai";
import { CommonChartProps } from "../../..";
import { userAtom } from "../../../../../Atoms/UserAtom";
import { ExcelFileProps } from "../../../../../Atoms/AssetAtom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { colorMap } from "../../../../../Data/colorMap";
import { studioHeaderAtom } from "../../../../../Atoms/StudioAtom";
import { selectedTechInfoAtom } from "../../../../../Atoms/DashboardAtom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ThesisKeywordChart = ({ customLayout, contentId, handleDelete, rowHeight }: CommonChartProps) => {
  const user = useAtomValue(userAtom);
  const [chartData, setChartData] = useState<any>(null);
  const studioHeader = useAtomValue(studioHeaderAtom);
  const selectedTechInfo = useAtomValue(selectedTechInfoAtom);

  useEffect(() => {
    const currentSource = user.currentAsset?.sources.find((assetSource) => assetSource.assetType === 'thesisKeyword');
    const fileData = currentSource?.file?.fileData as ExcelFileProps ?? null;
    if (!fileData) {
      console.error('fileData is null');
      return;
    }

    const keywordData = fileData.categoricalData?.find((data) => data.id === '키워드')?.data;
    const researchTrendData = fileData.numericalData?.find((data) => data.id === '연구동향-2022')?.data;
    const usesTrendData = fileData.numericalData?.find((data) => data.id === '활용동향-2022')?.data;

    if (!keywordData || !researchTrendData || !usesTrendData) {
      console.error('data is null');
      return;
    }

    const items = keywordData.map((keyword, index) => ({
      index: index,
      keyword: keyword,
      researchTrend: researchTrendData[index] ?? 0,
      usesTrend: usesTrendData[index] ?? 0,
    }));

    const uniqueItems = items.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.keyword === item.keyword
      ))
    );

    uniqueItems.sort((a, b) => b.researchTrend - a.researchTrend);

    const topItems = uniqueItems.slice(0, 5);

    const labels = topItems.map(item => item.keyword);
    const researchTrendValues = topItems.map(item => (item.researchTrend / topItems.reduce((acc, cur) => acc + cur.researchTrend, 0)) * 100);
    const usesTrendValues = topItems.map(item => (item.usesTrend / topItems.reduce((acc, cur) => acc + cur.usesTrend, 0)) * 100);

    const chartData = {
      labels,
      datasets: [
        {
          label: '연구동향',
          data: researchTrendValues,
          backgroundColor: colorMap[0],
        },
        {
          label: '활용동향',
          data: usesTrendValues,
          backgroundColor: colorMap[2],
        }
      ]
    };
    setChartData(chartData);
  }, [customLayout, user.currentAsset, selectedTechInfo, studioHeader.currentPageId]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        beginAtZero: true,
      },
    }
  };

  return (
    <Card style={{ width: "100%", height: "100%" }}>
      <CardContent style={{ maxHeight: `${customLayout.h * rowHeight - 40}px`, minHeight: `${customLayout.h * rowHeight - 40}px`, overflowY: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" gutterBottom>
            논문 키워드 빈도 차트
          </Typography>
          {handleDelete &&
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => handleDelete(e, contentId)}
            />
          }
        </Box>
        <Divider />
        <Box sx={{ display: "flex", maxHeight: `${customLayout.h * rowHeight - 90}px`, minHeight: `${customLayout.h * rowHeight - 90}px`, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          {chartData ? <Bar options={options} data={chartData} /> : <Typography>Loading chart...</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ThesisKeywordChart;
