import { useMemberstack, useMemberstackModal } from '@memberstack/react';
import { useAtom } from 'jotai';
import { memberAtom } from '../Atoms/UserAtom';
import './LoginForm.css';

export const NotLogin = () => {
  const memberstack = useMemberstack();
  const { openModal, hideModal } = useMemberstackModal();

  const [, setMember] = useAtom(memberAtom);

  const login = () =>
    openModal({
      type: 'LOGIN',
    }).then(() => {
      memberstack.getCurrentMember().then(({ data: member }) => {
        console.log(member);
        setMember(member);
        hideModal();
      });
    });

  return (
    <>
      <div className='loginContainer'>
        <div className='wrapper'>
          <button onClick={() => login()}>Login</button>
        </div>
      </div>
    </>
  );
};
