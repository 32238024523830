import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface RaderChartProps {
  data: any;
  options?: any;
}

const RadarChart = ({ data, options }: RaderChartProps) => {
  return options ? <Radar data={data} options={options} /> : <Radar data={data} />;
};

export default RadarChart;
