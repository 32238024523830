import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";
import convertSales from "../../../../Utils/ConvertSales";

interface DataType {
  key: string;
  value: number;
}

interface BarPlotProps {
  data: DataType[];
  title: string;
  description: string;
  xlabel: string;
  ylabel: string;
  left?: number;
  color?: string;
}

const KeyValueLeftBarPlot = ({ data, title, description, xlabel, ylabel, left, color }: BarPlotProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0 || !entries[0].contentRect) {
        return;
      }
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (svgRef.current) {
      resizeObserver.observe(svgRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [svgRef]);

  useEffect(() => {
    if (svgRef.current && dimensions.width && dimensions.height) {
      const svgElement = svgRef.current;
      const width = dimensions.width;
      const height = dimensions.height;
      const margin = { top: 0, right: 0, bottom: 0, left: 0 };

      const svg = d3.select(svgElement)
        .attr("width", width)
        .attr("height", height);

      svg.selectAll("*").remove(); // Clear previous content

      const y = d3.scaleBand()
        .domain(data.map(d => d.key))
        .range([margin.top, height - margin.bottom])
        .padding(0.1);

      const x = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value)!])
        .range([margin.left, width - margin.right]);

      // Add Y axis
      svg.append('g')
        .attr('transform', `translate(${margin.left - 10},0)`)
        .call(d3.axisLeft(y).tickSize(0))
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "13px");

      // Add X axis
      svg.append('g')
        .attr('transform', `translate(0,${height - margin.bottom + 5})`)
        .call(d3.axisBottom(x).ticks(5))
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "12px");

      // Add X gridlines
      svg.append("g")
        .attr("class", "grid")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x)
          .ticks(5)
          .tickSize(-height + margin.top + margin.bottom)
          .tickFormat(() => "")
        );

      // Add Y gridlines
      svg.append("g")
        .attr("class", "grid")
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y)
          .tickSize(-width + margin.left + margin.right)
          .tickFormat(() => "")
        );

      // Style gridlines
      svg.selectAll(".grid line")
        .style("stroke", "lightgrey")
        .style("stroke-dasharray", "8 8");

      // Style axis lines
      svg.selectAll(".domain").style("display", "none");

      // Tooltip
      const tooltip = d3.select(tooltipRef.current)
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background", "white")
        .style("border", "1px solid lightgrey")
        .style("border-radius", "4px")
        .style("padding", "5px")
        .style("font-size", "12px")
        .style("height", "20px")
        .style('z-index', 1000);

      // Draw bars
      svg.selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("x", margin.left)
        .attr("y", d => y(d.key)!)
        .attr("width", d => x(d.value) - margin.left)
        .attr("height", y.bandwidth())
        .attr("fill", color ? color : "#2D98D3")
        .on("mouseover", (event, d) => {
          tooltip.style("visibility", "visible").text(`${convertSales(d.value, "", "달러")}`);
        })
        .on("mousemove", (event) => {
          tooltip.style("top", 0).style("left", 0);
        })
        .on("mouseout", () => {
          tooltip.style("visibility", "hidden");
        });

      // Add bar values
      svg.selectAll(".text")
        .data(data)
        .enter()
        .append("text")
        .attr("x", 10)
        .attr("y", d => y(d.key)! + y.bandwidth() / 2 + 5)
        .text(d => d.key)
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "12px")
        .attr("fill", "black");

      // Add X axis label
      svg.append('text')
        .attr('class', 'x label')
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('y', height - 5)
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "12px")
      // .text(xlabel);

      // Add Y axis label
      svg.append('text')
        .attr('class', 'y label')
        .attr('text-anchor', 'middle')
        .attr('transform', 'rotate(-90)')
        .attr('x', -height / 2)
        .attr('y', 15)
        .attr("font-family", "NanumSquareNeoBrg")
        .attr("font-size", "12px")
      // .text(ylabel);
    }
  }, [dimensions, data, xlabel, ylabel, color]);

  return (
    <Box sx={{ width: "100%", height: "100%", boxSizing: "border-box", position: "relative" }}>
      <div ref={tooltipRef} />
      <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>
    </Box>
  );
}

export default KeyValueLeftBarPlot;
